
import { useState } from 'react';
import rightArrow from "/src/assets/img/rightWhiteArrow.svg";

import "../../assets/css/Carousel.css";

const CarouselGallery = ({ text, images, randomized }) => {
    const [selectedImage, setSelectedImage] = useState(images[0]);
    const [index, setCurrentIndex] = useState(0);

    const handleThumbnailClick = (image) => {
        setSelectedImage(image);
        setCurrentIndex(index);
    };

    return (
        <div className='w-full'>
            <div className="gallery-container">
                <div className="relative carousel">
                {/* <div className="relative w-full"> */}
                    {selectedImage.type == "image" ? (
                        <img className="object-cover mx-auto my-auto banner-principal" src={selectedImage.file + `?t=${randomized}`} alt="Selected" />
                    ) : (
                        <video controls className="object-contain w-full mx-auto my-auto bg-black banner-principal" src={selectedImage.file + `?t=${randomized}`} alt="Selected" />
                    )}
                    {/* {images.map((image, index) => (
                        <div
                            key={index}
                            className={`absolute text-center text-white transform -translate-x-1/2 -translate-y-1/2 text-container top-1/2 left-1/2 ${
                                selectedImage.id === image.id ? 'block' : 'hidden'
                            }`}
                        ></div>
                    ))} */}
                </div>
                <div className="thumbnails">
                    {images.map((image, index) => (
                        <div className={'relative img ' + (selectedImage.id === image.id ? 'selected' : '')}>
                            <img
                                key={index}
                                src={(image.type == "image"? image.file : image.thumbnail) + `?t=${randomized}`}
                                alt={`Thumbnail ${index}`}
                                onClick={() => handleThumbnailClick(image)}
                                className="object-cover w-full h-full"
                            />
                            {image.type == "video" && (
                                <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center w-16 h-16 m-auto rounded-full pointer-events-none bg-blueFb">
                                    <img src={rightArrow} alt="play" className='w-6 h-6' />
                                    <div className='absolute border border-white rounded-full -left-2 -right-2 -top-2 -bottom-2'></div>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>

            <div>
                <div className={`bottom-0 flex items-center justify-center w-full h-auto p-4 lg:h-16 bg-grayrgba lg:p-0`}>
                    {/* <span className='text-center text-white font-fira-medita lg:text-left'>{selectedImage.text}</span> */}
                    <span className='text-center text-white font-fira-medita lg:text-left'>{text}</span>
                </div>

                <div className="absolute flex items-center justify-center w-full h-16 bg-redMar">
                    <span className='text-lg text-white font-fira-extraBold'>FORMANDO PERSONAS PARA TODA LA VIDA</span>
                </div>
            </div>
        </div>
    );
};

export default CarouselGallery;
