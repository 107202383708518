import React from 'react'
import rightArrow from '/src/assets/img/rightWhiteArrow.svg'

const Bienvenido = () => {
    let player
    const onYTplayer = () => {
        player = new YT.Player("video", {
            events: {
              onReady: onPlayerReady
            }
          });
    }

    setTimeout(() => {
        onYTplayer()
    }, 1000);
    
    

    function onPlayerReady(event) {
        var playButton = document.getElementById("play-button");
        playButton.addEventListener("click", function () {
        document.getElementById('video').classList.remove('pointer-events-none')
        playButton.style.marginTop = "60%"
        playButton.style.opacity = "0"
          player.playVideo();
        });
      
    }

    var tag = document.createElement("script");
    tag.src = "//www.youtube.com/player_api";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    return (
        <div className='w-full flex flex-col justify-center items-center'>
            <div className='w-full lg:w-2/3 py-20 lg:px-0 px-4'>
                <h2 className='text-blueMar font-fira-bold text-5xl lg:text-left text-center pb-4 lg:pb-0'>
                    Bienvenidos a la familia Marcelina
                </h2>
                <p className='font-fira-medita text-center lg:text-left'>
                    Somos una escuela que se abre a la vida y se propone enseñar a vivirla, que desea formar la inteligencia, humanidad y
                    espíritu, construyendo competencias al servicio de una sociedad más equitativa y que quiere hacer capaz al otro de dar 
                    respuestas auténticas a desafíos esenciales del mundo de hoy, ese es nuestro cometido y también nuestra misión.
                </p>
            </div>
            <div className='w-full lg:h-vh75 h-400 bg-gray-900 flex justify-center items-center overflow-hidden'>
               {/*  */}
               <iframe id='video' className='w-full h-full relative pointer-events-none' src="https://www.youtube.com/embed/ScfAc1qjO0s?enablejsapi=1&html5=1&mute=0&controls=1" title="YouTubeInicio" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
               <div id='play-button' className='rounded-full border-solid border border-white p-2 lg:w-36 lg:h-36 w-24 h-24 absolute z-10 animateYtBt cursor-pointer'>
                        <div className=' h-full w-full bg-blueFb flex justify-center items-center rounded-full'>
                            <img src={rightArrow} alt="rightIcon" className='w-8 h-8' />
                    </div>
                </div>
               {/*  */}
            </div>
        </div>
    )
}

export default Bienvenido
