import React from 'react'
import arrowDown from '/src/assets/img/downArrow.svg'

const Portada = () => {
    return (
        <div className='w-full relative h-vh75 bg-vinculacion bg-cover lg:bg-center bg-center flex justify-center mt-20 lg:mt-32'>
            <div className='absolute -bottom-14 h-28 w-28 flex justify-center items-center bg-bluergba rounded-full'>
                <img src={arrowDown} alt="arrow down icon" className='w-4 h-4' />
            </div>
        </div>
    )
}

export default Portada
