import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import arrowRight from '/src/assets/img/rightRedArrow.svg'
import { useEffect, useState } from "react"

const Pedagogia = () => {
    const [index, setIndex] = useState('1.Atención en las relaciones');
    return (
        <div className='w-full py-10 flex justify-start items-center flex-col'>
            <h5 className='font-fira-bold text-redMar text-3xl mb-16'>PEDAGOGÍA MARCELINA</h5>

            <Splide 
                    className='w-full relative z-10'
                    onMove={(splide) => {
                        // console.log("Index?"+splide.options.get); 
                        // console.log(splide.options.get);
                        console.log("index "+splide.index);
                        let numero = splide.index
                        setIndex(() => {
                            switch (numero) {
                                case 0:
                                    return "1. Atención en las relaciones"
                                    break;
                            
                                case 1:
                                    return "2. Atención al pensamiento"
                                    break;

                                case 2:
                                    return "3. Madurez de la voluntad"
                                    break;
                                
                                case 3:
                                    return "4. Atención a la realidad"
                                    break;
                            
                                case 4:
                                    return "5. Atención a la realidad"
                                    break;
                                    
                                default:
                                    break;
                            }
                        })
                    }}
                    renderControls={ () => (
                        <div className="splide__arrows w-full flex justify-center items-center py-5 flex-col">
                            <button className='splide__arrow--next text-redYt flex items-center font-fira-medita'>Siguiente <img src={arrowRight} alt="controlIcons" className='w-4 h-4 ml-3' /></button>
                            <button className='splide__arrow--prev hidden'></button>
                            <span className='mt-2 text-blueFb font-fira-medita text-sm'>{index}</span>
                        </div>

                      ) }
                    options={{ 
                        type : 'slide',
                        rewind : 'true',
                        rewindSpeed: 500,
                        perPage: 1,
                        drag:true,
                        autoplay:false,
                        interval:7000,
                        waitForTransition:true,
                        keyboard:true,
                        arrows:true,
                        pagination:false,
                     }}
                >
                    <SplideSlide className="relative z-10 h-950 lg:h-550 flex justify-center">
                        <div className='w-full flex justify-center relative z-10 h-3/6 lg:h-5/6 bg-pedagogia bg-cover bg-center'>
                        </div>
                            <div className='p-5 lg:p-14 bg-bluergbaPedagogia w-11/12 lg:w-10/12 flex flex-col h-auto absolute -bottom-0 lg:-bottom-0 z-10'>
                                <span className='font-fira-bold text-white text-xl lg:text-2xl'>1. ATENCIÓN EN LAS RELACIONES</span>
                                <span className='text-white font-fira-medita mt-4 text-justify lg:text-base text-sm'>
                                    Nuestra pedagogía parte del principio que afirma que sólo al interior de una relación
                                    educativa importante y significativa, se forma una personalidad completa y armoniosa. <br /><br />
                                    La Pedagogía Marcelina parte de una suposición sencilla y clara: la persona es un ser
                                    único, entero, complejo, irrepetible, no programable, así que la escuela y todo ambiente
                                    educativo, debería permitirle adquirir gradualmente un conocimiento de sí mismo. Todo
                                    esto se realiza a través de la relación educativa, una relación que según nuestro espíritu
                                    no se construye técnicamente: es una relación creativa y, al mismo tiempo, reveladora. <br /><br />
                                    El educador es aquel que continuamente lleva a la toma de consciencia, instaura
                                    relaciones entre las personas cercanas y lejanas en el tiempo, entre las personas y la
                                    realidad en todas sus formas. Educar según el estilo Marcelina significa acoger a las
                                    personas que se nos confían poniendo atención a su mente y a su corazón, desafiar la
                                    fragmentación que caracteriza nuestro siglo a través de una capacidad de mediación
                                    creativa; creer en el ser humano pensado por su Creador, cuyo corazón es la unión de
                                    sentimientos, inteligencia y voluntad.
                                </span>
                            </div>
                    </SplideSlide>
                    <SplideSlide className="relative z-10 h-950 lg:h-550 flex justify-center">
                        <div className='w-full flex justify-center relative z-10 h-5/6 bg-pastoralPortada bg-cover bg-center'>
                        </div>
                            <div className='p-5 lg:p-14 bg-bluergbaPedagogia w-11/12 lg:w-10/12 flex flex-col h-auto absolute -bottom-0 z-10'>
                                <span className='font-fira-bold text-white text-xl lg:text-2xl'>2. ATENCIÓN AL PENSAMIENTO</span>
                                <span className='text-white font-fira-medita mt-4 text-justify lg:text-base text-sm'>
                                    El docente acompaña en la búsqueda de un conocimiento que dé razón de lo que se ve y se siente, de lo que
                                    se tiene como experiencia, que genera preguntas profundas y orienta hacia la búsqueda de lo trascendente. <br /> <br />
                                    Educar al estilo Marcelina significa, por tanto, contribuir a cultivar una actitud honesta de
                                    búsqueda, preparar un ambiente que estimule y sea capaz de revelar, a través de las
                                    diversas disciplinas y sus reglas, el sentido profundo del hombre con su grandeza y con
                                    sus límites, para suscitar el deseo de construir el mundo de una manera activa y no
                                    sumisa. La escuela se convierte así en un lugar vivo en el cual se realizan concretamente
                                    las elecciones de su propia vida, antes de la elección profesional. <br /> <br />
                                    “La forma de la escuela, en la intuición de Luis Biraghi, determina el estilo educativo, y
                                    lleva en sí, una pedagogía según la cual, el elemento espiritual es capaz de estructurar la
                                    existencia humana necesitando también del conocimiento”
                                    (Documentos XXIV Capítulo General).
                                </span>
                            </div>
                    </SplideSlide>
                    <SplideSlide className="relative z-10 h-950 lg:h-550 flex justify-center">
                        <div className='w-full flex justify-center relative z-10 h-5/6 bg-principal bg-cover bg-center'>
                        </div>
                            <div className='p-5 lg:p-14 bg-bluergbaPedagogia w-11/12 lg:w-10/12 flex flex-col h-auto absolute -bottom-0 z-10'>
                                <span className='font-fira-bold text-white text-xl lg:text-2xl'>3. MADUREZ DE LA VOLUNTAD</span>
                                <span className='text-white font-fira-medita mt-4 text-justify lg:text-base text-sm'>
                                    Nos interesa formar el carácter y la voluntad de la persona. Acompañando siempre a
                                    nuestros niños y jóvenes para que desarrollen una personalidad fuerte y llena de
                                    esperanza. <br /> <br />
                                    El espíritu de familia indicado por nuestro Fundador Luis Biraghi, como el clima dentro del
                                    cual se desarrolla la actividad educativa, nos lleva a la reflexión sobre aquellas expresiones
                                    de atención propias del rol paterno y materno. El Fundador recordando continuamente la
                                    dimensión materna, nunca omite el recomendar un estilo sobrio, sin demasiadas
                                    concesiones. Atribuye a esta habilidad de los educadores el buen resultado de la
                                    formación del carácter, de la voluntad en los jóvenes. <br /> <br />
                                    En una buena relación educativa, es necesario comprender, pero también dar estructura;
                                    consolar, pero también establecer los límites y las reglas; mantener -en el sentido de
                                    asistir, acompañar, tomar de la mano- pero también sostener -en el sentido de ser un
                                    apoyo, alentar. Existe también una forma “masculina” de expresar el cuidado, dirigido a
                                    proponer actitudes que los hagan ser autónomos e independientes: ayudarlos a ser
                                    fuertes los hará capaces de ser intrépidos, valientes, con iniciativa y llenos de esperanza.
                                </span>
                            </div>
                    </SplideSlide>
                    <SplideSlide className="relative z-10 h-950 lg:h-550 flex justify-center">
                        <div className='w-full flex justify-center relative z-10 h-5/6 bg-bgTeatro bg-cover bg-center'>
                        </div>
                            <div className='p-5 lg:p-14 bg-bluergbaPedagogia w-11/12 lg:w-10/12 flex flex-col h-auto absolute -bottom-0 z-10'>
                                <span className='font-fira-bold text-white text-xl lg:text-2xl'>4. ATENCIÓN A LA REALIDAD</span>
                                <span className='text-white font-fira-medita mt-4 text-justify lg:text-base text-sm'>
                                    La formación de una conciencia crítica es un instrumento indispensable y precioso para
                                    orientar, más aún, para cultivar una mirada esperanzadora de nuestro tiempo, que
                                    coincidirá con la forma en que Dios ama y mira el mundo. <br /> <br />
                                    Una característica propia de la Pedagogía Marcelina es la atención a lo cotidiano como
                                    medio precioso para educar, encontrar en la realidad experiencias sencillas de las cuales
                                    servirnos para continuar a tejer la trama de nuevos contextos educativos eficaces. <br /> <br />
                                    Esto impulsa a los educadores a encarnar valores en las cosas de cada día, es decir, hacer pasar los
                                    valores educativos a través de los pliegues de la vida diaria. Invita a encontrar en la sociedad, en las
                                    experiencias contradictorias que viven nuestros jóvenes, algunos elementos de valor; a creer que
                                    todos poseen la idea del Bien, de Bello y Verdadero. La acción educativa Marcelina, ve la
                                    formación de una persona capaz de autodefinirse desde su interior para transformar su entorno y
                                    construir un mundo más solidario y fraterno.
                                </span>
                            </div>
                    </SplideSlide>
                </Splide>
        </div>
    )
}

export default Pedagogia
