import React from 'react'
import arrowDown from '/src/assets/img/downArrow.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Valores = () => {
    AOS.init();
    return (
        <div className='relative w-full p-4 lg:p-16 bg-grisMar'>
            <div className='relative inline-flex justify-center w-full'>
                <div className='absolute top-0 w-8 h-8 rounded-full bg-redMar left-12'></div>
                <div className='flex flex-col justify-around w-11/12 lg:w-9/12 lg:flex-row'>
                    <div className='relative flex flex-col w-full p-5 bg-white lg:w-4/12 rounded-2xl' data-aos="fade-right" data-aos-duration="1000">
                        <span className='text-xl font-fira-bold text-blueFb'>VISIÓN</span>
                        <span className='pt-3 font-fira-medita'>
                            En nuestra comunidad 
                            Marcelina planteamos una 
                            nueva era de desarrollo 
                            centrado en la educación para 
                            el cuidado de la casa común y 
                            la formación de personas 
                            autónomas conscientes de su 
                            impacto en el entorno, 
                            dispuestos a actuar y 
                            trascender, propiciando su 
                            crecimiento y el de quienes las 
                            rodean.
                        </span>
                    </div>
                    <div className='relative flex flex-col items-center w-full p-5 mx-0 bg-white lg:mx-8 lg:w-4/12 rounded-2xl text-left my-5 lg:my-0 z-40' data-aos="fade-right" data-aos-duration="1000">
                        <span className='text-xl font-fira-bold text-blueFb w-full lg:w-auto text-left'>MISIÓN</span>
                        <span className='pt-3 font-fira-medita'>
                            Acompañamos el proceso de 
                            formación humano y espiritual 
                            de nuestros educandos en un 
                            ambiente cálido y de 
                            crecimiento continuo.
                        </span>
                        <div className='absolute z-10 p-4 rounded-full lg:flex bg-blueFb -top-8 lg:-right-10 lg:top-40'>
                            <img src={arrowDown} alt="valm" className='w-3 lg:w-4 h-3 lg:h-4 transform lg:-rotate-90'/>
                        </div>
                        <div className='absolute z-10 p-4 transform rounded-full lg:flex bg-blueFb -bottom-8 lg:bottom-auto lg:-left-10 lg:top-40 lg:mx-0 ' >
                            <img src={arrowDown} alt="valm" className='w-3 lg:w-4 h-3 lg:h-4 transform lg:-rotate-90 '/>
                        </div>
                    </div>
                    <div className='relative flex flex-col w-full p-5 bg-white lg:w-4/12 rounded-2xl' data-aos="fade-right" data-aos-duration="1000">
                        <span className='text-xl font-fira-bold text-blueFb'>VALORES</span>
                        <span className='pt-3 font-fira-medita'>
                            › Responsabilidad <br/>
                            › Respeto <br/>
                            › Verdad <br/>
                            › Solidaridad <br/>
                            › Honestidad <br/>
                            › Diálogo <br/>
                            › Empatía <br/>
                            › Generosidad <br/>
                            › Bondad <br/>
                            › Libertad <br/>
                            › Dar a conocer a Cristo  <br/>
                            para descubrir un sentido de vida <br /> 
                            pleno y feliz
                        </span>
                    </div>
                </div>
            </div>
            <div className='flex items-center justify-center w-full py-16'>
                {/* <a href="http://" target="_blank" rel="noopener noreferrer" className='flex items-center px-6 py-2 rounded-full bg-redMar'>
                    <span className='text-white font-fira-semiboldNo'>Descargar</span>
                    <img src={arrowDown} alt="arrowDescargar" className='h-2.5 w-2.5 ml-3'/>
                </a> */}
            </div>
        </div>
    )
}

export default Valores
