import React from 'react'
import arrowDown from '/src/assets/img/downArrow.svg'
import logoMarce from '/src/assets/img/LogoMarce.png'



const Identidad = () => {
    return (
        <div className='w-full bg-no-repeat bg-cover bg-center lg:bg-100 bg-identidad p-16'>
            <div className='w-full text-center'>
                <h4 className='font-fira-bold text-redMar text-3xl mb-8'>
                    IDENTIDAD MARCELINA
                </h4>
            </div>
            <div className='w-full my-14 lg:my-10 flex justify-center items-center'>
                <img src={logoMarce} alt="Logo-marcelina" className='w-3/4 lg:w-1/5'/>
            </div>
            <div className='w-full flex justify-center'>
                <audio controls>
                    <source src="/himno.mp3" type="audio/mpeg"/>
                </audio> 
            </div>
            <div className='w-full py-16 flex justify-center items-center'>
                <a href="/letrahimno.pdf" target="_blank" rel="noopener noreferrer" className='rounded-full py-2 px-6 bg-redMar flex items-center'>
                    <span className='font-fira-semiboldNo text-white'>Descargar letra</span>
                    <img src={arrowDown} alt="arrowDescargar" className='h-2.5 w-2.5 ml-3'/>
                </a>
            </div>
        </div>
    )
}

export default Identidad
