import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import banIta from '/src/assets/img/banItalia.png'
import banAlbania from '/src/assets/img/banAlbania.png'
import banBenin from '/src/assets/img/banBenin.png'
import banBra from '/src/assets/img/banBrazil.png'
import banCana from '/src/assets/img/banCanada.png'
import banIng from '/src/assets/img/banInglaterra.png'
import banMex from '/src/assets/img/banMexico.png'

const Mundo = () => {
    return (
        <div>

            <div className='w-full block lg:hidden bg-grisMar py-14  '>

                <div className='w-full justify-center items-center py-11 text-center'>

                    <h3 className='font-fira-bold text-redMar text-3xl mb-8'>MARCELINAS <br/> POR EL MUNDO </h3>

                </div>

            <Splide 
                    className='w-full relative'
                    options={{ 
                        type : 'loop',
                        rewind : 'true',
                        rewindSpeed: 500,
                        perPage: 1,
                        padding: '5rem',
                        drag:true,
                        autoplay:true,
                        interval:7000,
                        waitForTransition:true,
                        keyboard:false,
                        arrows:false,
                        pagination:true,
                        classes:{
                          pagination: 'flex justify-center w-full isactiveBt mt-14',
                          page      : 'w-3 h-3 mx-2 rounded-full bg-blueFb hover:bg-redYt', 
                        } 
                    }}
                >
                    <SplideSlide className=' flex justify-center'>

                        <div className='circulo-italia'>

                            <div className='bg-grisMar rounded-full h-full w-full flex justify-center items-center relative'>


                                <span className='font-fira-semiboldNo text-grayMundo'>ITALIA</span> 

                            </div>

                        </div>

                    </SplideSlide>

                    <SplideSlide className=' flex justify-center'>

                        <div className='circulo-inglaterra'>

                            <div className='bg-grisMar rounded-full h-full w-full flex justify-center items-center'>

                                <span className='font-fira-semiboldNo text-grayMundo'>INGLATERRA</span> 

                            </div>

                        </div>

                    </SplideSlide>

                    <SplideSlide className=' flex justify-center'>

                        <div className='circulo-albania'>

                            <div className='bg-grisMar rounded-full h-full w-full flex justify-center items-center'>

                                <span className='font-fira-semiboldNo text-grayMundo'>ALBANIA</span> 

                            </div>

                        </div>

                    </SplideSlide>
                    <SplideSlide className=' flex justify-center'>
                            
                        <div className='circulo-benin'>

                            <div className='bg-grisMar rounded-full h-full w-full flex justify-center items-center'>

                                <span className='font-fira-semiboldNo text-grayMundo'>BENIN</span> 

                            </div>

                        </div>

                    </SplideSlide>

                    <SplideSlide className=' flex justify-center'>
                                
                        <div className='circulo-canada'>

                            <div className='bg-grisMar rounded-full h-full w-full flex justify-center items-center'>

                                <span className='font-fira-semiboldNo text-grayMundo'>CANADA</span> 

                            </div>

                        </div>
                            
                    </SplideSlide>

                    <SplideSlide className=' flex justify-center'>
                                
                        <div className='circulo-brasil'>

                            <div className='bg-grisMar rounded-full h-full w-full flex justify-center items-center'>

                                <span className='font-fira-semiboldNo text-grayMundo'>BRASIL</span> 

                            </div>

                        </div>
                            
                    </SplideSlide>

                    <SplideSlide className=' flex justify-center'>
                                
                        <div className='circulo-mexico'>

                            <div className='bg-grisMar rounded-full h-full w-full flex justify-center items-center'>

                                <span className='font-fira-semiboldNo text-grayMundo'>MÉXICO</span> 

                            </div>

                        </div>
                                    
                    </SplideSlide>


                </Splide>

            </div>

            <div className='w-full bg-grisMar p-14 lg:block hidden'>

                <div className='w-full justify-center items-center py-16 text-center'>

                    <h3 className='font-fira-bold text-redMar text-3xl mb-8'>MARCELINAS <br/> POR EL MUNDO </h3>

                </div>

                <div className='w-full flex justify-around flex-wrap'>

                    <div className='circulo-italia'>

                        <div className='bg-grisMar rounded-full h-full w-full flex justify-center items-center relative hov'>

                            <img src={banIta} alt="banderItalia" className='w-60  h-48 absolute -top-12 opacity-0 group-hover:visible transition-all ban duration-700' />

                            <span className='font-fira-semiboldNo text-grayMundo'>ITALIA</span> 

                        </div>

                    </div>

                    <div className='circulo-inglaterra'>

                        <div className='bg-grisMar rounded-full h-full w-full flex justify-center items-center relative hov'>

                            <img src={banIng} alt="banderItalia" className='w-60  h-48 absolute -top-12 opacity-0 ban transition-all duration-700' />

                            <span className='font-fira-semiboldNo text-grayMundo'>INGLATERRA</span> 

                        </div>

                    </div>

                    <div className='circulo-albania'>

                        <div className='bg-grisMar rounded-full h-full w-full flex justify-center items-center relative hov'>

                            <img src={banAlbania} alt="banderItalia" className='w-60  h-48 absolute -top-12 opacity-0 transition-all ban duration-700' />

                            <span className='font-fira-semiboldNo text-grayMundo'>ALBANIA</span> 

                        </div>

                    </div>

                    <div className='circulo-benin'>

                        <div className='bg-grisMar rounded-full h-full w-full flex justify-center items-center relative hov'>

                            <img src={banBenin} alt="banderItalia" className='w-60  h-48 absolute -top-12 opacity-0 transition-all ban duration-700' />

                            <span className='font-fira-semiboldNo text-grayMundo'>BENIN</span> 

                        </div>

                    </div>

                </div>

                <div className='w-full flex justify-around flex-wrap mt-16 px-8'>

                    <div className='circulo-canada'>

                        <div className='bg-grisMar rounded-full h-full w-full flex justify-center items-center relative hov'>

                            <img src={banCana} alt="banderItalia" className='w-60  h-48 absolute -top-12 opacity-0 transition-all ban duration-700' />

                            <span className='font-fira-semiboldNo text-grayMundo'>CANADA</span> 

                        </div>

                    </div>

                    <div className='circulo-brasil'>

                        <div className='bg-grisMar rounded-full h-full w-full flex justify-center items-center relative hov'>

                            <img src={banBra} alt="banderItalia" className='w-60  h-48 absolute -top-12 opacity-0 transition-all ban duration-700' />

                            <span className='font-fira-semiboldNo text-grayMundo'>BRASIL</span> 

                        </div>

                    </div>

                    <div className='circulo-mexico'>

                        <div className='bg-grisMar rounded-full h-full w-full flex justify-center items-center relative hov'>

                            <img src={banMex} alt="banderItalia" className='w-60  h-48 absolute -top-12 opacity-0 transition-all ban duration-700' />

                            <span className='font-fira-semiboldNo text-grayMundo'>MÉXICO</span> 

                        </div>

                    </div>

                </div>

            </div>
            
        </div>
    )
}

export default Mundo
