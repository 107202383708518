import React from 'react'
import { Fragment } from 'react/cjs/react.production.min'
import filtroSello from '/src/assets/img/filtroAlumnosSello.png'
import hexImg from '/src/assets/img/hexMov.png'

const Alumnos = () => {
    return (
        <Fragment>
            <div className='w-full pb-10 text-center pt-36 bg-grisMar'>
                <span className='mb-8 text-3xl font-fira-bold text-redMar'>
                    NUESTROS ALUMNOS
                </span>
            </div>
            <div className='relative flex flex-col items-center justify-center w-full p-10 bg-cover lg:p-16 bg-pedagogia bg-grisMar'>
                <img src={filtroSello} alt="filtro" className='absolute w-full h-full' />
                <div className='relative flex flex-col justify-center w-full text-center lg:justify-end lg:h-52'>
                    <div className='w-20 h-2 mb-5 bg-redMar lg:mb-10'></div>
                    <span className='w-11/12 text-lg text-left text-white font-fira-semibold lg:w-8/12'>
                        Construyen su proyecto de vida basados en el modelo de <br />
                        hombre en Cristo, capaces de dialogar y comprometerse con  <br />
                        su entorno y con el cuidado de la casa común. <br />
                    </span>
                </div>
            </div>
            <div className='w-full p-5 bg-center bg-no-repeat bg-cover lg:bg-100 bg-identidad lg:p-16'>
                {/* Hexmanual */}
                {/* <div className='flex items-center justify-center w-40 h-40 overflow-hidden transform rotate-90'>
                    <div id="hexagono3" class="bg-blueFb">
                        <div id="hexagono3-1" class="bg-blueFb">
                            <div id="hexagono3-2" class="bg-blueFb">
                                <span>SERA CON ABS</span>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* Hex imagen */}
                <div className='justify-center hidden w-full h-1000 lg:p-28 lg:flex'>
                    <div className='block w-full h-full bg-center bg-no-repeat bg-contain bg-fondoHex'></div>
                </div>
                <img src={hexImg} alt="hexMov" className='block w-full lg:hidden' />
            </div>
        </Fragment>
    )
}

export default Alumnos
