import React, { Fragment } from 'react';
import logoMarceMdInfo from '/src/assets/img/LogoMarceG.svg'
import arrowDown from '/src/assets/img/downArrow.svg'

const Oferta = () => {
  return (
      <Fragment>
        <div className='w-full flex justify-center py-16 bg-100 relative bg-filtroInformes bg-blueFb mt-36'>
            <div className='rounded-full bg-white shadow-xl w-36 h-36 p-2 absolute -top-24'>
                <img src={logoMarceMdInfo} alt="imagenLogoMarce" className='w-full h-full' />
            </div>
            <div className=' flex flex-col items-center my-20'>
                <span className='text-redMar font-fira-bold text-4xl mb-16 lg:text-center'>
                    Oferta académica y costo anual
                </span>

                <a href="http://" target="_blank" rel="noopener noreferrer" className='rounded-full py-2 px-6 bg-redMar flex items-center mt-7'>
                    <span className='font-fira-semiboldNo text-white'>Clic aquí</span>
                    <img src={arrowDown} alt="arrowDown" className='h-2.5 w-2.5 ml-3' />
                </a>
            </div>
        </div>
        <div className='bg-blueFb'>
            <div className='flex w-full justify-center items-center py-10 flex-col'>
                <span className='font-fira-boldIta text-white text-2xl'>Déjanos tus datos</span>
                <form action="" method="post" className='flex flex-col w-1/3'>
                    <div class="my-3 w-full">
                        <input placeholder='Nombre*' class="w-full bg-transparent border-b border-solid border-grayForm focus:no-underline focus:outline-none font-fira-medium text-base text-white" type="text"/>
                    </div>
                    <div class="my-3 w-full">
                        <input placeholder='Apellido*' class="w-full bg-transparent border-b border-solid border-grayForm focus:no-underline focus:outline-none font-fira-medium text-base text-white" type="text"/>
                    </div>
                    <div class="my-3 w-full">
                        <input placeholder='Email*' class="w-full bg-transparent border-b border-solid border-grayForm focus:no-underline focus:outline-none font-fira-medium text-base text-white" type="text"/>
                    </div>
                    <div class="my-3 w-full">
                        <input placeholder='Teléfono' class="w-full bg-transparent border-b border-solid border-grayForm focus:no-underline focus:outline-none font-fira-medium text-base text-white" type="text"/>
                    </div>
                    <div class="my-3 w-full">
                        <input placeholder='Comentarios*' class="w-full bg-transparent border-b border-solid border-grayForm focus:no-underline focus:outline-none font-fira-medium text-base text-white" type="text"/>
                    </div>
                    <div class="my-3 w-full">
                        <input placeholder='Nivel académico de interés' class="w-full bg-transparent border-b border-solid border-grayForm focus:no-underline focus:outline-none font-fira-medium text-base text-white" type="text"/>
                    </div>
                    <button className='rounded-full py-2 px-6 bg-redMar flex items-center mt-7 font-fira-semiboldNo text-white w-28 mx-auto' type="submit">Enviar<img src={arrowDown} alt="arrowDown" className='h-2.5 w-2.5 ml-3 transform -rotate-90' /></button>
                </form>
            </div>
        </div>
      </Fragment>

  )
};

export default Oferta;
