import React from 'react';
import regresar from '/src/assets/img/regresar.svg'

const gracias = () => {
  return (
        <div class="w-full flex flex-col justify-center items-center pt-20 lg:pt-32 h-vh90 bg-grayTY">
            <div className='w-1/4 text-center'>
                <h1 className='text-blueMar font-fira-bold text-6xl'>¡GRACIAS!</h1>
                <span className='font-fira-medium text-lg mt-3'>En breve te contactaremos</span>
            </div>
            <a href="https://colegiomarcelina.edu.mx" rel="noopener noreferrer" className='rounded-full py-2 px-6 bg-redMar flex items-center mt-16'>
                <span className='font-fira-semiboldNo text-white'>Regresar</span>
                <img src={regresar} alt="regresar" className='h-4 w-4 ml-3' />
            </a>
        </div> 
    );
};

export default gracias;
