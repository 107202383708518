import React from 'react'
import iconSl1  from '/src/assets/img/icono_proceso.svg'
import iconSl2  from '/src/assets/img/icono_diagnostico.svg'
import iconSl3  from '/src/assets/img/icono_retro.svg'
import iconSl4  from '/src/assets/img/icono_inscripcion.svg'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import arrowRight from '/src/assets/img/left.svg'
import arrowLeft from '/src/assets/img/right.svg'
import { useEffect, useState } from "react"


const Pasos = () => {
    const [index, setIndex] = useState('1/4');
    return (
        <div className='bg-grisMar p-5 lg:p-16'>
            {/* <img src={iconSl1} alt="iconsl" /> */}
            <Splide 
                    className='w-full'
                    onMove={(splide) => {;
                        let numero = splide.index
                        setIndex(() => {
                            switch (numero) {
                                case 0:
                                    return "1/4"
                                    break;
                            
                                case 1:
                                    return "2/4"
                                    break;

                                case 2:
                                    return "3/4"
                                    break;
                                
                                case 3:
                                    return "4/4"
                                    break;
                                
                                default:
                                    break;
                            }
                        })
                    }}
                    renderControls={ () => (
                        <div className="splide__arrows w-10/12 hidden lg:flex justify-between absolute inset-0 m-auto">
                            <button className='splide__arrow--prev '><img src={arrowRight} alt="controlIcons" className='w-16 h-16' /></button>
                            <button className='splide__arrow--next '><img src={arrowLeft } alt="controlIcons" className='w-16 h-16' /></button>
                        </div>
                    )}
                    options={{ 
                        type : 'fade',
                        rewind : 'true',
                        rewindSpeed: 500,
                        perPage: 1,
                        drag:true,
                        autoplay:false,
                        interval:9000,
                        waitForTransition:true,
                        keyboard:true,
                        pagination:false,
                        breakpoints: {
                            700: {
                                arrows:false,
                            },
                         }
                    }}
                >
                    <SplideSlide className="flex justify-center items-center flex-col p-5 lg:p-14 w-full">
                        <div className='rounded-full bg-circuloSld bg-100 flex justify-center items-center'>
                            <img src={iconSl1} alt="circulo-sld2" className='w-40 lg:w-72 h-40 lg:h-72'/>
                        </div>
                        <div className='flex justify-center items-center flex-col text-center'>
                            <span className='font-fira-bold text-redMar text-2xl mt-5'>
                                Proceso de admisión
                            </span>
                            <span className='font-fira-bold text-redMar text-3xl mt-5'>
                                VISITA
                            </span>
                            <span className='font-fira-medita text-blueMar mt-6'>
                                Agenda una cita y conoce nuestro modelo educativo.
                            </span>
                            <span className='font-fira-medita mt-6'>
                                Llámanos al <a href="tel:(442) 212 9098 ">(442) 212 9098</a><br />
                                Envíanos un email a: <a href="mailto:admisiones@colegiomarcelina.edu.mx">admisiones@colegiomarcelina.edu.mx</a><br />
                                Envíanos un WhatsApp: <a href="tel:(442) 212 9098 ">(442) 824 2747 </a>
                            </span>
                        </div>
                    </SplideSlide>
                    <SplideSlide className="flex justify-center items-center flex-col p-5 lg:p-14 w-full">
                        <div className='rounded-full bg-circuloSld bg-100 flex justify-center items-center'>
                            <img src={iconSl2} alt="circulo-sld2" className='w-40 lg:w-72 h-40 lg:h-72'/>
                        </div>
                        <div className='flex justify-center items-center flex-col text-center'>
                            <span className='font-fira-bold text-redMar text-2xl mt-5'>
                                Proceso de admisión
                            </span>
                            <span className='font-fira-bold text-redMar text-3xl mt-5'>
                                DIAGNÓSTICO DE INGRESO
                            </span>
                            <span className='font-fira-medita text-blueMar mt-6'>
                                Programa tu Diagnóstico de Ingreso, para ello te solicitaremos: 
                            </span>
                            <ul className='font-fira-medita mt-6'>
                                <li>
                                    Pago de diagnóstico
                                </li>
                                <li>
                                    Carta de buena conducta
                                </li>
                                <li>
                                    Carta de NO adeudo económico
                                </li>
                                <li>
                                    Evaluaciones / Boleta más reciente <br />
                                    (Promedio mínimo de 8 y no materias reprobadas)
                                </li>
                            </ul>
                        </div>
                    </SplideSlide>
                    <SplideSlide className="flex justify-center items-center flex-col p-5 lg:p-14 w-full">
                        <div className='rounded-full bg-circuloSld bg-100 flex justify-center items-center'>
                            <img src={iconSl3} alt="circulo-sld2" className='w-40 lg:w-72 h-40 lg:h-72'/>
                        </div>
                        <div className='flex justify-center items-center flex-col text-center'>
                            <span className='font-fira-bold text-redMar text-2xl mt-5'>
                                Proceso de admisión
                            </span>
                            <span className='font-fira-bold text-redMar text-3xl mt-5'>
                                RETROALIMENTACIÓN
                            </span>
                            <span className='font-fira-medita text-blueMar mt-6 lg:w-1/2'>
                                De dos a tres días posteriores a la aplicación del Diagnóstico de ingreso recibirán la retroalimentación correspondiente, de parte del área académica de interés. 
                            </span>
                        </div>
                    </SplideSlide>
                    <SplideSlide className="flex justify-center items-center flex-col p-5 lg:p-14 w-full">
                        <div className='rounded-full bg-circuloSld bg-100 flex justify-center items-center'>
                            <img src={iconSl4} alt="circulo-sld2" className='w-40 lg:w-72 h-40 lg:h-72'/>
                        </div>
                        <div className='flex justify-center items-center flex-col text-center'>
                            <span className='font-fira-bold text-redMar text-2xl mt-5'>
                                Proceso de admisión
                            </span>
                            <span className='font-fira-bold text-redMar text-3xl mt-5'>
                                INSCRIPCIÓN
                            </span>
                            <span className='font-fira-medita text-blueMar mt-6'>
                                Realizar el pago correspondiente y entregar la documentación que le será requerida.
                            </span>
                        </div>
                    </SplideSlide>
                </Splide>
            <div className='flex justify-center w-full'>
                <span className='font-fira-medita mt-6'> 
                    {index}
                </span>
            </div>
        </div>
    )
}

export default Pasos
