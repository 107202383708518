import React from 'react'

const portada = () => {
    return (
        <div className='relative w-full bg-center bg-cover bg-proyecto lg:mt-32 h-vh75'>
            
        </div>
    )
}

export default portada
