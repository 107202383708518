import React from 'react'
import circuloConocenos from '/src/assets/img/circuloConocenos.jpg'

const Historia = () => {
    return (
        
        <div className='flex flex-col w-full h-auto py-16 lg:py-10 bg-no-repeat bg-fondoAzul bg-grisMar bg-cover lg:bg-100 lg:flex-row bg-center'>

            <div className='relative flex items-center justify-center w-full lg:w-1/2 lg:mt-0 mt-4'>

                <div className='circulo-fondo-padre relative lg:absolute lg:-bottom-36 bottom-0'>

                    <img src={circuloConocenos} alt="circuloPadre" className='w-full h-full rounded-full' />

                </div>

                <div className='absolute w-4 h-4 lg:w-8 lg:h-8 rounded-full bg-redMar left-12 top-3'></div>

            </div>

            <div className='relative flex flex-col items-start justify-center w-full p-3 lg:w-1/2'>

                <div>
                    <h2 className='mt-5 text-3xl text-white font-fira-bold'>NUESTRA HISTORIA</h2>
                </div>

                <div className='flex flex-col w-full mt-4 lg:w-10/12'>
                    <span className='text-base text-white font-fira-medita'>
                        En el año de 1838, en Italia, el sacerdote Luis Biraghi tuvo una brillante intuición, 
                        en respuesta a lo que consideró una necesidad digna de atención: ocuparse de la formación espiritual y 
                        preparación intelectual de las jóvenes de la alta sociedad de la Región lombarda, Milán y sus alrededores, 
                        ya que carecían de auténtica cultura y sólidos principios éticos. <br /> <br />
                        Por tal motivo fundó la Congregación de las Hermanas Marcelinas, quienes, a ejemplo de Santa Marcelina, a quien escogió 
                        como patrona y protectora del naciente instituto religioso, dedicarían su vida a Dios al servicio de los hermanos en Cristo, 
                        en actividades predominantemente educativas.
                    </span>
                </div>

                <div className='absolute w-4 lg:w-5 h-4 lg:h-5 rounded-full bg-blueFb right-16 -bottom-28 lg:-bottom-16'></div>

            </div>

        </div>
    )
}

export default Historia
