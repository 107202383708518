import React from 'react'

const Login = () => {
  return (
    <div>
        <iframe src="https://colegiomarcelina.edu.mx/login.php" frameborder="0" className='w-full mt-14 lg:mt-32 h-vh95 lg:h-login'></iframe>
    </div>
  )
}

export default Login