import React from 'react';

const IdiomaInstalacion = () => {
  return (
        <div className='w-full p-8 lg:h-vh95 h-550 lg:p-24'>
            <iframe id='InstalacionesRecorrido' className='w-full h-full' src="https://colegiomarcelina.edu.mx/recorrido/" title="instalaciones recorrido"></iframe>
        </div>
  );
};

export default IdiomaInstalacion;
