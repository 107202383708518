import React from 'react'
import masLogo from '/src/assets/img/masIcono.svg'
import menosLogo from '/src/assets/img/menosIcono.svg'
import avionLogo from '/src/assets/img/avionIcono.svg'
import { useEffect, useState } from "react"

const Faq = () => {
    const [faq1, setFaq1] = useState('False');
    const [faq2, setFaq2] = useState('False');
    const [faq3, setFaq3] = useState('False');
    const [faq4, setFaq4] = useState('False');
    const [faq5, setFaq5] = useState('False');
    const stateFaq = (faq) => {
        switch (faq) {
            case 1:
                if(faq1 == 'False'){
                    setFaq1('True')
                }else{
                    setFaq1('False')
                }
                break;

            case 2:
                if(faq2 == 'False'){
                    setFaq2('True')
                }else{
                    setFaq2('False')
                }
                break;

            case 3:
                if(faq3 == 'False'){
                    setFaq3('True')
                }else{
                    setFaq3('False')
                }
                break;

            case 4:
                if(faq4 == 'False'){
                    setFaq4('True')
                }else{
                    setFaq4('False')
                }
                break;

            case 5:
                if(faq5 == 'False'){
                    setFaq5('True')
                }else{
                    setFaq5('False')
                }
                break;

            default:
                break;
        }
    }
    return (
        <div className='py-16 lg:px-16 px-10 w-full flex flex-col items-center'>
            <div className='w-full lg:w-10/12 flex justify-center items-center flex-col mb-8 lg:mb-16'>
                <h4 className='font-fira-bold text-redMar text-3xl mb-4 lg:mb-8'>
                    PREGUNTAS FRECUENTES
                </h4>
                <span className='font-fira-medita text-sm text-grisText'>
                    Es en manos de nuestros alumnos, tus hijos, donde se construye el futuro. Con su liderazgo y nobleza dejan su huella en el mundo, agradecemos tu 
                    interés en el Colegio, sabemos que tienes muchas preguntas, a continuación damos respuesta a algunas de las más frecuentes de ellas: 
                </span>
            </div>

            <div className='w-full lg:w-10/12 flex flex-col'>
                <div className='w-full flex flex-col py-4 border-solid border-b border-t border-grayForm'>
                    <div onClick={() => {stateFaq(1)}} className='w-full flex items-center cursor-pointer'>
                        <p className='w-11/12 font-fira-medita text-lg text-blueFb mb-2'>
                            ¿Por qué elegir al Colegio Marcelina?
                        </p>
                        <div className='w-1/12 flex justify-center items-center'>
                            <img src={(faq1 != 'False' ? menosLogo : masLogo )} alt="plusFaq" className='w-3.5 h-3.5'/>
                        </div>
                    </div>
                    <div className={(faq1 != 'False' ? 'h-400 lg:h-28 ': 'h-0 ')+'overflow-hidden w-11/12 transition-all duration-500'}>
                        <span className='font-fira-regular'>
                        Somos una institución en continua innovación, con un propósito claro que de manera dinámica e inspiradora impulsa el desarrollo de nuestra comunidad; 
                        valoramos a la persona, sus talentos y cualidades por lo que ofrecemos a nuestros estudiantes experiencias de aprendizaje que los induzca a 
                        lograr lo mejor de sí mismos como personas valiosas, formándose humana y espiritualmente, intelectual y culturalmente para la vida que les tocará afrontar y 
                        así contribuir a un mundo mejor.
                        </span>
                    </div>
                </div>

                <div className='w-full flex flex-col py-4 border-solid border-b border-grayForm'>
                    <div onClick={() => {stateFaq(2)}} className='w-full flex items-center cursor-pointer'>
                        <p className='w-11/12 font-fira-medita text-lg text-blueFb mb-2'>
                            ¿Con qué áreas académicas cuenta el Colegio y cuáles son sus horarios?
                        </p>
                        <div className='w-1/12 flex justify-center items-center'>
                            <img src={(faq2 != 'False' ? menosLogo : masLogo )} alt="plusFaq" className='w-3.5 h-3.5'/>
                        </div>
                    </div>
                    <div className={(faq2 != 'False' ? 'h-48 lg:h-36 ': 'h-0 ')+' overflow-hidden w-11/12 transition-all duration-500'}>
                        <span className='font-fira-regular'>
                            Contamos con cuatro áreas académicas: <br />
                            Preescolar: 8:00 a 14:00 <br />
                            Primaria: 7:20 a 14:30 <br />
                            Secundaria: 7:10 a 14:50 <br />
                            Preparatoria: 7:20 a 14:40 <br />
                        </span>
                    </div>
                </div>

                <div className='w-full flex flex-col py-4 border-solid border-b border-grayForm'>
                    <div onClick={() => {stateFaq(3)}} className='w-full flex items-center cursor-pointer'>
                        <p className='w-11/12 font-fira-medita text-lg text-blueFb mb-2'>
                            ¿Cuentan con horario extendido?
                        </p>
                        <div className='w-1/12 flex justify-center items-center'>
                            <img src={(faq3 != 'False' ? menosLogo : masLogo )} alt="plusFaq" className='w-3.5 h-3.5'/>
                        </div>
                    </div>
                    <div className={(faq3 != 'False' ? 'h-56 lg:h-24 ': 'h-0 ')+' overflow-hidden w-11/12 transition-all duration-500'}>
                        <span className='font-fira-regular'>
                            Contamos con actividades extraescolares y deportivas. <br />
                            En Preescolar el servicio de estancia por la mañana es de 7:20 a 8:00 hrs. y por la tarde de 14:20 a 14:50 hrs, la entrada y salida para ellos será por la calle Níspero.
                        </span>
                    </div>
                </div>

                <div className='w-full flex flex-col py-4 border-solid border-b border-grayForm'>
                    <div onClick={() => {stateFaq(4)}} className='w-full flex items-center cursor-pointer'>
                        <p className='w-11/12 font-fira-medita text-lg text-blueFb mb-2'>
                            ¿Qué idiomas se imparten? 
                        </p>
                        <div className='w-1/12 flex justify-center items-center'>
                            <img src={(faq4 != 'False' ? menosLogo : masLogo )} alt="plusFaq" className='w-3.5 h-3.5'/>
                        </div>
                    </div>
                    <div className={(faq4 != 'False' ? 'h-450 lg:h-48 ': 'h-0 ')+' overflow-hidden w-11/12 transition-all duration-500'}>
                        <span className='font-fira-regular'>
                            Preescolar: Iniciamos el proceso de adquisición del inglés como una segunda lengua. <br/>
                            Primaria: Idioma Inglés y con la asignatura de Science. <br/>
                            Idioma Italiano desde 1o de primaria. <br/>
                            Secundaria: Idioma Inglés por niveles a través de las asignaturas: Historia, Geografía, Literacy & Human Kind Matters. Idioma Italiano en los 3 grados. <br/>
                            Preparatoria: Inglés por niveles y con asignaturas como Biología, Química y Ecología. Francés en todos los semestres.
                        </span>
                    </div>
                </div>
                
                <div className='w-full flex flex-col py-4 border-solid border-b  border-grayForm'>
                    <div onClick={() => {stateFaq(5)}} className='w-full flex items-center cursor-pointer'>
                        <p className='w-11/12 font-fira-medita text-lg text-blueFb mb-2'>
                            ¿Se realizan viajes al extranjero?
                        </p>
                        <div className='w-1/12 flex justify-center items-center'>
                            <img src={(faq5 != 'False' ? menosLogo : masLogo )} alt="plusFaq" className='w-3.5 h-3.5'/>
                        </div>
                    </div>
                    <div className={(faq5 != 'False' ? 'h-96 lg:h-40 ': 'h-0 ')+' overflow-hidden w-11/12 transition-all duration-500'}>
                        <span className='font-fira-regular'>
                            Actividades académicas en línea con Colegios hermanos de distintas partes del mundo. <br />
                            En Preparatoria cada dos años se realiza un viaje a Londres para que a través de la inmersión en la cultura británica practiquen y mejoren el idioma inglés. <br />
                            Al finalizar el primer año de la preparatoria los mejores promedios tienen la oportunidad de estudiar 4o semestre en un colegio de la Congregación Marcelina en Italia. 
                        </span>
                    </div>
                </div>
            </div>

            {/* <div className='w-full flex justify-center items-center py-12'>
                <div className='py-2 pl-6 pr-3 rounded-full inline-flex justify-between items-center bg-blueFb'>
                    <span className='text-white font-fira-medita text-base'>
                        FORMA PARTE DE NUESTRA <br />
                        FAMILIA MARCELINA
                    </span>
                    <img src={avionLogo} alt="enviar" className='w-10 h-10 ml-5'/>
                </div>
            </div> */}
            
        </div>
    )
}

export default Faq
