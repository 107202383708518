import React from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import solLogo from '/src/assets/img/pastoralSol.png'

const PastoralCorazon = () => {
  return ( 
      <Fragment>
        <div className='w-full flex justify-center items-center flex-col lg:hidden'>
            {/* Movil */}
            <div className='w-full flex justify-center items-center pb-44 pt-14'>
                <img src={solLogo} alt="solImg" className='relative lg:absolute lg:left-28 lg:top-28 h-56 w-56'/>
            </div>        
            <div className="bg-pastoralCoraMov w-full h-vh95 bg-100 bg-center flex justify-center relative">
                <div className='bg-100 p-14 pl-20 bg-pastoralNotas bg-center bg-no-repeat w-96 absolute -top-40'>
                    <span className='font-fira-medium text-base'>
                        Es el corazón de la comunidad
                        Educativa que prepara, siembra y 
                        cultiva el espacio donde se puede 
                        realizar en encuentro con Dios,
                        buscando florecer en la vida personal
                        y de fe, y así colaborar al servicio
                        de los demás.
                    </span>
                </div>
            </div>
        </div>
        {/* Escritorio */}
        <div className='bg-pastoralCorazon bg-100 h-vh95 w-full relative hidden lg:block'>
            <img src={solLogo} alt="solImg" className='relative lg:absolute lg:left-28 lg:top-28 h-56 w-56'/>
            <div className='bg-100 p-14 pl-20 absolute right-28 top-14 bg-pastoralNotas bg-center bg-no-repeat w-3/12'>
                <span className='font-fira-medium text-base'>
                    Es el corazón de la comunidad
                    Educativa que prepara, siembra y 
                    cultiva el espacio donde se puede 
                    realizar en encuentro con Dios,
                    buscando florecer en la vida personal
                    y de fe, y así colaborar al servicio
                    de los demás.
                </span>
            </div>
        </div>
    </Fragment>
  )
};

export default PastoralCorazon;
