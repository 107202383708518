import React from 'react'
import circuloVinculacion from "/src/assets/img/circuloAlumnaVinculacion.png"

const Futuro = () => {
    return (
        <div className='w-full bg-fondoAzul bg-grisMar bg-cover bg-center lg:bg-100 bg-no-repeat py-10 lg:px-0 px-5 flex flex-col lg:flex-row'>

            <div className='w-full lg:w-1/2 relative flex justify-center items-center mt-9'>

                <div className='bg-redMar rounded-full w-8 h-8 absolute right-10 bottom-0'></div>

                <div className='circulo-fondo lg:h-80 lg:w-80 w-60 h-60'>

                    <img src={circuloVinculacion} alt="circuloMujer" className='w-full h-full' />

                </div>

                <div className='bg-redMar rounded-full w-8 h-8 absolute left-10 top-0'></div>

            </div>

            <div className='w-full lg:w-1/2 flex items-start p-3 flex-col justify-center text-center lg:text-left'>

                <h2 className='text-white font-fira-bold text-3xl mt-9 lg:mt-5 w-full'>¿QUÉ FUTURO QUIERES <br /> PARA TUS HIJOS?</h2>

                <div className='flex flex-col w-full lg:w-10/12 mt-10 lg:mt-4'>
                    <span className='text-white font-fira-medita text-base'>Elegir la escuela ideal para nuestros hijos, sin duda se trata del futuro que queremos para ellos.</span>
                    <span className='text-white font-fira-medita text-base my-3'>En el colegio formamos líderes exitosos, personas nobles, capaces de amar y ser felices, nuestros niños y jóvenes, sin duda, saben compartir su luz con otros.</span>
                    <span className='text-white font-fira-medita text-base'>Permítenos acompañarte y guiarte en el proceso, será un gusto enorme conocerte</span>
                </div>

            </div>

            
        </div>
    )
}

export default Futuro
