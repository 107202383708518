import React from 'react';
import { Fragment } from 'react/cjs/react.production.min'
import arrowGal from '/src/assets/img/arrowGal.svg'
import primariaCirculo from '/src/assets/img/secundariaCirculo.png'
import arrowDown from '/src/assets/img/downArrow.svg'
import imagenUno from '/src/assets/img/secundariaCompetencias.png'
import imagenDos from '/src/assets/img/secundariaHabilidades.png'
import imagenTres from '/src/assets/img/secundariaCompetencias_1.png'
import imagenCuatro from '/src/assets/img/secundariaColaborativo.png'
import imagenCinco from '/src/assets/img/secundariaDesarrollo.png'
import filtroImagen from '/src/assets/img/primariaFiltro.png'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import arrowRight from '/src/assets/img/left.svg'
import arrowLeft from '/src/assets/img/right.svg'
import rightArrow from '/src/assets/img/rightWhiteArrow.svg'
import circuloSld1 from '/src/assets/img/Secundaria/Secundaria_01.jpg'
import circuloSld2 from '/src/assets/img/Secundaria/Secundaria_02.jpg'
import circuloSld3 from '/src/assets/img/Secundaria/Secundaria_03.jpg'
import circuloSld4 from '/src/assets/img/Secundaria/Secundaria_04.jpg'
import circuloSld5 from '/src/assets/img/Secundaria/Secundaria_05.jpg'
import circuloSld6 from '/src/assets/img/Secundaria/Secundaria_06.jpg'
import circuloSld7 from '/src/assets/img/Secundaria/Secundaria_07.jpg'
import circuloSld8 from '/src/assets/img/Secundaria/Secundaria_08.jpg'
import circuloSld9 from '/src/assets/img/Secundaria/Secundaria_09.jpg'
import circuloSld10 from '/src/assets/img/Secundaria/Secundaria_10.jpg'
import circuloSld11 from '/src/assets/img/Secundaria/Secundaria_11.jpg'
import circuloSld12 from '/src/assets/img/Secundaria/Secundaria_12.jpg'
import circuloSld13 from '/src/assets/img/Secundaria/Secundaria_13.jpg'
import circuloSld14 from '/src/assets/img/Secundaria/Secundaria_14.jpg'
import circuloSld15 from '/src/assets/img/Secundaria/Secundaria_15.jpg'
import circuloSld16 from '/src/assets/img/Secundaria/Secundaria_16.jpg'
import circuloSld17 from '/src/assets/img/Secundaria/Secundaria_17.jpg'
import circuloSld18 from '/src/assets/img/Secundaria/Secundaria_18.jpg'
import circuloSld19 from '/src/assets/img/Secundaria/Secundaria_19.jpg'

const Secundaria = () => {
    let playerSecu
    const onYTplayerSecu = () => {
        playerSecu = new YT.Player("videoSecu", {
            events: {
              onReady: onPlayerReadySecu
            }
          });
    }

    setTimeout(() => {
        onYTplayerSecu()
    }, 1000);
    
    

    function onPlayerReadySecu(event) {
        var playerButtonSecu = document.getElementById("playbuttonSecu");
        playerButtonSecu.addEventListener("click", function () {
        document.getElementById('videoSecu').classList.remove('pointer-events-none')
        playerButtonSecu.style.marginTop = "60%"
        playerButtonSecu.style.opacity = "0"
        playerSecu.playVideo();
        });
    }
  return (
    <Fragment>
        <div className='relative flex justify-center w-full mt-20 bg-center bg-cover lg:mt-32 h-vh75 bg-secundaria'>
            <div className='absolute z-10 flex items-center justify-center w-20 h-20 rounded-full -bottom-10 lg:-bottom-14 lg:h-28 lg:w-28 bg-bluergba'>
                <img src={arrowDown} alt="arrow down icon" className='w-4 h-4' />
            </div>
        </div>
        <div className='relative w-full px-4 py-10 pt-20 mt-20 bg-center bg-no-repeat bg-cover bg-blueFb lg:bg-fondoAzul lg:bg-100 lg:px-10 '>
            <div className='absolute w-8 h-8 rounded-full bg-redMar left-10 top-10'></div>
            <div className='absolute hidden w-4 h-4 rounded-full bg-redMar left-2/4 -bottom-10 lg:block'></div>
            {/* <div className='flex justify-end w-full'>
                <div className='inline-flex items-center justify-end'>
                    <a href='#secuGal' className='text-base text-white lg:text-lg font-fira-semiboldNo'>
                        VER GALERÍA
                    </a>
                    <img src={arrowGal} alt="VerGaleria" className='w-20 h-4 lg:w-28'/>
                </div>
            </div> */}
            <div className='flex flex-col w-full lg:flex-row'>
                <div className='flex justify-center w-full py-8 lg:w-1/2 lg:block lg:py-0'>
                    <div className='relative border-4 border-solid h-72 w-72 lg:absolute lg:p-2 circulo-fondo-linea-kinder 2xl:h-450 2xl:w-450 lg:w-96 lg:h-96 lg:-bottom-28 2xl:-bottom-44 lg:left-48'>
                        <img src={primariaCirculo} alt="circuloNinos" className='w-full h-full'/>
                    </div>
                </div>
                <div className='flex flex-col items-start justify-center w-full p-3 lg:w-1/2'>

                    <div>
                        <h2 className='mt-5 text-3xl text-white font-fira-bold'>SECUNDARIA</h2>
                    </div>
                    <div className='flex flex-col w-10/12 mt-4 mb-12'>
                        <span className='text-base text-white font-fira-medita'>En secundaria favorecemos el fortalecimiento de su personalidad con base en valores 
                                                                                    fundamentales como la libertad, la responsabilidad y la verdad, en un espacio de 
                                                                                    convivencia sano y alegre en el que todos formamos una gran familia. Desarrollamos 
                                                                                    las competencias cognitivas, afectivas y sociales de nuestros jóvenes, por medio de 
                                                                                    proyectos y retos que promueven su talento, creatividad y autonomía.
                        </span>
                        <span className='mt-4 text-base text-white font-fira-medita'>Tenemos como objetivo que los estudiantes desarrollen competencias lingüísticas en 
                                                                                        los idiomas inglés e italiano mediante estrategias que favorezcan la adquisición de 
                                                                                        una segunda y tercera lengua, en donde los jóvenes viven el idioma.
                        </span>
                    </div>
                </div>
            </div>
        </div>

        {/* <div className='relative flex items-center justify-center w-full mt-0 h-vh75 lg:mt-52'>
                <iframe id='videoSecu' className='relative w-full h-full pointer-events-none' src="https://www.youtube.com/embed/r2k7TBfl8L4?enablejsapi=1&html5=1&mute=0&controls=1" title="YouTubeInicio" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div id='playbuttonSecu' className='absolute z-10 p-2 border border-white border-solid rounded-full cursor-pointer w-36 h-36 animateYtBt'>
                        <div className='flex items-center justify-center w-full h-full rounded-full bg-blueFb'>
                            <img src={rightArrow} alt="rightIcon" className='w-8 h-8' />
                    </div>
                </div>
        </div> */}

        <div className='flex items-center justify-center w-full h-auto py-10 bg-cover lg:h-vh75 bg-filtroPedagogia lg:p-8'>
            <div className='flex flex-col items-center justify-center w-full bg-cartaMob lg:bg-cartaAcademica 2xl:w-1/2 h-750 2xl:h-550 lg:h-400 lg:w-1/2 bg-100 lg:items-start p-11'>
                <span className='mt-40 text-2xl text-center font-fira-boldIta lg:text-4xl text-blueFb lg:text-left lg:mt-0'>Bienvenido a <br /> Secundaria Marcelina</span>
                <div className='relative flex items-center justify-center w-full -bottom-16 lg:-bottom-16'>
                <a href="Bienvenido a Secundaria Marcelina.pdf" target="_blank" rel="noopener noreferrer" className='flex items-center px-6 py-2 mt-5 rounded-full lg:mt-0 bg-redMar'>
                    <span className='text-white font-fira-semiboldNo'>Descargar carta</span>
                    <img src={arrowDown} alt="arrowDown" className='h-2.5 w-2.5 ml-3' />
                </a>
                </div>
            </div>
        </div>

        <div className='flex justify-center w-full py-20 lg:p-20'>

            <div className='flex flex-col w-10/12 lg:w-8/12'>

                <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                    <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                        <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                            <img src={imagenUno} alt="img1" className='w-full h-full' />
                        </div>
                    </div>
                    <div className='flex flex-col w-full lg:w-2/3'>
                        <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Competencias comunicativas</span>
                        <span className='text-center font-fira-medita lg:text-justify'>
                            Desarrollamos en nuestros estudiantes las competencias lingüísticas en inglés por 
                            niveles para lograr la certificación de Cambridge. Además, como parte del 
                            proyecto Multicultural, nuestros jóvenes aprenden italiano y se enriquecen con 
                            experiencias del intercambio cultural para construir un mundo más fraterno.
                        </span>
                    </div>
                </div>

                <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                    <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                        <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                            <img src={imagenDos} alt="img1" className='w-full h-full' />
                        </div>
                    </div>
                    <div className='flex flex-col w-full lg:w-2/3'>
                        <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Habilidades digitales</span>
                        <span className='text-center font-fira-medita lg:text-justify'>
                            A través del uso responsable de la tecnología, impulsamos las ideas de nuestros 
                            jóvenes para aprender a resolver problemas investigando, diseñando, 
                            programando y comunicando de manera que puedan familiarizarse con el 
                            conocimiento de los procesos digitales y automatizados en las herramientas de 
                            Google Workspace, Adobe Spark, Scratch y Robótica con LEGO EV3.
                        </span>
                    </div>
                </div>

                <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                    <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                        <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                            <img src={imagenTres} alt="img1" className='w-full h-full' />
                        </div>
                    </div>
                    <div className='flex flex-col w-full lg:w-2/3'>
                        <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Competencias interpersonales</span>
                        <span className='text-center font-fira-medita lg:text-justify'>
                            Acompañamos a los alumnos en esta etapa fascinante que es la adolescencia, 
                            porque reconocemos la importancia de que aprendan a conocerse, apreciarse, 
                            autorregularse y gestionar sus emociones, cuidando de sí mismos y de los demás, 
                            para ser capaces de generar relaciones empáticas con su entorno. El tutor de cada 
                            grado brinda acompañamiento a cada alumno, tanto en su desarrollo académico, 
                            como emocional, personal y familiar.
                        </span>
                    </div>
                </div>


                <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                    <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                        <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                            <img src={imagenCuatro} alt="img1" className='w-full h-full' />
                        </div>
                    </div>
                    <div className='flex flex-col w-full lg:w-2/3'>
                        <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Aprendizaje colaborativo</span>
                        <span className='text-center font-fira-medita lg:text-justify'>
                            El modelo pedagógico de Marcelina tiene su base en el aprendizaje por 
                            competencias y la aplicación de metodologías inductivas, ya que a partir de 
                            situaciones de la vida real se desarrollan proyectos que permiten a los alumnos 
                            trabajar en colaboración de manera vivencial conocimientos, habilidades, 
                            actitudes y valores.
                        </span>
                    </div>
                </div>


                <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                    <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                        <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                            <img src={imagenCinco} alt="img1" className='w-full h-full' />
                        </div>
                    </div>

                    <div className='flex flex-col w-full lg:w-2/3'>
                        <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Desarrollo espiritual</span>
                        <span className='text-center font-fira-medita lg:text-justify'>
                            Nuestros jóvenes aprovechan en su adolescencia la oportunidad de madurar y 
                            crecer espiritualmente, acogiendo la propuesta de Jesús de formar parte de la 
                            comunidad (Iglesia), para ir descubriendo la belleza de vivir en fraternidad.
                        </span>
                    </div>
                </div>

            </div>

            </div>
{/* 
            <div className='relative w-full bg-center bg-cover py-52 bg-secundariaFondo'>
                <img src={filtroImagen} alt="filtro" className='absolute top-0 w-full h-full' />
            </div> */}
    </Fragment>

  )
};

export default Secundaria;
