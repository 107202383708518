import React from 'react'
import { Fragment } from 'react/cjs/react.production.min'
import rightArrow from '/src/assets/img/rightWhiteArrow.svg'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'

import { useEffect, useState } from "react";

import slide1 from '/src/assets/img/banner_cc/ccmv.jpg'
import slide4 from '/src/assets/img/banner_cc/artes_plasticas.jpg'
import slide2 from '/src/assets/img/banner_cc/basquetbol_alejandro.jpg'
import slide3 from '/src/assets/img/banner_cc/basquetbol_gustavo.jpg'
import slide5 from '/src/assets/img/banner_cc/collage.jpg'
import slide6 from '/src/assets/img/banner_cc/futbol_emmanuel.jpg'
import slide7 from '/src/assets/img/banner_cc/futbol_jorge.jpg'
import slide8 from '/src/assets/img/banner_cc/ludoteca.jpg'
import slide9 from '/src/assets/img/banner_cc/musica.jpg'
import slide10 from '/src/assets/img/banner_cc/postal_responsables_ccmv.jpg'
import slide11 from '/src/assets/img/banner_cc/teatro.jpg'
import slide12 from '/src/assets/img/banner_cc/voleibol.jpg'

import actividad1 from '/src/assets/img/banner_cc/actividades_1.jpg'
import actividad2 from '/src/assets/img/banner_cc/actividades_2.jpg'
import actividad3 from '/src/assets/img/banner_cc/actividades_3.jpg'
import actividad4 from '/src/assets/img/banner_cc/actividades_4.jpg'
import actividad5 from '/src/assets/img/banner_cc/actividades_5.jpg'
import actividad6 from '/src/assets/img/banner_cc/actividades_6.jpg'
import actividad7 from '/src/assets/img/banner_cc/actividades_7.jpg'
import actividad8 from '/src/assets/img/banner_cc/actividades_8.jpg'
import actividad9 from '/src/assets/img/banner_cc/actividades_9.jpg'
import actividad10 from '/src/assets/img/banner_cc/actividades_10.jpg'
import actividad11 from '/src/assets/img/banner_cc/actividades_11.jpg'
import actividad12 from '/src/assets/img/banner_cc/actividades_12.jpg'
import actividad13 from '/src/assets/img/banner_cc/actividades_13.jpg'
import actividad14 from '/src/assets/img/banner_cc/actividades_14.jpg'
import actividad15 from '/src/assets/img/banner_cc/actividades_15.jpg'
import actividad16 from '/src/assets/img/banner_cc/actividades_16.jpg'
import actividad17 from '/src/assets/img/banner_cc/actividades_17.jpg'
import actividad18 from '/src/assets/img/banner_cc/actividades_18.jpg'
import actividad19 from '/src/assets/img/banner_cc/actividades_19.jpg'
import actividad20 from '/src/assets/img/banner_cc/actividades_20.jpg'

import arrowRight from '/src/assets/img/left.svg'
import arrowLeft from '/src/assets/img/right.svg'

import fb from '/src/assets/img/fbLogo.svg'
import ins from '/src/assets/img/instagramLogo.svg'
import yt from '/src/assets/img/ytLogo.svg'

const CentroCultural = () => {

  const [modalImg, setModalImg] = useState(false)
  const [Img, setImg] = useState(actividad20)


  let playerCentro

  const onYTplayerPress = () => {
    playerCentro = new YT.Player("videoPress", {
        events: {
          onReady: onPlayerReadyPres
        }
      });
    }

    setTimeout(() => {
        onYTplayerPress()
    }, 1000);

    function onPlayerReadyPres(event) {
        var playerButtonPrees = document.getElementById("play-buttonPress");
        playerButtonPrees.addEventListener("click", function () {
        document.getElementById('videoPress').classList.remove('pointer-events-none')
        playerButtonPrees.style.marginTop = "60%"
        playerButtonPrees.style.opacity = "0"
        playerCentro.playVideo();
        });
    }

    function sayHello(){

      setModalImg(true)

    }

    function closeModal(){

      setModalImg(false)
      
    }


    return (

    <Fragment>

      <div className={(modalImg ? 'flex' : 'hidden ') + ' w-full h-screen justify-center items-center fixed z-50 top-0 bg-blackMarce'} >

        <div className='relative w-full lg:w-2/3'>
            
          <div  onClick={closeModal} className='absolute flex items-center justify-center w-16 h-16 text-white border-2 rounded-full cursor-pointer bg-redYt right-4 top-4 border-redYt'>

            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-10 h-10">
                
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                
            </svg>

          </div>

          <img src={Img} className='w-full h-full' />

        </div>

      </div>
        
      <div className="flex flex-col items-center justify-center w-full min-h-screen gap-8 md:pt-20 lg:pt-32">
         
        {/* <div className='relative flex items-center justify-center w-full h-vh75 '>

          <iframe id='videoPress' className='relative w-full h-full pointer-events-none' src="https://www.youtube.com/embed/KIDmRihBZ8E?enablejsapi=1&html5=1&controls=1" title="YouTubeInicio" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          
          <div id='play-buttonPress' className='absolute z-10 p-2 border border-white border-solid rounded-full cursor-pointer w-36 h-36 animateYtBt'>
            
            <div className='flex items-center justify-center w-full h-full rounded-full bg-blueFb'>
            
              <img src={rightArrow} alt="rightIcon" className='w-8 h-8' />

            </div>
          
          </div>

        </div> */}

        <div className='flex flex-col items-center justify-center w-full gap-2 pt-8'>

          <h2 className='text-xl text-center font-fira-bold text-blueMar md:text-2xl 2xl:text-5xl lg:text-3xl'>

            Conoce nuestro centro cultural Marina Videmari

          </h2>

          <h3 className='px-2 text-base text-center 2xl:text-4xl lg:text-2xl md:text-lg text-redMar font-fira-medium'>
          
            Acompáñanos y disfruta de presentaciones artísticas y competencias deportivas

          </h3>

        </div>

        {/* <div  className='flex flex-col items-center justify-center w-full pt-8 custom-dots'>

          <h3  className='text-xl font-fira-bold text-blueMar 2xl:text-4xl lg:text-2xl'>
            
            Actividades

          </h3>

          <Splide  className='relative w-full pb-16 mt-10' 
              // renderControls={ () => (
              //     <div className="absolute inset-0 flex justify-between w-full h-24 px-2 m-auto splide__arrows lg:w-10/12 ">
              //         <button className='splide__arrow--prev '><img src={arrowRight} alt="controlIcons" className='w-16 h-16' /></button>
              //         <button className='splide__arrow--next'><img src={arrowLeft} alt="controlIcons" className='w-16 h-16' /></button>
              //     </div>
              // )}
            options={{
              type : 'slide',
              rewind : 'true',
              rewindSpeed: 500,
              perPage: 1,
              drag:false,
              noDrag:true,
              autoplay:false,
              interval:6000,
              waitForTransition:true,
              keyboard:true,
              pagination:true,
              arrows:true,
            }}>

            <SplideSlide  className="flex items-center justify-center w-full h-full">

              <img src={actividad1} className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad1 );}} />

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad2}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad2 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad3}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad3 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad4}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad4 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad5}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad5 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad6}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad6 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad7}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad7 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad8}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad8 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad9}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad9 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad10}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad10 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad11}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad11 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad12}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad12 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad13}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad13 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad14}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad14 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad15}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad15 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad16}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad16 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad17}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad17 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad18}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad18 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad19}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad19 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={actividad20}  className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( actividad20 );}}/>

            </SplideSlide>
                   
          </Splide>

        </div>

        <div className='flex flex-col items-center justify-center w-full pt-8 custom-dots'>

          <h3 className='text-xl font-fira-bold text-blueMar 2xl:text-4xl lg:text-2xl'>
            
            Docentes

          </h3>

          <Splide className='relative w-full pb-16 mt-10' 
          //   renderControls={ () => (
          //     <div className="absolute inset-0 flex justify-between w-full h-24 px-2 m-auto splide__arrows lg:w-10/12 ">
          //       <button className='splide__arrow--prev '><img src={arrowRight} alt="controlIcons" className='w-16 h-16' /></button>
          //       <button className='splide__arrow--next'><img src={arrowLeft} alt="controlIcons" className='w-16 h-16' /></button>
          //   </div>
          // )}
          options={{
            type : 'slide',
            rewind : 'true',
            rewindSpeed: 500,
            perPage: 1,
            drag:true,
            autoplay:false,
            interval:6000,
            waitForTransition:true,
            keyboard:true,
            pagination:true,
            arrows:true,
          }}>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={slide1} className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( slide1 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={slide2} className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( slide2 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={slide3} className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( slide3 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={slide4} className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( slide4 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={slide5} className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( slide5 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={slide6} className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( slide6 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={slide7} className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( slide7 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={slide8} className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( slide8 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={slide9} className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( slide9 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={slide10} className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( slide10 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={slide11} className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( slide11 );}}/>

            </SplideSlide>

            <SplideSlide className="flex items-center justify-center w-full h-full ">

              <img src={slide12} className='w-10/12 h-full cursor-pointer md:w-1/2' onClick={() => { sayHello(); setImg( slide12 );}}/>

            </SplideSlide>
                  
          </Splide>

        </div> */}

        <div className='flex flex-row items-center justify-around w-full gap-4 px-2 pb-12 mt-16 md:w-2/3 lg:w-2/5 2xl:w-1/4'>
        
          <a href="https://www.facebook.com/profile.php?id=100085233617370&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" className='flex items-center justify-around w-2/6 px-2 py-2 mt-3 text-white rounded-full font-fira-medium bg-blueFb'>Facebook <img className='w-5 h-5 ml-1' src={fb} alt="Fasebook logo" /></a>
          
          <a href="https://www.instagram.com/marinavidemari/?igshid=YWJhMjlhZTc%3D" target="_blank" rel="noopener noreferrer" className='flex items-center justify-around w-2/6 px-2 py-2 mt-3 text-white rounded-full lg:mx-0 font-fira-medium bg-instagram'>Instagram <img className='w-5 h-5 ml-1' src={ins} alt="Youtube logo" /></a>
          
          <a href="https://www.youtube.com/channel/UCoI_idB3zCzeE4qKNmFn2eg" target="_blank" rel="noopener noreferrer" className='flex items-center justify-around w-2/6 px-2 py-2 mt-3 text-white rounded-full font-fira-medium bg-redYt'>Youtube <img className='ml-2 w-7 h-7' src={yt} alt="Instagram logo" /></a>

        </div>

      </div>
      </Fragment>
    )
  }

export default CentroCultural
