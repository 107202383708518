import { useEffect, useRef, useState } from "react"

import Configurador from "./Configurador.jsx"

export default function Pasarela() {
    const [ hash, setLoginData ] = useState(null)
    const password = useRef("")

    function checkLogin(evt) {
        evt.preventDefault()

        fetch("/log_carousel.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                password: password.current
            })
        }).then(response => {
            return response.json()
        }).then(( data ) => {
            if (data.success) {
                sessionStorage.setItem("hash", data.hash)
                setLoginData(data.hash)
            } else {
                alert(data.msg)
            }
        }).catch(err => console.log(err));
    }

    useEffect(() => {
        const login = sessionStorage.getItem( "hash" )?? false

        if (login) {
            fetch("/check.php", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: JSON.stringify({ hash: login })
            }).then(response => {
                return response.json()
            }).then(( data ) => {
                if (data.success) {
                    setLoginData(login)
                } else {
                    sessionStorage.removeItem("hash")
                    setLoginData(false)
                }
            }).catch(err => console.log(err));
        } else {
            setLoginData(false)
        }
    }, [])

    return (
        <div className="flex w-full min-h-screen mt-20 bg-gris-fondo-3 lg:mt-32 lg:min-h-login">
            {hash == null? (
                <span>Cargando...</span>
            ) : (
                hash ? (
                    <Configurador hash={hash} />
                ) : (
                    <div className="grid w-full grid-cols-1 md:grid-cols-2">
                        <img src="https://colegiomarcelina.edu.mx/assets/login.webp" class="relative hidden md:block object-cover w-full h-full" />
                        <form onSubmit={checkLogin} className="flex flex-col items-center justify-center w-full h-full gap-4 p-4">
                            <label className="inline-block w-full max-w-md text-right">
                                <span className="text-2xl font-bold text-center font-mont-bold text-blueFb font-Montse md:text-2xl text-primario md:text-right md:pt-0">INICIAR SESIÓN</span>
                                <br />
                                <br />
                                <input type="password" name="password" placeholder="Contraseña" defaultValue="" onChange={({ target }) => { password.current = target.value }} className="relative w-full h-10 p-2 mb-3 text-xl border-2 rounded-lg campoTexto focus:outline-none font-Cond" />
                            </label>
                            <button type="submit" className="relative w-24 h-10 text-white cursor-pointer focus:border-none focus:outline-none bg-blueFb rounded-3xl lg:relative">Entrar</button>
                        </form>
                    </div>
                )
            )}
        </div>
    )
}
