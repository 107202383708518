import React from 'react';

const InteresadoVideo = () => {
  return (
    <div className='w-full py-16 flex justify-center items-center flex-col'>
        <div className='w-10/12 flex flex-col items-center text-center'>
            <span className='text-2xl font-fira-bold text-blueFb'>
                ¿Estás interesado en formar parte del COLEGIO MARCELINA?
            </span>
            <span className='text-xl font-fira-bold text-redMar mt-2'>
                Contáctanos para agendar una sesión informativa
            </span>
        </div>

        <div className='flex w-full justify-center items-center flex-col mt-14'>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/fCL2cE090jg" title="YouTube video player marce2" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <span className='mt-8 text-xl text-blueFb font-fira-bold'>Mensaje de nuestra directora general</span>
        </div>
    </div>
  )
};

export default InteresadoVideo;
