import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import circuloSld0 from '/src/assets/img/MarinaVidemari.jpg'
import circuloSld1 from '/src/assets/img/luisBiraghi.png'
import circuloSld2 from '/src/assets/img/historicoSld3.png'
import circuloSld3 from '/src/assets/img/historicoSld4.png'
import arrowRight from '/src/assets/img/left.svg'
import arrowLeft from '/src/assets/img/right.svg'
import { useEffect, useState } from "react"

const Principios = () => {
    const [index, setIndex] = useState('1/3');
    return (
        <div id='principios' className='flex flex-col items-center justify-start w-full p-4 pb-10 lg:p-16 bg-grisMar'>
            <div className='flex flex-col items-center justify-center w-full pb-6'>
                <h4 className='text-3xl text-center font-fira-bold text-redMar'>
                    PRINCIPIOS MARCELINA
                </h4>
                <a href="https://www.marcelline.org/" target="_blank" rel="noopener noreferrer" className='text-sm font-fira-medita text-blueFb'>https://www.marcelline.org/</a>
            </div>
            <div className='w-full py-5 text-center'>
                <Splide 
                    className='w-full'
                    onMove={(splide) => {
                        let numero = splide.index
                        setIndex(() => {
                            switch (numero) {
                                case 0:
                                    return "1/3"
                                    break;
                            
                                case 1:
                                    return "2/3"
                                    break;

                                case 2:
                                    return "3/3"
                                    break;
                                
                                case 3:
                                    return "4/4"
                                    break;
                                

                                default:
                                    break;
                            }
                        })
                    }}
                    renderControls={ () => (
                        <div className="absolute inset-0 flex justify-between w-11/12 lg:w-10/12 m-auto splide__arrows">
                            <button className='splide__arrow--prev '><img src={arrowRight} alt="controlIcons" className='w-10 h-10 lg:w-16 lg:h-16' /></button>
                            <button className='splide__arrow--next '><img src={arrowLeft} alt="controlIcons" className='w-10 h-10 lg:w-16 lg:h-16' /></button>
                        </div>
                    )}
                    options={{ 
                        type : 'fade',
                        rewind : 'true',
                        rewindSpeed: 500,
                        perPage: 1,
                        drag:true,
                        autoplay:false,
                        interval:6000,
                        waitForTransition:true,
                        keyboard:true,
                        pagination:false,
                     }}
                >
                    <SplideSlide className='w-full bg-sld1Conocenos bg-contain bg-no-repeat bg-center p-5 flex justify-center h-vh75'>
                            <div className='bg-bluergbaPedagogia w-11/12 p-7 absolute bottom-0'>
                            <span className='text-2xl font-fira-bold text-redMar'>Sor Marina Videmari</span> <br />
                            <span className='font-fira-medita text-white'>
                                ˮ Todas las cosas humanas nacen, crecen y mueren, pero si nuestra Congregación vivirá humilde, 
                                observante de la Regla procurando asimilar el espíritu, seguirá adelante mejor que antes; confío 
                                en Dios con su santa bendición.ˮ
                            </span>
                            </div>
                    </SplideSlide>

                    <SplideSlide className='w-full bg-sld2Conocenos bg-contain bg-no-repeat bg-center p-5 flex justify-center h-vh75'>
                            <div className='bg-bluergbaPedagogia w-11/12 p-7 absolute bottom-0'>
                            <span className='text-2xl font-fira-bold text-redMar'>Beato Luis Biraghi</span> <br />
                            <span className='font-fira-medita text-white'>
                            “Somos una familia con carisma y educación, y creemos que es posible un
                            mundo en que el hombre vive en armonía con Dios, el medio ambiente y consigo mismo”
                            </span>
                            </div>
                    </SplideSlide>

                    <SplideSlide className='w-full bg-sld3Conocenos bg-contain bg-no-repeat bg-center p-5 flex justify-center h-vh75'>
                            <div className='bg-bluergbaPedagogia w-11/12 p-7 absolute bottom-0'>
                            <span className='text-2xl font-fira-bold text-redMar'>Santa Marcelina</span> <br />
                            <span className='font-fira-medita text-white'>
                                En la oración fúnebre pronunciada por San Ambrosio en memoria de su hermano
                                Sátiro, llamó a Marcelina "...santa hermana, admirable por su inocencia, su rectitud y su bondad
                                con el prójimo."
                            </span>
                            </div>
                    </SplideSlide>




                    {/* <SplideSlide className="flex items-center justify-center flex-col w-full lg:p-14">
                        <div className='lg:h-450 w-52 h-52 lg:w-450  p-2 rounded-full lg:p-8 bg-circuloSld bg-100'>
                            <img src={circuloSld0} alt="circulo-sld2" className='w-full h-full rounded-full object-cover'/>
                        </div>
                        <div className='flex flex-col items-center justify-center w-2/3 mt-4 text-center'>
                            <span className='text-2xl font-fira-bold text-redMar'>Sor Marina Videmari</span> 
                            <span className='font-fira-medita'>
                                ˮ Todas las cosas humanas nacen, crecen y mueren, pero si nuestra Congregación vivirá humilde, 
                                observante de la Regla procurando asimilar el espíritu, seguirá adelante mejor que antes; confío 
                                en Dios con su santa bendición.ˮ
                            </span>
                        </div>
                    </SplideSlide> */}
                    {/* <SplideSlide className="flex items-center justify-center flex-col w-full lg:p-14">
                        <div className='lg:h-450 w-52 h-52 lg:w-450  p-2 rounded-full lg:p-8 bg-circuloSld bg-100'>
                            <img src={circuloSld1} alt="circulo-sld2" className='w-full h-full rounded-full object-cover'/>
                        </div>
                        <div className='flex flex-col items-center justify-center w-2/3 mt-4 text-center'>
                            <span className='text-2xl font-fira-bold text-redMar'>Beato Luis Biraghi</span> 
                            <span className='font-fira-medita'>
                            “Somos una familia con carisma y educación, y creemos que es posible un
                            mundo en que el hombre vive en armonía con Dios, el medio ambiente y consigo mismo”
                            </span>
                        </div>
                    </SplideSlide> */}
                    {/* <SplideSlide className="flex items-center justify-center flex-col w-full lg:p-14">
                        <div className='lg:h-450 w-52 h-52 lg:w-450  p-2 rounded-full lg:p-8 bg-circuloSld bg-100'>
                            <img src={circuloSld3} alt="circulo-sld2" className='w-full h-full rounded-full object-cover'/>
                        </div>
                        <div className='flex flex-col items-center justify-center w-2/3 mt-4 text-center'>
                            <span className='text-2xl font-fira-bold text-redMar'>Santa Marcelina</span> 
                            <span className='font-fira-medita'>
                                En la oración fúnebre pronunciada por San Ambrosio en memoria de su hermano
                                Sátiro, llamó a Marcelina "...santa hermana, admirable por su inocencia, su rectitud y su bondad
                                con el prójimo."
                            </span>
                        </div>
                    </SplideSlide> */}
                    {/* <SplideSlide className="flex items-center justify-center flex-col w-full lg:p-14">
                        <div className='lg:h-450 w-52 h-52 lg:w-450  p-2 rounded-full lg:p-8 bg-circuloSld bg-100'>
                            <img src={circuloSld3} alt="circulo-sld2" className='w-full h-full rounded-full'/>
                        </div>
                        <div className='flex flex-col items-center justify-center w-2/3 mt-4 text-center'>
                            <span className='text-2xl font-fira-bold text-redMar'>Sor Mariana Videmari</span> 
                            <span className='font-fira-medita'>
                                ˮ Todas las cosas humanas nacen, crecen y mueren, pero si nuestra Congregación vivirá humilde, 
                                observante de la Regla procurando asimilar el espíritu, seguirá adelante mejor que antes; confío 
                                en Dios con su santa bendición.ˮ
                            </span>
                        </div>
                    </SplideSlide> */}
                </Splide>  
                <div className=' w-full justify-center py-4'>
                    <span className='font-fira-bold text-base'>
                        {index}
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Principios
