import { useEffect, useState } from "react"




export default function Configurador({ hash }) {
    const [ files, setFiles ] = useState([])
    const [ randomized, setRandomized ] = useState()
    const [ loading, setLoading ] = useState(false)
    const [ text, setText ] = useState("")




    async function getPictures() {
        await fetch("/get_pictures.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        }).then(response => {
            return response.json()
        }).then(( data ) => {
            if (data.success) {
                setFiles(data.files)
            } else {
                alert(data.msg)
            }
        }).catch(err => alert(err));

        getToken()

        setLoading(false)
    }

    async function getText() {
        await fetch("/get_text.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        }).then(response => {
            return response.json()
        }).then(( data ) => {
            if (data.success) {
                setText(data.text)
            } else {
                alert(data.msg)
            }
        }).catch(err => alert(err));
    }

    function upImage(index) {
        setLoading(true)

        fetch("/up_image.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                hash: hash,
                index: index,
            })
        }).then(response => {
            return response.json()
        }).then(( data ) => {
            if (data.success) {
                getPictures()
            } else {
                alert(data.msg)
            }
        }).catch(err => alert(err));
    }

    function downImage(index) {
        setLoading(true)

        fetch("/down_image.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                hash: hash,
                index: index,
            })
        }).then(response => {
            return response.json()
        }).then(( data ) => {
            if (data.success) {
                getPictures()
            } else {
                alert(data.msg)
            }
        }).catch(err => alert(err));
    }

    function removeImage(index) {
        setLoading(true)

        fetch("/remove_image.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                hash: hash,
                index: index,
            })
        }).then(response => {
            return response.json()
        }).then(( data ) => {
            if (data.success) {
                getPictures()
            } else {
                alert(data.msg)
            }
        }).catch(err => alert(err));
    }

    function addPicture({ target }) {
        setLoading(true)

        const [ file ] = target.files

        const formData = new FormData()

        formData.append('hash', hash);
        formData.append('file', file);

        fetch("/add_picture.php", {
            method: "POST",
            headers: {
                "Accept": "application/json"
            },
            body: formData
        }).then(response => {
            return response.json()
        }).then(( data ) => {
            if (data.success) {
                location.reload()
            } else {
                alert(data.msg)
            }
        }).catch(err => alert(err));
    }

    function saveText({ target }){
        fetch("/add_text.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                hash: hash,
                text: target.value,
            })
        }).then(response => {
            return response.json()
        }).then(( data ) => {
            if (data.success) {
                setText(data.text)
            } else {
                alert(data.msg)
            }
        }).catch(err => alert(err));
    }

    async function getToken() {
        await fetch("/get_token.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        }).then(response => {
            return response.json()
        }).then(( data ) => {
            if (data.success) {
                setRandomized(data.text)
            } else {
                alert(data.msg)
            }
        }).catch(err => alert(err));
    }




    useEffect(() => {
        setLoading(true)
        getToken()
        getPictures()
        getText()
    }, [])




    return (
        <div className="flex justify-center w-full">
            <div className="flex flex-col w-full max-w-5xl min-h-screen p-4 pb-16 bg-gris-fondo-3 lg:min-h-login">
                <span className="text-xl text-black">Lista de fotos:</span>
                <div className="flex flex-col w-full gap-4 pt-4">
                    {files.map((file, index) => {
                        return (
                            <div key={index} className="relative flex flex-col items-start justify-center min-h-[3.5rem] w-full gap-2 p-4 bg-gray-300 rounded pr-32">
                                <img src={`/carousel_folder/${file}?t=${randomized}`} className="max-w-full max-h-32" />
                                {/* <span>Archivo: {file}</span> */}
                                <div className="absolute top-0 bottom-0 right-0 flex items-center gap-2 px-2 my-auto h-14">
                                    <button type="button" onClick={() => upImage(index +1)} className="flex items-end justify-center w-8 h-8 rounded-full cursor-pointer bg-blueMar">
                                        <div className="w-3.5 h-3.5 mb-1.5 transform block rotate-45 border-t-2 border-l-2 border-gray-100"></div>
                                    </button>
                                    <button type="button" onClick={() => downImage(index +1)} className="flex items-start justify-center w-8 h-8 rounded-full cursor-pointer bg-blueMar">
                                        <div className="w-3.5 h-3.5 mt-1.5 transform block rotate-45 border-b-2 border-r-2 border-gray-100"></div>
                                    </button>
                                    <button type="button" onClick={() => removeImage(index +1)} className="flex items-center justify-center w-8 h-8 rounded-full cursor-pointer bg-redMar">
                                        <div className="relative block w-4 h-4 transform rotate-45">
                                            <div className="absolute top-0 bottom-0 left-0 right-0 block w-1 h-full m-auto bg-white rounded"></div>
                                            <div className="absolute top-0 bottom-0 left-0 right-0 block w-full h-1 m-auto bg-white rounded"></div>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className="w-full py-4">
                    <hr />
                </div>

                <div className="relative block w-full h-32 overflow-hidden text-center border-4 rounded-lg border-blueMar">
                    <input type="file" name="file" id="file" accept="image/*" onChange={addPicture} placeholder="Añadir imagen" className="block w-full h-full" />
                    <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center gap-4 bg-white pointer-events-none">
                        <div className="relative inline-block w-6 h-6 transform">
                            <div className="absolute top-0 bottom-0 left-0 right-0 block w-1 h-full m-auto rounded bg-blueMar"></div>
                            <div className="absolute top-0 bottom-0 left-0 right-0 block w-full h-1 m-auto rounded bg-blueMar"></div>
                        </div>
                        <span style={{ maxWidth: "calc(100% - 4rem)" }} className="inline-block text-xl text-left text-black">
                            Arrastre o haga click aquí para agregar una imagen
                        </span>
                    </div>
                </div>

                <div className="w-full pt-16 pb-4">
                    <hr />
                </div>

                <div className="flex flex-wrap items-center justify-start gap-3 pb-2">
                    <span className="text-xl text-black">Texto para mostrar:</span>
                    <span className="p-3 text-center text-white rounded bg-grayrgba font-fira-medita lg:text-left">{ text }</span>
                </div>
                <input type="text" defaultValue={text} onInput={saveText} className="w-full px-4 border rounded h-11 border-blueMar" placeholder="Escriba el texto del inicio" />
            </div>

            {loading && (
                <div className="fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-40">
                    <div className="p-4 bg-white rounded-lg">
                        <span className="text-xl text-black">Cargando...</span>
                    </div>
                </div>
            )}
        </div>
    )
}
