import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'

const informes = () => {
  return (
    <div className='w-full'>
            <Splide 
                    className='w-full mt-20 lg:mt-32 relative'
                    options={{ 
                        type : 'loop',
                        rewind : 'true',
                        rewindSpeed: 500,
                        perPage: 1,
                        drag:true,
                        autoplay:true,
                        interval:7000,
                        waitForTransition:true,
                        keyboard:true,
                        arrows:false,
                        pagination:true,
                        classes:{
                          pagination: 'absolute bottom-6 isactiveBt flex justify-center w-full z-10',
                          page      : 'w-3 h-3 mx-2 rounded-full bg-gray-300 hover:bg-gray-400', 
                        } 
                     }}
                >
                    <SplideSlide className="bg-principal p-14 w-full h-vh75 bg-no-repeat bg-center bg-cover"></SplideSlide>
                    <SplideSlide className="bg-pedagogia p-14 w-full h-vh75 bg-no-repeat bg-center bg-cover"></SplideSlide>
                    <SplideSlide className="bg-pastoralPortada p-14 w-full h-vh75 bg-no-repeat bg-center bg-cover"></SplideSlide>
              </Splide>
    </div>
  )
};

export default informes;
