import React from 'react'
import arrowGal from '/src/assets/img/arrowGal.svg'
import kinderCirculo from '/src/assets/img/kinderCirculo.png'
import arrowDown from '/src/assets/img/downArrow.svg'
import imagenUno from '/src/assets/img/imagenLenguaje.png'
import imagenDos from '/src/assets/img/kinderCompetencias.png'
import imagenTres from '/src/assets/img/kinderDesarrollo.png'
import imagenCuatro from '/src/assets/img/kinderPrograma.png'
import imagenCinco from '/src/assets/img/kinderConociendo.png'
import { Fragment } from 'react/cjs/react.production.min'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import arrowRight from '/src/assets/img/left.svg'
import arrowLeft from '/src/assets/img/right.svg'
import filtroImagen from '/src/assets/img/primariaFiltro.png'
import rightArrow from '/src/assets/img/rightWhiteArrow.svg'
import circuloSld1 from '/src/assets/img/Preescolar/Preescolar_01.jpg'
import circuloSld2 from '/src/assets/img/Preescolar/Preescolar_02.jpg'
import circuloSld3 from '/src/assets/img/Preescolar/Preescolar_03.jpg'
import circuloSld4 from '/src/assets/img/Preescolar/Preescolar_04.jpg'
import circuloSld5 from '/src/assets/img/Preescolar/Preescolar_05.jpg'
import circuloSld6 from '/src/assets/img/Preescolar/Preescolar_06.jpg'
import circuloSld7 from '/src/assets/img/Preescolar/Preescolar_07.jpg'
import circuloSld8 from '/src/assets/img/Preescolar/Preescolar_08.jpg'
import circuloSld9 from '/src/assets/img/Preescolar/Preescolar_09.jpg'
// import circuloSld10 from '/src/assets/img/Preescolar/Preescolar_10.jpg'
import circuloSld11 from '/src/assets/img/Preescolar/Preescolar_11.jpg'
// import circuloSld12 from '/src/assets/img/Preescolar/Preescolar_12.jpg'
// import circuloSld13 from '/src/assets/img/Preescolar/Preescolar_13.jpg'
import circuloSld14 from '/src/assets/img/Preescolar/Preescolar_14.jpg'
// import circuloSld15 from '/src/assets/img/Preescolar/Preescolar_15.jpg'
// import circuloSld16 from '/src/assets/img/Preescolar/Preescolar_16.jpg'
// import circuloSld17 from '/src/assets/img/Preescolar/Preescolar_17.jpg'
// import circuloSld18 from '/src/assets/img/Preescolar/Preescolar_18.jpg'
// import circuloSld19 from '/src/assets/img/Preescolar/Preescolar_19.jpg'
// import circuloSld20 from '/src/assets/img/Preescolar/Preescolar_20.jpg'
// import circuloSld21 from '/src/assets/img/Preescolar/Preescolar_21.jpg'
// import circuloSld22 from '/src/assets/img/Preescolar/Preescolar_22.jpg'
// import circuloSld23 from '/src/assets/img/Preescolar/Preescolar_23.jpg'


const preescolar = () => {
        let playerPreescolar
        const onYTplayerPress = () => {
            playerPreescolar = new YT.Player("videoPress", {
                events: {
                  onReady: onPlayerReadyPres
                }
              });
        }
    
        setTimeout(() => {
            onYTplayerPress()
        }, 1000);
        
        
    
        function onPlayerReadyPres(event) {
            var playerButtonPrees = document.getElementById("play-buttonPress");
            playerButtonPrees.addEventListener("click", function () {
            document.getElementById('videoPress').classList.remove('pointer-events-none')
            playerButtonPrees.style.marginTop = "60%"
            playerButtonPrees.style.opacity = "0"
            playerPreescolar.playVideo();
            });
        }

        // let playerPreescolarInsta
        // const onYTplayerPressInsta = () => {
        //     playerPreescolarInsta = new YT.Player("videoPressInstalaciones", {
        //         events: {
        //           onReady: onPlayerReadyPresInsta
        //         }
        //       });
        // }
    
        // setTimeout(() => {
        //     onYTplayerPressInsta();
        // }, 1000);
        
        
    
        // function onPlayerReadyPresInsta(event) {
        //     var playerButtonPreesInsta = document.getElementById("playButtonPressInsta");
        //     playerButtonPreesInsta.addEventListener("click", function () {
        //     document.getElementById('videoPressInstalaciones').classList.remove('pointer-events-none')
        //     playerButtonPreesInsta.style.marginTop = "60%"
        //     playerButtonPreesInsta.style.opacity = "0"
        //     playerPreescolarInsta.playVideo();
        //     });
        // }

        // const slidesRender = () => {
        //     for (let i = 1; i < 23; i++) {
        //         console.log("CIclo ?");
        //         return (
        //             <SplideSlide className="flex items-center justify-center w-full p-14">
        //                 <div className='w-4/12 p-8 rounded-full bg-circuloSld bg-100'>
        //                     <img src={circuloSld1} alt={"circulo-sld"+i} className='w-full h-full'/>
        //                 </div>
        //             </SplideSlide>
        //             )
                
        //     }
        // }

    return (
        <Fragment>
            <div className='relative flex justify-center w-full mt-20 bg-center bg-cover lg:mt-32 h-vh75 bg-preescolar'>
                <div className='absolute z-10 flex items-center justify-center w-20 h-20 rounded-full -bottom-10 lg:-bottom-14 lg:h-28 lg:w-28 bg-bluergba'>
                    <img src={arrowDown} alt="arrow down icon" className='w-4 h-4' />
                </div>
            </div>
            <div className='relative w-full px-4 py-10 bg-center bg-no-repeat bg-cover bg-blueFb lg:bg-fondoAzul lg:bg-100 lg:px-10 '>
                <div className='absolute w-8 h-8 rounded-full bg-redMar left-10 top-10'></div>
                <div className='absolute hidden w-4 h-4 rounded-full bg-redMar left-2/4 -bottom-10 lg:block'></div>
                <div className='flex justify-end w-full'>
                    <div className='inline-flex items-center justify-end'>
                        <a href='#galeriaPres' className='text-base text-white lg:text-lg font-fira-semiboldNo'>
                            VER GALERÍA
                        </a>
                        <img src={arrowGal} alt="VerGaleria" className='w-20 h-4 lg:w-28'/>
                    </div>
                </div>
                <div className='flex flex-col w-full lg:flex-row'>
                    <div className='flex justify-center w-full py-8 lg:w-1/2 lg:block lg:py-0'>
                        <div className='relative border-4 border-solid h-72 w-72 lg:absolute lg:p-2 circulo-fondo-linea-kinder 2xl:h-450 2xl:w-450 lg:w-96 lg:h-96 lg:-bottom-28 2xl:-bottom-44 lg:left-48'>
                            <img src={kinderCirculo} alt="circuloNinos" className='w-full h-full'/>
                        </div>
                    </div>
                    <div className='flex flex-col items-start justify-center w-full p-3 lg:w-1/2'>

                        <div>
                            <h2 className='mt-5 text-3xl text-white font-fira-bold'>PREESCOLAR</h2>
                        </div>
                        <div className='flex flex-col w-10/12 mt-4 mb-12'>
                            <span className='text-base text-white font-fira-medita'>Sabemos la importancia del desarrollo humano en la primera infancia. 
                                                                                    El juego, el arte, la música, la literatura y la exploración de su entorno son pilares 
                                                                                    y escencia en nuestro Sistema Marcelina.
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='relative flex items-center justify-center w-full mt-0 h-vh75 lg:mt-52 '>
                <iframe id='videoPress' className='relative w-full h-full pointer-events-none' src="https://www.youtube.com/embed/TLq7EDd-IjY?enablejsapi=1&html5=1&controls=1" title="YouTubeInicio" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div id='play-buttonPress' className='absolute z-10 p-2 border border-white border-solid rounded-full cursor-pointer w-36 h-36 animateYtBt'>
                        <div className='flex items-center justify-center w-full h-full rounded-full bg-blueFb'>
                            <img src={rightArrow} alt="rightIcon" className='w-8 h-8' />
                        </div>
                </div>
            </div> */}

            <div className='flex items-center justify-center w-full h-auto py-10 bg-cover lg:h-vh75 bg-filtroPedagogia lg:p-8'>
                <div className='flex flex-col items-center justify-center w-full bg-cartaMob lg:bg-cartaAcademica 2xl:w-1/2 h-750 2xl:h-550 lg:h-400 lg:w-1/2 bg-100 lg:items-start p-11'>
                    <span className='mt-40 text-2xl text-center font-fira-boldIta lg:text-4xl text-blueFb lg:text-left lg:mt-0'>Bienvenido a <br /> Preescolar Marcelina</span>
                    <div className='relative flex items-center justify-center w-full -bottom-16 lg:-bottom-16'>
                        <a href="Bienvenido a Preescolar Marcelina.pdf" target="_blank" rel="noopener noreferrer" className='flex items-center px-6 py-2 mt-5 rounded-full lg:mt-0 bg-redMar'>
                            <span className='text-white font-fira-semiboldNo'>Descargar carta</span>
                            <img src={arrowDown} alt="arrowDown" className='h-2.5 w-2.5 ml-3' />
                        </a>
                    </div>
                </div>
            </div>

            <div className='flex justify-center w-full py-20 lg:p-20'>

                <div className='flex flex-col w-10/12 lg:w-8/12'>

                    <div className='flex flex-col items-center w-full lg:flex-row lg:justify-between py-7 lg:min-w-650'>
                        <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                            <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                                <img src={imagenUno} alt="img1" className='w-full h-full' />
                            </div>
                        </div>
                        <div className='flex flex-col w-full lg:w-2/3'>
                            <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Segunda lengua</span>
                            <span className='text-center font-fira-medita lg:text-justify'>
                                Construimos un ambiente de aprendizaje en el que se favorece el conocimiento de
                                otras culturas, ideas, costumbres y lenguajes a través del idioma inglés por
                                medio de situaciones reales y lúdicas que les permita reconocer, valorar y amar a
                                cada persona más allá de la cercanía física.
                            </span>
                        </div>
                    </div>

                    <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                        <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                            <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                                <img src={imagenDos} alt="img1" className='w-full h-full' />
                            </div>
                        </div>
                        <div className='flex flex-col w-full lg:w-2/3'>
                            <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Competencias digitales</span>
                            <span className='text-center font-fira-medita lg:text-justify'>
                                Nuestros pequeños incursionan de manera moderada en el uso de dispositivos
                                electrónicos. El proyecto de tecnología va acompañado de material didáctico Lego
                                y el uso de iPad´s
                            </span>
                        </div>
                    </div>

                    <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                        <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                            <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                                <img src={imagenTres} alt="img1" className='w-full h-full' />
                            </div>
                        </div>
                        <div className='flex flex-col w-full lg:w-2/3'>
                            <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Desarrollo socioemocional</span>
                            <span className='text-center font-fira-medita lg:text-justify'>
                                Nos centramos en que desarrollen un sentido positivo de si mismos, regulen sus
                                emociones, aprendan a trabajar en colaboración, valoren sus logros individuales
                                y colectivos, resuelvan conﬂictos mediante el diálogo y convivencia fraterna.
                            </span>
                        </div>
                    </div>


                    <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                        <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                            <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                                <img src={imagenCuatro} alt="img1" className='w-full h-full' />
                            </div>
                        </div>
                        <div className='flex flex-col w-full lg:w-2/3'>
                            <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Programa escolar</span>
                            <span className='text-center font-fira-medita lg:text-justify'>
                                Nuestros pequeños comienzan su proceso de adquisición de la lengua escrita
                                desde un enfoque constructivista y con proyectos, secuencias y situaciones
                                didácticas signiﬁcativas, así como retos cognitivos.
                            </span>
                        </div>
                    </div>


                    <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                        <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                            <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                                <img src={imagenCinco} alt="img1" className='w-full h-full' />
                            </div>
                        </div>
                        <div className='flex flex-col w-full lg:w-2/3'>
                            <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Conociendo a Jesús</span>
                            <span className='text-center font-fira-medita lg:text-justify'>
                                Reconocemos a Dios como Padre y Creador, descubriendo la naturaleza como un
                                gran regalo y nuestro cuerpo como una expresión de amor.
                            </span>
                        </div>
                    </div>

                </div>

            </div>

            <div className='relative w-full bg-cover py-52 bg-kinderPortada bg-pos-20'>
                <img src={filtroImagen} alt="filtro" className='absolute top-0 w-full h-full' />
            </div>

            <div id='galeriaPres' className='flex flex-col items-center justify-center w-full px-0 bg-center bg-no-repeat bg-cover lg:bg-100 bg-identidad lg:px-40 lg:py-20 py-11'>
                <span className='text-3xl font-fira-bold text-redMar'>GALERÍA</span>
                <Splide 
                    className='relative w-full mt-14 lg:h-vh85'
                    renderControls={ () => (
                        <div className="inset-0 flex justify-between w-10/12 m-auto splide__arrows lg:absolute">
                            <button className='splide__arrow--prev '><img src={arrowRight} alt="controlIcons" className='w-16 h-16' /></button>
                            <button className='splide__arrow--next'><img src={arrowLeft} alt="controlIcons" className='w-16 h-16' /></button>
                        </div>
                    )}
                    options={{ 
                        type : 'loop',
                        rewind : 'true',
                        rewindSpeed: 500,
                        padding:'10rem',
                        perPage: 1,
                        drag:true,
                        autoplay:false,
                        interval:6000,
                        waitForTransition:true,
                        keyboard:true,
                        pagination:false,
                        breakpoints: {
                            900: {
                                padding:'1rem',
                            },
                        }
                     }}
                >
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                        <img src={circuloSld1} alt="circulo-sld2" className='object-cover w-full h-full'/>
                        
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                        {/* <div className='w-full p-2 bg-100 lg:p-8'> */}
                            <img src={circuloSld2} alt="circulo-sld2" className='object-cover w-full h-full'/>
                        {/* </div> */}
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                        <img src={circuloSld3} alt="circulo-sld2" className='object-cover w-full h-full'/>
                        {/* <div className='w-full p-2 bg-100 lg:p-8'>
                            <img src={circuloSld2} alt="circulo-sld2" className='w-full h-full'/>
                        </div> */}
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                        <img src={circuloSld4} alt="circulo-sld2" className='object-cover w-full h-full'/>
                        {/* <div className='w-full p-2 bg-100 lg:p-8'>
                            <img src={circuloSld3} alt="circulo-sld2" className='w-full h-full'/>
                        </div> */}
                    </SplideSlide>
                    {/* <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                        <img src={circuloSld1} alt="circulo-sld2" className='object-cover w-full h-full'/>
                        
                    </SplideSlide> */}
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld5} alt="circulo-sld2" className='object-cover w-full h-full'/>
                        {/* <div className='w-64 p-2 rounded-full lg:w-4/12 bg-circuloSld bg-100 lg:p-8'>
                        </div> */}
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld6} alt="circulo-sld2" className='object-cover w-full h-full'/>
                        {/* <div className='w-64 p-2 rounded-full lg:w-4/12 bg-circuloSld bg-100 lg:p-8'>
                        </div> */}
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld7} alt="circulo-sld2" className='object-cover w-full h-full'/>
                        {/* <div className='w-64 p-2 rounded-full lg:w-4/12 bg-circuloSld bg-100 lg:p-8'>
                        </div> */}
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld8} alt="circulo-sld2" className='object-cover w-full h-full'/>
                        {/* <div className='w-64 p-2 rounded-full lg:w-4/12 bg-circuloSld bg-100 lg:p-8'>
                        </div> */}
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld9} alt="circulo-sld2" className='object-cover w-full h-full'/>
                        {/* <div className='w-64 p-2 rounded-full lg:w-4/12 bg-circuloSld bg-100 lg:p-8'>
                        </div> */}
                    </SplideSlide>
                    {/* <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld10} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide> */}
                        {/* <div className='w-64 p-2 rounded-full lg:w-4/12 bg-circuloSld bg-100 lg:p-8'>
                        </div> */}
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld11} alt="circulo-sld2" className='object-cover w-full h-full'/>
                        {/* <div className='w-64 p-2 rounded-full lg:w-4/12 bg-circuloSld bg-100 lg:p-8'>
                        </div> */}
                    </SplideSlide>
                    {/* <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld12} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide> */}
                        {/* <div className='w-64 p-2 rounded-full lg:w-4/12 bg-circuloSld bg-100 lg:p-8'>
                        </div> */}
                    {/* <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld13} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide> */}
                        {/* <div className='w-64 p-2 rounded-full lg:w-4/12 bg-circuloSld bg-100 lg:p-8'>
                        </div> */}
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld14} alt="circulo-sld2" className='object-cover w-full h-full'/>
                        {/* <div className='w-64 p-2 rounded-full lg:w-4/12 bg-circuloSld bg-100 lg:p-8'>
                        </div> */}
                    </SplideSlide>
                    {/* <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld15} alt="circulo-sld2" className='object-cover w-full h-full'/>
                        
                    </SplideSlide> */}
                    {/* <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld16} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld17} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld18} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld19} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld20} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld21} alt="circulo-sld2" className='object-cover w-full h-full'/>
                        
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld22} alt="circulo-sld2" className='object-cover w-full h-full'/>
                        
                    </SplideSlide> */}
                    

                   
                </Splide>

            {/*          classes:{
                            pagination: 'hidden',
                            page      : 'w-3 h-3 mx-2 rounded-full bg-gray-300 hover:bg-gray-400',
                            arrows    : 'w-full flex justify-between',
                            prev      : '',
                            next      : ''
                        } */}
            </div>

            {/* <div className='flex w-full px-10 py-16'>
                
                <div className='flex items-center justify-center w-1/2 pt-12 pb-5'>
                    <div className='flex flex-col items-start justify-center text-left'>
                        <span className='text-xl font-fira-semibold text-blueMar'>Escuela Marcelina</span>
                        <h3 className='text-5xl font-fira-bold text-blueMar'>PREESCOLAR</h3>
                    </div>
                </div>

                <div className='w-full p-5 lg:w-1/2 '>
                    <div className='relative flex items-center justify-center w-full px-10 py-16 h-500'>
                        <iframe id='videoPressInstalaciones' className='relative w-full h-full pointer-events-none' src="https://www.youtube.com/embed/pYT9q8XFMKc?enablejsapi=1&html5=1&mute=1&controls=0" title="YouTubeInicio" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div id='playButtonPressInsta' className='absolute z-10 p-2 border border-white border-solid rounded-full cursor-pointer w-36 h-36 animateYtBt'>
                                    <div className='flex items-center justify-center w-full h-full rounded-full bg-blueFb'>
                                        <img src={rightArrow} alt="rightIcon" className='w-8 h-8' />
                                </div>
                        </div>
                    </div>
                </div>

            </div> */}


            {/* 
                    <SplideSlide className="flex items-center justify-center w-full p-5 lg:p-14">
                        <div className='w-full p-2 bg-100 lg:p-8'>
                            <img src={circuloSld22} alt="circulo-sld2" className='w-full h-full'/>
                        </div>
                    </SplideSlide>
             */}

        </Fragment>
    )
}

export default preescolar
