import React from 'react'
import arrowDown from '/src/assets/img/downArrow.svg'

const portada = () => {
    return (
        <div className='relative flex justify-center w-full bg-center bg-cover lg:mt-32 h-vh75 bg-conocenos'>
            <div className='absolute flex items-center justify-center rounded-full -bottom-14 h-28 w-28 bg-bluergba'>
                <img src={arrowDown} alt="arrow down icon" className='w-4 h-4' />
            </div>
        </div>
    )
}

export default portada
