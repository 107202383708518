import React from 'react'

const Chat = () => {
    return (
        <a href='https://wa.link/sf28fs' target='_blank' className='hidden fixed bottom-0 right-10 p-3 lg:flex items-center justify-between z-50 bg-blueMar rounded-t-2xl shadow-md'>
            <span className='text-white font-fira-medium text-base'>¡Chatea con nosotros, estamos en línea!</span>
            <div className='inline-flex ml-4'>
                <div className='bg-white rounded-full w-2 h-2'></div>
                <div className='bg-white rounded-full w-2 h-2 mx-1'></div>
                <div className='bg-white rounded-full w-2 h-2'></div>
            </div>
        </a>
    )
}

export default Chat
