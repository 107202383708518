import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import arrowRight from '/src/assets/img/left.svg'
import arrowLeft from '/src/assets/img/right.svg'
import circuloSld1 from '/src/assets/img/metodologias.png'
import circuloSld2 from '/src/assets/img/protagonista.jpg'
import circuloSld3 from '/src/assets/img/comunicacion.jpg'

const modelos = () => {
    return (
        <div className='flex flex-wrap justify-center w-full px-7 py-10 lg:p-16 bg-grisMar'>
            <div className='w-full'>
                <Splide 
                        className='w-full'
                        renderControls={ () => (
                            <div className="relative lg:absolute lg:inset-0 flex justify-center lg:justify-between lg:w-11/12  w-full  m-auto splide__arrows lg:py-0 py-8">
                                <button className='splide__arrow--prev '><img src={arrowRight} alt="controlIcons" className=' w-14 lg:w-16 h-14 lg:h-16 mr-5 lg:m-0' /></button>
                                <button className='splide__arrow--next '><img src={arrowLeft} alt="controlIcons" className=' w-14 lg:w-16 h-14 lg:h-16 ml-5 lg:m-0' /></button>
                            </div>
                        )}
                        options={{ 
                            type : 'fade',
                            rewind : 'true',
                            rewindSpeed: 500,
                            perPage: 1,
                            drag:true,
                            autoplay:true,
                            interval:6000,
                            waitForTransition:true,
                            keyboard:true,
                            pagination:false,
                            breakpoints: {
                                1024: {
                                    autoplay:false,
                                },
                            }
                        }}
                    >


                    <SplideSlide className='w-full bg-sld1Metodos bg-cover bg-no-repeat bg-center p-5 flex justify-center h-vh75'>
                            <div className='bg-bluergbaPedagogia w-11/12 p-7 absolute bottom-0 text-center'>
                                <span className='text-2xl font-fira-bold text-redMar'>Modelo educativo</span> <br />
                                <span className='text-3xl font-fira-bold text-redMar'>METODOLOGÍAS INDUCTIVAS</span> <br />
                                <span className='font-fira-medita text-white'>
                                    Metodologías que estimulan la imaginación, la investigación y la creatividad
                                </span>
                            </div>
                    </SplideSlide>

                    <SplideSlide className='w-full bg-sld2Metodos bg-cover bg-no-repeat bg-center p-5 flex justify-center h-vh75'>
                            <div className='bg-bluergbaPedagogia w-11/12 p-7 absolute bottom-0 text-center'>
                                <span className='text-2xl font-fira-bold text-redMar'>Nuestros alumnos son los</span> <br />
                                <span className='text-3xl font-fira-bold text-redMar'>PROTAGONISTAS</span> <br />
                                <span className='font-fira-medita text-white'>
                                    de su propio aprendizaje
                                </span>
                            </div>
                    </SplideSlide>

                    <SplideSlide className='w-full bg-sld3Metodos bg-cover bg-no-repeat bg-center p-5 flex justify-center h-vh75'>
                            <div className='bg-bluergbaPedagogia w-11/12 p-7 absolute bottom-0 text-center'>
                            <span className='text-2xl font-fira-bold text-redMar'>Interacción y </span> <br />
                            <span className='text-3xl font-fira-bold text-redMar'>COLABORACIÓN</span> <br />
                            <span className='font-fira-medita text-white'>
                                para potenciar
                                el aprendizaje
                            </span>
                            </div>
                    </SplideSlide>

                    {/* <SplideSlide className="flex flex-col items-center justify-center w-full p-10 lg:p-14">
                        <div className='w-60 h-60 p-3 lg:w-96 lg:h-96 lg:p-5 rounded-full bg-circuloSld bg-100'>
                            <img src={circuloSld2} alt="circulo-sld2" className='w-full h-full rounded-full object-cover'/>
                        </div>
                        <div className='flex flex-col items-center justify-center lg:w-2/3 w-full mt-12 text-center'>
                            <span className='text-2xl font-fira-bold text-redMar'>Modelo educativo</span> 
                            <span className='text-3xl font-fira-bold text-redMar'>METODOLOGÍAS INDUCTIVAS</span> 
                            <span className='font-fira-medita text-blueFb'>
                                Metodologías que estimulan la imaginación, la investigación y la creatividad
                            </span>
                        </div>
                        <div className='w-full mt-5 text-center'>
                            <span className='font-fira-bold text-blueMar'>1/3</span>
                        </div>
                    </SplideSlide> */}

                    {/* <SplideSlide className="flex flex-col items-center justify-center w-full p-10 lg:p-14">
                        <div className='w-60 h-60 p-3 lg:w-96 lg:h-96 lg:p-5 rounded-full bg-circuloSld bg-100'>
                            <img src={circuloSld1} alt="circulo-sld2" className='w-full h-full rounded-full object-cover'/>
                        </div>
                        <div className='flex flex-col items-center justify-center lg:w-2/3 w-full mt-12 text-center'>
                            <span className='text-2xl font-fira-bold text-redMar'>Nuestros alumnos son los</span> 
                            <span className='text-3xl font-fira-bold text-redMar'>PROTAGONISTAS</span> 
                            <span className='font-fira-medita text-blueFb'>
                                de su propio aprendizaje
                            </span>
                        </div>
                        <div className='w-full mt-5 text-center'>
                            <span className='font-fira-bold text-blueMar'>2/3</span>
                        </div>
                    </SplideSlide> */}

                    {/* <SplideSlide className="flex flex-col items-center justify-center w-full p-10 lg:p-14">
                        <div className='w-60 h-60 p-3 lg:w-96 lg:h-96 lg:p-5 rounded-full bg-circuloSld bg-100'>
                            <img src={circuloSld3} alt="circulo-sld2" className='w-full h-full rounded-full object-cover'/>
                        </div>
                        <div className='flex flex-col items-center justify-center lg:w-2/3 w-full mt-12 text-center'>
                            <span className='text-2xl font-fira-bold text-redMar'>Interacción y </span> 
                            <span className='text-3xl font-fira-bold text-redMar'>COLABORACIÓN</span> 
                            <span className='font-fira-medita text-blueFb'>
                                para potenciar
                                el aprendizaje
                            </span>
                        </div>
                        <div className='w-full mt-5 text-center'>
                            <span className='font-fira-bold text-blueMar'>3/3</span>
                        </div>
                    </SplideSlide> */}
                </Splide>
            </div>
        </div>
    )
}

export default modelos
