import React from 'react'
import circuloSld0 from '/src/assets/img/googlews.png'
import circuloSld1 from "/src/assets/img/cambridge.png"
import circuloSld2 from '/src/assets/img/globalEducation.png'
import circuloSld3 from '/src/assets/img/aprendeId.png'
import circuloSld4 from '/src/assets/img/anahuac.png'
import circuloSld5 from '/src/assets/img/cambridge.png'
import circuloSld6 from '/src/assets/img/ebc.png'
import circuloSld7 from '/src/assets/img/state.png'
import circuloSld8 from '/src/assets/img/tecMon.png'
import circuloSld9 from '/src/assets/img/uco.png'
import circuloSld10 from '/src/assets/img/udlap.png'
import circuloSld11 from '/src/assets/img/uvm.png'
import { useEffect, useState } from "react"
import { Splide, SplideSlide } from '@splidejs/react-splide'

const convenio = () => {
    const [imageUrl, setImageUrl] = useState(circuloSld0);
    const [sliderImg, setSliderImg] = useState(0);
    const changeImage = (imageSelect) =>{
        switch (imageSelect) {
            case 1:
                    setImageUrl(circuloSld0)
                break;
            case 2:
                    setImageUrl(circuloSld1)
                break;

            case 3:
                    setImageUrl(circuloSld2)
                break;

            case 4:
                    setImageUrl(circuloSld3)
                break;

            case 5:
                    setImageUrl(0)
                break;
        
            default:
                break;
        }
    }
    

    return (
        <div className='w-full bg-fondoAzul bg-cover bg-center lg:bg-100 bg-no-repeat lg:py-10 py-16 flex justify-around'>

            <div className='w-1/2 relative flex justify-center items-center'>

                <div className='bg-redMar rounded-full lg:w-8 lg:h-8 w-4 h-4 absolute right-3 -bottom-2 lg:right-10 lg:bottom-0'></div>

                <div className='circulo-fondo lg:h-80 lg:w-80 w-40 h-40 flex justify-center items-center bg-white'>
                                
                    <img src={imageUrl} alt="circuloMujer" className={(imageUrl == 0 ? 'hidden ' : '')+'w-2/3'} />
                    
                    <Splide 
                         className={(imageUrl == 0 ? 'flex' : 'hidden')+' hiddFix justify-center items-center'}
                        options={{ 
                            type : 'loop',
                            perPage: 1,
                            drag:true,
                            autoplay:true,
                            interval:3000,
                            keyboard:false,
                            pagination:false,
                            arrows : false
                        }}
                    >
                        <SplideSlide className='lg:h-80 lg:w-80 w-40 h-40 flex justify-center items-center'>
                            <img src={circuloSld5} alt="circuloescuela1" className='w-2/3' />
                        </SplideSlide>
                    
                        <SplideSlide className='lg:h-80 lg:w-80 w-40 h-40 flex justify-center items-center'>
                            <img src={circuloSld4} alt="circuloescuela1" className='w-2/3' />
                        </SplideSlide>

                        <SplideSlide className='lg:h-80 lg:w-80 w-40 h-40 flex justify-center items-center'>
                            <img src={circuloSld6} alt="circuloescuela1" className='w-2/3' />
                        </SplideSlide>

                        <SplideSlide className='lg:h-80 lg:w-80 w-40 h-40 flex justify-center items-center'>
                            <img src={circuloSld7} alt="circuloescuela1" className='w-2/3' />
                        </SplideSlide>

                        <SplideSlide className='lg:h-80 lg:w-80 w-40 h-40 flex justify-center items-center'>
                            <img src={circuloSld8} alt="circuloescuela1" className='w-2/3' />
                        </SplideSlide>

                        <SplideSlide className='lg:h-80 lg:w-80 w-40 h-40 flex justify-center items-center'>
                            <img src={circuloSld9} alt="circuloescuela1" className='w-2/3' />
                        </SplideSlide>

                        <SplideSlide className='lg:h-80 lg:w-80 w-40 h-40 flex justify-center items-center'>
                            <img src={circuloSld10} alt="circuloescuela1" className='w-2/3' />
                        </SplideSlide>

                        <SplideSlide className='lg:h-80 lg:w-80 w-40 h-40 flex justify-center items-center'>
                            <img src={circuloSld11} alt="circuloescuela1" className='w-2/3' />
                        </SplideSlide>

                    </Splide>

                </div>
                
                <div className='bg-redMar rounded-full lg:w-8 lg:h-8 w-4 h-4 absolute -top-5 left-5 lg:left-10 lg:top-0'></div>

            </div>

            <div className='w-1/2 flex items-start pl-6 flex-col justify-center'>

                <div>
                    <h2 className='text-white font-fira-bold text-xl lg:text-3xl mt-5'>CONVENIOS</h2>
                </div>

                <ul className='text-white font-fira-medita text-sm lg:text-lg list-disc mt-2 lg:mt-0'>

                    <li onClick={() => changeImage(1)} className='my-2 cursor-pointer'>
                        Google Workspace
                    </li>

                    <li onClick={() => changeImage(2)} className='my-2 cursor-pointer'>
                        Casa Certificadora de Cambridge
                    </li>

                    <li onClick={() => changeImage(3)} className='my-2 cursor-pointer'>
                        Pacto Educativo Global
                    </li>

                    <li onClick={() => changeImage(4)} className='my-2 cursor-pointer'>
                        Aprende ID
                    </li>

                    <li onClick={() => changeImage(5)} className='my-2 cursor-pointer'>
                        Universidades
                    </li>

                </ul>

            </div>

        </div>
    )
}

export default convenio
