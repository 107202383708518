import React from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import flor from '/src/assets/img/florGerminado.png' 
import { useEffect, useState } from "react"
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import arrowRightYl from '/src/assets/img/leftArrowYellow.svg'
import arrowLeftYl from '/src/assets/img/rightArrowYellow.svg'
import germinado from '/src/assets/img/circuloGerminado.png'
import perseverando from '/src/assets/img/perseverando.png'
import sembrando from '/src/assets/img/sembrando.png'
import floreciendo from '/src/assets/img/floreciendo.png'
import compartiendo from '/src/assets/img/compartiendo.png'

const PatoralGerminado = () => {
  return ( 
      <Fragment>
        <Splide 
                    className='w-full bg-pastoralGerminado bg-cover bg-center lg:bg-100'
                    renderControls={ () => (
                        <div className="splide__arrows w-full lg:h-5 flex justify-between absolute bottom-0 px-10 pb-12 lg:inset-0 m-auto">
                            <button className='splide__arrow--prev rounded-full bg-white h-12 w-12 flex justify-center items-center'><img src={arrowRightYl} alt="controlIcons" className='w-6 h-6' /></button>
                            <button className='splide__arrow--next rounded-full bg-white h-12 w-12 flex justify-center items-center'><img src={arrowLeftYl} alt="controlIcons" className='w-6 h-6' /></button>
                        </div>
                    )}
                    options={{ 
                        type : 'loop',
                        rewind : 'true',
                        rewindSpeed: 500,
                        perPage: 1,
                        drag:true,
                        autoplay:false,
                        interval:9000,
                        waitForTransition:true,
                        keyboard:true,
                        pagination:false,
                    }}
                >
                    <SplideSlide>
                    <div className='w-full h-40 bg-cafeMarce'></div>
                      <div className='w-full lg:p-20 lg:h-96 pb-32'>
                        <div className='lg:inline-flex flex flex-col lg:flex-row w-full'>
                            <div className='w-full lg:w-1/2 relative flex justify-center'>
                              <div className='lg:right-10 circulo-fondo-germinado lg:absolute relative w-72 h-72 lg:h-96 lg:w-96 -top-32 lg:-top-40'>
                                <img src={sembrando} alt="germinadoImg" className='w-full h-full rounded-full'/>
                              </div>
                            </div>
                            <div className='w-full lg:w-1/2 relative flex justify-center'>
                              <div className='relative lg:absolute lg:left-10 bg-cuadroGerminado bg-100 h-80 w-80 lg:-top-40 pt-14 px-5 flex flex-col'>
                                  <img src={flor} alt="flor" className='absolute -top-16 -right-20 h-44 w-72' />
                                  <span className='font-fira-bold text-2xl text-cafeMarce '>
                                    Sembrando:
                                  </span>
                                  <span className='font-fira-regular text-lg text-cafeMarce mt-3'>
                                    Recibe la buena nueva del Evangelio y se descubre
                                    como hijo a través de la experiencia del amor del Dios Padre.
                                  </span>
                              </div>
                            </div>
                        </div>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div className='w-full h-40 bg-cafeMarce'></div>
                        <div className='w-full lg:p-20 lg:h-96 pb-32'>
                          <div className='lg:inline-flex flex flex-col lg:flex-row w-full'>
                              <div className='w-full lg:w-1/2 relative flex justify-center'>
                                <div className='lg:right-10 circulo-fondo-germinado lg:absolute relative w-72 h-72 lg:h-96 lg:w-96 -top-32 lg:-top-40'>
                                  <img src={germinado} alt="germinadoImg" className='w-full h-full'/>
                                </div>
                              </div>
                              <div className='w-full lg:w-1/2 relative flex justify-center'>
                                <div className='relative lg:absolute lg:left-10 bg-cuadroGerminado bg-100 h-80 w-80 lg:-top-40 pt-14 px-5 flex flex-col'>
                                    <img src={flor} alt="flor" className='absolute -top-16 -right-20 h-44 w-72' />
                                    <span className='font-fira-bold text-2xl text-cafeMarce '>
                                      Germinando:
                                    </span>
                                    <span className='font-fira-regular text-lg text-cafeMarce mt-3'>
                                      Desarrolla un sentido de 
                                      confianza en si mismo y 
                                      en Jesús.
                                    </span>
                                </div>
                              </div>
                          </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div className='w-full h-40 bg-cafeMarce'></div>
                        <div className='w-full lg:p-20 lg:h-96 pb-32'>
                          <div className='lg:inline-flex flex flex-col lg:flex-row w-full'>
                              <div className='w-full lg:w-1/2 relative flex justify-center'>
                                <div className='lg:right-10 circulo-fondo-germinado lg:absolute relative w-72 h-72 lg:h-96 lg:w-96 -top-32 lg:-top-40'>
                                  <img src={compartiendo} alt="germinadoImg" className='w-full h-full rounded-full'/>
                                </div>
                              </div>
                              <div className='w-full lg:w-1/2 relative flex justify-center'>
                                <div className='relative lg:absolute lg:left-10 bg-cuadroGerminado bg-100 h-80 w-80 lg:-top-40 pt-14 px-5 flex flex-col'>
                                    <img src={flor} alt="flor" className='absolute -top-16 -right-20 h-44 w-72' />
                                    <span className='font-fira-bold text-2xl text-cafeMarce '>
                                      Compartiendo:
                                    </span>
                                    <span className='font-fira-regular text-lg text-cafeMarce mt-3'>
                                      Se vuelve un Don para la sociedad viviendo a la luz del Evangelio su 
                                      proyecto personal de vida.
                                    </span>
                                </div>
                              </div>
                          </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div className='w-full h-40 bg-cafeMarce'></div>
                        <div className='w-full lg:p-20 lg:h-96 pb-32'>
                          <div className='lg:inline-flex flex flex-col lg:flex-row w-full'>
                              <div className='w-full lg:w-1/2 relative flex justify-center'>
                                <div className='lg:right-10 circulo-fondo-germinado lg:absolute relative w-72 h-72 lg:h-96 lg:w-96 -top-32 lg:-top-40'>
                                  <img src={perseverando} alt="germinadoImg" className='w-full h-full rounded-full'/>
                                </div>
                              </div>
                              <div className='w-full lg:w-1/2 relative flex justify-center'>
                                <div className='relative lg:absolute lg:left-10 bg-cuadroGerminado bg-100 h-80 w-80 lg:-top-40 pt-14 px-5 flex flex-col'>
                                    <img src={flor} alt="flor" className='absolute -top-16 -right-20 h-44 w-72' />
                                    <span className='font-fira-bold text-2xl text-cafeMarce '>
                                      Perseverando:
                                    </span>
                                    <span className='font-fira-regular text-lg text-cafeMarce mt-3'>
                                      Vive la auténtica amistad cristiana, promueve relaciones duraderas y 
                                      constructivas que lo acompañan en las adversidades.
                                    </span>
                                </div>
                              </div>
                          </div>
                        </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div className='w-full h-40 bg-cafeMarce'></div>
                        <div className='w-full lg:p-20 lg:h-96 pb-32'>
                          <div className='lg:inline-flex flex flex-col lg:flex-row w-full'>
                              <div className='w-full lg:w-1/2 relative flex justify-center'>
                                <div className='lg:right-10 circulo-fondo-germinado lg:absolute relative w-72 h-72 lg:h-96 lg:w-96 -top-32 lg:-top-40'>
                                  <img src={floreciendo} alt="germinadoImg" className='w-full h-full rounded-full'/>
                                </div>
                              </div>
                              <div className='w-full lg:w-1/2 relative flex justify-center'>
                                <div className='relative lg:absolute lg:left-10 bg-cuadroGerminado bg-100 h-80 w-80 lg:-top-40 pt-14 px-5 flex flex-col'>
                                    <img src={flor} alt="flor" className='absolute -top-16 -right-20 h-44 w-72' />
                                    <span className='font-fira-bold text-2xl text-cafeMarce '>
                                      Floreciendo:
                                    </span>
                                    <span className='font-fira-regular text-lg text-cafeMarce mt-3'>
                                      Busca, encuentra y cultiva el sentido
                                      de la propia vida a la luz del Espíritu Santo.
                                    </span>
                                </div>
                              </div>
                          </div>
                        </div>
                    </SplideSlide>
                </Splide>
      </Fragment>
  )
};

export default PatoralGerminado;
