import React from 'react'
import logoMarceG from '/src/assets/img/LogoMarceG.svg'
import logoMarceMd from '/src/assets/img/LogoMarceMd.svg'
import circuloSld0 from '/src/assets/img/Tecnologia de vanguardia.jpg'
import circuloSld1 from '/src/assets/img/Habilidades socioemocionales.jpg'
import circuloSld2 from '/src/assets/img/Proyecto multicultural.jpg'
import circuloSld3 from '/src/assets/img/Arte y creatividad.jpg'
import circuloSld4 from '/src/assets/img/Salud fisica.jpg'
import circuloSld5 from '/src/assets/img/Pastoral educativa.jpg'
import circuloSld6 from '/src/assets/img/Habilidades del siglo XXI.jpg'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { useEffect, useState } from "react"
import '@splidejs/splide/dist/css/splide.min.css'

const Formacion = () => {
    const [imageUrl, setImageUrl] = useState(circuloSld0);
    // const [sliderImg, setSliderImg] = useState(0);
    const changeImage = (imageSelect) =>{
        switch (imageSelect) {
            case 1:
                    setImageUrl(circuloSld0)
                break;
            case 2:
                    setImageUrl(circuloSld1)
                break;

            case 3:
                    setImageUrl(circuloSld2)
                break;

            case 4:
                    setImageUrl(circuloSld3)
                break;

            case 5:
                    setImageUrl(circuloSld4)
                break;

            case 6:
                    setImageUrl(circuloSld5)
            break;

            case 7:
                    setImageUrl(circuloSld6)
            break;
        
            default:
                break;
        }
    }

    return (
        <div className='w-full lg:p-16'>
            <div className='w-full flex justify-center items-center py-10'>
                <img src={logoMarceG} alt="Logo Marcelina Global" className='w-40 h-40'/>
            </div>
            <div className='w-full text-center pb-7 px-10'>
                <h2 className='font-fira-bold text-2xl text-blueFb'>LÍNEAS DE FORMACIÓN MARCELINA</h2>
            </div>
            <div className='w-full relative py-28 hidden lg:flex justify-center'>
                <div className='circulo-fondo-linea flex justify-center items-center'>
                    <img src={imageUrl}  alt="circuloFormacion" className='w-full h-full rounded-full object-cover'/>

                    <div className={(imageUrl == circuloSld0 ? 'bg-redMar':'')+' absolute -top-24  rounded-full bg-blueFb py-2 px-5 text-center w-40 cursor-pointer'} onClick={() => changeImage(1)}>
                        <span className='text-white font-fira-medita text-sm'>Tecnología de <br /> vanguardia</span>
                    </div>
                    <div className={(imageUrl == circuloSld1 ? 'bg-redMar':'')+' absolute z-10 rounded-full px-5 py-2 bg-blueFb text-center -right-40 top-3 w-40 cursor-pointer'} onClick={() => changeImage(2)}>
                        <span className='text-white font-fira-medita text-sm'>Habilidades <br /> socioemocionales</span>
                    </div>
                    <div className={(imageUrl == circuloSld2 ? 'bg-redMar':'')+' absolute z-10 rounded-full px-5 py-2  text-center bottom-12 -right-44 w-40 cursor-pointer bg-blueFb'} onClick={() => changeImage(3)}>
                        <span className='text-white font-fira-medita text-sm'>
                            Proyecto <br />
                            multicultural
                        </span>
                    </div>
                    <div className={(imageUrl == circuloSld3 ? 'bg-redMar':'')+' absolute z-10 rounded-full px-5 py-2 bg-blueFb text-center -right-10 -bottom-20 w-40 cursor-pointer'} onClick={() => changeImage(4)}>
                        <span className='text-white font-fira-medita text-sm'>
                            Arte y <br />
                            creatividad
                        </span>
                    </div>
                    <div className= {(imageUrl == circuloSld4 ? 'bg-redMar':'')+' absolute z-10 rounded-full px-5 py-5 bg-blueFb text-center -left-10 -bottom-20 w-40 cursor-pointer'} onClick={() => changeImage(5)}>
                        <span className='text-white font-fira-medita text-sm'>
                            Salud física 
                        </span>
                    </div>
                    <div className={(imageUrl == circuloSld5 ? 'bg-redMar':'')+' absolute z-10 rounded-full px-5 py-2 bg-blueFb text-center bottom-12 -left-44 w-40 cursor-pointer'} onClick={() => changeImage(6)}>
                        <span className='text-white font-fira-medita text-sm'>
                            Pastoral <br />
                            educativa
                        </span>
                    </div>
                    <div className={(imageUrl == circuloSld6 ? 'bg-redMar':'')+' absolute z-10 rounded-full px-5 py-2 bg-blueFb text-center -left-40 top-3 w-40 cursor-pointer'} onClick={() => changeImage(7)}>
                        <span className='text-white font-fira-medita text-sm'>
                            Habilidades del
                            siglo XXI
                        </span>
                    </div>
                </div>
            </div>

            <Splide 
                    className='w-full relative block lg:hidden py-10'
                    renderControls={ () => (
                        <div className='w-full flex justify-center items-center py-10 text-center'>
                            <span className='font-fira-bold text-redMar text-2xl'>Proyecto <br /> multicultural</span>
                        </div>
                    )}
                    options={{ 
                        type : 'loop',
                        rewind : 'true',
                        rewindSpeed: 500,
                        perPage: 1,
                        padding: '3.5rem',
                        drag:true,
                        autoplay:false,
                        interval:8000,
                        waitForTransition:true,
                        keyboard:false,
                        arrows:false,
                        pagination:true,
                        classes:{
                          pagination: 'flex justify-center w-full isactiveBt',
                          page      : 'w-3 h-3 mx-2 rounded-full bg-blueFb hover:bg-redYt', 
                        } 
                    }}
                >
                    <SplideSlide className=' flex justify-center'>

                        <div className='bg-circuloSld p-2.5 h-52 w-52 bg-100'>
                            <img src={circuloSld2} alt="imagenSlider" className='h-full w-full rounded-full object-cover' />
                        </div>

                    </SplideSlide>

                    <SplideSlide className=' flex justify-center'>

                        <div className='bg-circuloSld p-2.5 h-52 w-52 bg-100'>
                                <img src={circuloSld1} alt="imagenSlider" className='h-full w-full rounded-full object-cover' />
                        </div>

                    </SplideSlide>

                    <SplideSlide className=' flex justify-center'>

                        <div className='bg-circuloSld p-2.5 h-52 w-52 bg-100'>
                            <img src={circuloSld3} alt="imagenSlider" className='h-full w-full rounded-full object-cover' />
                        </div>

                    </SplideSlide>
                    <SplideSlide className=' flex justify-center'>
                            
                        <div className='bg-circuloSld p-2.5 h-52 w-52 bg-100'>
                            <img src={circuloSld4} alt="imagenSlider" className='h-full w-full rounded-full object-cover' />
                        </div>

                    </SplideSlide>

                    <SplideSlide className=' flex justify-center'>
                                
                        <div className='bg-circuloSld p-2.5 h-52 w-52 bg-100'>
                                <img src={circuloSld5} alt="imagenSlider" className='h-full w-full rounded-full object-cover' />
                        </div>
                            
                    </SplideSlide>

                    <SplideSlide className=' flex justify-center'>
                                
                        <div className='bg-circuloSld p-2.5 h-52 w-52 bg-100'>
                                <img src={circuloSld6} alt="imagenSlider" className='h-full w-full rounded-full object-cover' />
                        </div>
                            
                    </SplideSlide>

                </Splide>

        </div>
    )
}

export default Formacion
