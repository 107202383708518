import React from 'react'
import lineasCirculo from '/src/assets/img/imagenCirculo.jpg'
import arrowDown from '/src/assets/img/downArrow.svg'
import marceLogo from '/src/assets/img/LogoMarceMd.svg'

const LineasFormacion = () => {
    return (
        <div className='flex flex-col w-full py-20 bg-center bg-no-repeat bg-cover bg-fondoAzul lg:bg-100 lg:flex-row mt-52'>
            <div className='relative flex justify-center w-full lg:w-1/2'>
                <div className='absolute w-10 h-10 rounded-full bg-redMar left-10 -bottom-36 lg:bottom-0'></div>
                {/* <div className="absolute p-2 circulo-fondo-linea-descarga lg:p-4 h-80 w-80 lg:h-400 lg:w-400 lg:left-52 -top-52"> */}
                <div className="absolute p-2 lg:p-4 h-80 w-80 lg:h-400 lg:w-400 lg:left-52 -top-52">
                    {/* <img src={lineasCirculo} alt="lineasFormacion" className='object-cover w-full h-full rounded-full' /> */}
                    <div className='p-2 circulo-fondo-logo-linea h-28 w-28 lg:p-5 lg:h-36 lg:w-36 bottom-1 -right-3 lg:-right-10'>
                        <img src={marceLogo} alt="marce logo linea md" />
                    </div>
                </div>
                <div className='absolute block rounded-full bg-redMar w-7 h-7 right-5 -top-48 lg:hidden'></div>
            </div>
            
            <div className='relative flex flex-col items-center w-full px-5 py-5 mt-48 text-center lg:w-1/2 lg:text-left lg:items-start lg:mt-0'>
                <h4 className='mt-5 text-3xl text-white font-fira-bold lg:text-4xl'>LÍNEAS DE FORMACIÓN <br/>
                    MARCELINA</h4>
                <a href="Líneas de Formación Marcelina.pdf" target="_blank" rel="noopener noreferrer" className='flex items-center px-6 py-2 rounded-full bg-redMar mt-7'>
                    <span className='text-white font-fira-semiboldNo'>Descargar</span>
                    <img src={arrowDown} alt="arrowDown" className='h-2.5 w-2.5 ml-3' />
                </a>
                <div className='absolute hidden rounded-full bg-redMar w-7 h-7 right-10 -top-24 lg:block'></div>
            </div>
        </div>
    )
}

export default LineasFormacion
