import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import arrowRight from '/src/assets/img/leftBlueArrow.svg'
import arrowLeft from '/src/assets/img/rightBlueArrow.svg'
import circuloSld1 from '/src/assets/img/proyecto1.jpg'
import circuloSld2 from '/src/assets/img/proyecto2.jpg'
import circuloSld3 from '/src/assets/img/proyecto3.jpg'
import circuloSld4 from '/src/assets/img/proyecto4.jpg'

const Slider = () => {
    return (
        <div className='w-full bg-fondoAzul lg:bg-100 bg-cover bg-center bg-no-repeat px-5 lg:px-14 flex relative bg-grisMar h-400'>
            <div className='bg-redMar rounded-full w-5 lg:w-10 h-5 lg:h-10 absolute left-10 top-36 lg:bottom-0'></div>
            <Splide 
                    className='w-full absolute -top-36'
                    renderControls={ () => (
                        <div className="splide__arrows w-10/12 flex items-center justify-between relative lg:absolute inset-0 m-auto mt-10 lg:mt-0">
                            <button className='splide__arrow--next bg-white rounded-full w-12 h-12 flex items-center justify-center'><img src={arrowRight} alt="controlIcons" className='w-5 h-5' /></button>
                            <button className='splide__arrow--prev bg-white rounded-full w-12 h-12 flex items-center justify-center'><img src={arrowLeft} alt="controlIcons" className='w-5 h-5' /></button>
                        </div>
                    )}
                    options={{ 
                        type : 'fade',
                        rewind : 'true',
                        rewindSpeed: 500,
                        perPage: 1,
                        drag:true,
                        autoplay:true,
                        interval:6000,
                        waitForTransition:true,
                        keyboard:true,
                        pagination:false,

                    }}
                >
                    <SplideSlide className="flex justify-center items-center w-full">
                        <div className='w-72 lg:w-96 h-72 lg:h-96 rounded-full bg-100 p-3 bg-bgCirculo'>
                            <img src={circuloSld1} alt="circulo-sld2" className='w-full h-full rounded-full object-cover'/>
                        </div>
                    </SplideSlide>
                    <SplideSlide className="flex justify-center items-center w-full">
                        <div className='w-72 lg:w-96 h-72 lg:h-96 rounded-full bg-100 p-3 bg-bgCirculo'>
                            <img src={circuloSld2} alt="circulo-sld2" className='w-full h-full rounded-full object-cover'/>
                        </div>
                    </SplideSlide>
                    <SplideSlide className="flex justify-center items-center w-full">
                        <div className='w-72 lg:w-96 h-72 lg:h-96 rounded-full bg-100 p-3 bg-bgCirculo'>
                            <img src={circuloSld3} alt="circulo-sld2" className='w-full h-full rounded-full object-cover'/>
                        </div>
                    </SplideSlide>
                    <SplideSlide className="flex justify-center items-center w-full">
                        <div className='w-72 lg:w-96 h-72 lg:h-96 rounded-full bg-100 p-3 bg-bgCirculo'>
                            <img src={circuloSld4} alt="circulo-sld2" className='w-full h-full rounded-full object-cover'/>
                        </div>
                    </SplideSlide>
                </Splide>
                <div className='bg-redMar rounded-full w-3 h-3 absolute right-3 top-24 lg:right-96 lg:-top-11'></div>
        </div>
    )
}

export default Slider
