import ReactDOM  from "react-dom";
import App from "./components/App";   
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
// Ya no necesitas importar react ya que en el babel lo hace de manera automatica 

ReactDOM.render(<App />, document.getElementById("root"));

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
        /* .then(registration => {
            console.log('SW registered: ', registration);
        }) */
        .catch(registrationError => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}

//https://www.youtube.com/watch?v=lNMxjHCLpbk

//https://webpack.js.org/guides/progressive-web-application/#adding-workbox

//https://stackoverflow.com/questions/46795409/how-to-cache-larger-files-for-development