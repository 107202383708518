import React from 'react'
import rightArrow from '/src/assets/img/rightWhiteArrow.svg'

const Instalaciones = () => {

    return (
        <div className='flex flex-col w-full px-5 py-10 lg:py-16 lg:px-10 lg:flex-row'>
            <div className='w-full p-0 lg:w-1/2 lg:p-5 '>
                <div className='relative flex items-center justify-center w-full p-0 lg:h-500 h-60 lg:py-16 lg:px-10'>
                    <iframe id='InstalacionesRecorrido' className='w-full h-full' src="https://colegiomarcelina.edu.mx/recorrido/" title="instalaciones recorrido" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>

            <div className='w-full pt-12 pb-5 lg:w-1/2'>
                <div className='flex flex-col items-start justify-center w-full h-full'>
                    <span className='text-xl font-fira-semibold text-blueMar'>Conoce nuestro</span>
                    <h3 className='text-4xl font-fira-bold text-blueMar lg:text-5xl'>COLEGIO MARCELINA</h3>
                    <span className='mt-5 text-left font-fira-medium lg:text-justify'>
                    Explora el recorrido virtual que hemos preparado para ti y descubre por qué somos un Colegio de excelencia educativa.
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Instalaciones
