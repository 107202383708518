import React from 'react'

const Footer = () => {
    return (
        <footer className='w-full py-11 bg-blueFb flex justify-center'>
            <div className='w-full lg:w-2/3 flex lg:justify-around justify-center items-center lg:flex-row flex-col'>
                <a className='text-white font-fira-medium text-lg' href="http://" target="_blank" rel="noopener noreferrer">AVISO DE PRIVACIDAD</a>
                <span className='text-white font-fira-medium text-lg my-5 lg:my-0'>COLEGIO MARCELINA 2021</span>
                <a href='https://inkwonders.com/' target="_blank" rel="noopener noreferrer" className='text-white font-fira-medium text-lg'>DESARROLLADO POR INKWONDERS</a>
            </div>
        </footer>
    )
}

export default Footer
