import React from 'react';
import rightArrow from '/src/assets/img/rightWhiteArrow.svg'

const IdiomaVideo = () => {
    
    let playerPreescolarInsta
    const onYTplayerPressInsta = () => {
        playerPreescolarInsta = new YT.Player("videoPressInstalaciones", {
            events: {
              onReady: onPlayerReadyPresInsta
            }
          });
    }

    setTimeout(() => {
        onYTplayerPressInsta();
    }, 1000);
    
    

    function onPlayerReadyPresInsta(event) {
        var playerButtonPreesInsta = document.getElementById("playButtonPressInsta");
        playerButtonPreesInsta.addEventListener("click", function () {
        document.getElementById('videoPressInstalaciones').classList.remove('pointer-events-none')
        playerButtonPreesInsta.style.marginTop = "60%"
        playerButtonPreesInsta.style.opacity = "0"
        playerPreescolarInsta.playVideo();
        });
    }
    return(
            <div className='w-full flex lg:flex-row flex-col-reverse py-16 px-5 lg:px-10'>
                {/* <div className='w-full lg:w-1/2 lg:pb-5 pt-12 flex items-center justify-start lg:justify-center'>
                    <div className='flex justify-center items-start text-left flex-col'>
                        <span className='font-fira-semibold text-blueMar text-xl'>Proyecto</span>
                        <h3 className='font-fira-bold text-blueMar text-5xl'>IDIOMAS</h3>
                    </div>
                </div>

                <div className='w-full lg:w-1/2 p-0 lg:p-5 '>
                    <div className='h-500 w-full pb-3 lg:py-16 px-0 lg:px-10 relative flex justify-center items-center'>
                        <iframe id='videoPressInstalaciones' className='w-full h-full relative pointer-events-none' src="https://www.youtube.com/embed/Lt_vaT9O9lo?enablejsapi=1&html5=1&mute=0&controls=1" title="YouTubeInicio" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        <div id='playButtonPressInsta' className='rounded-full border-solid border border-white p-2 w-36 h-36 absolute z-10 animateYtBt cursor-pointer'>
                            <div className=' h-full w-full bg-blueFb flex justify-center items-center rounded-full'>
                                <img src={rightArrow} alt="rightIcon" className='w-8 h-8' />
                            </div>
                        </div>
                    </div>
                </div> */}

            </div> 
    )
      
};

export default IdiomaVideo;
