import React from 'react';
import { Fragment } from 'react/cjs/react.production.min'
import arrowGal from '/src/assets/img/arrowGal.svg'
import prepaCirculo from '/src/assets/img/prepaCirculo.png'
import arrowDown from '/src/assets/img/downArrow.svg'
import imagenUno from '/src/assets/img/prepaCultura.png'
import imagenDos from '/src/assets/img/prepaCompetencias.png'
import imagenTres from '/src/assets/img/prepaCuidado.png'
import imagenCuatro from '/src/assets/img/prepaAprendiendo.png'
import imagenCinco from '/src/assets/img/prepaPastoral.png'
import filtroImagen from '/src/assets/img/primariaFiltro.png'
import somosPrepa from '/src/assets/img/logoSomosPrepa.svg'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import arrowRight from '/src/assets/img/left.svg'
import arrowLeft from '/src/assets/img/right.svg'
import rightArrow from '/src/assets/img/rightWhiteArrow.svg'
import circuloSld2 from '/src/assets/img/Preparatoria/Preparatoria_01.jpg'
import circuloSld1 from '/src/assets/img/Preparatoria/Preparatoria_02.jpg'
import circuloSld3 from '/src/assets/img/Preparatoria/Preparatoria_03.jpg'
import circuloSld4 from '/src/assets/img/Preparatoria/Preparatoria_04.jpg'
import circuloSld5 from '/src/assets/img/Preparatoria/Preparatoria_05.jpg'
import circuloSld6 from '/src/assets/img/Preparatoria/Preparatoria_06.jpg'
import circuloSld7 from '/src/assets/img/Preparatoria/Preparatoria_07.jpg'
import circuloSld8 from '/src/assets/img/Preparatoria/Preparatoria_08.jpg'
import circuloSld9 from '/src/assets/img/Preparatoria/Preparatoria_09.jpg'
import circuloSld10 from '/src/assets/img/Preparatoria/Preparatoria_10.jpg'
import circuloSld11 from '/src/assets/img/Preparatoria/Preparatoria_11.jpg'
import circuloSld12 from '/src/assets/img/Preparatoria/Preparatoria_12.jpg'
import circuloSld13 from '/src/assets/img/Preparatoria/Preparatoria_13.jpg'
import circuloSld14 from '/src/assets/img/Preparatoria/Preparatoria_14.jpg'
import circuloSld15 from '/src/assets/img/Preparatoria/Preparatoria_15.jpg'
import circuloSld16 from '/src/assets/img/Preparatoria/Preparatoria_16.jpg'
import circuloSld17 from '/src/assets/img/Preparatoria/Preparatoria_17.jpg'
import circuloSld18 from '/src/assets/img/Preparatoria/Preparatoria_18.jpg'
import circuloSld19 from '/src/assets/img/Preparatoria/Preparatoria_19.jpg'
import circuloSld20 from '/src/assets/img/Preparatoria/Preparatoria_20.jpg'

const preparatoria = () => {
    let playerPrepa
    const onYTplayerPrepa = () => {
        playerPrepa = new YT.Player("videoPrepa", {
            events: {
              onReady: onPlayerReadyPrepa
            }
          });
    }

    setTimeout(() => {
        onYTplayerPrepa()
    }, 1000);
    
    

    function onPlayerReadyPrepa(event) {
        var playerButtonPrepa = document.getElementById("playbuttonPrepa");
        playerButtonPrepa.addEventListener("click", function () {
        playerButtonPrepa.style.marginTop = "60%"
        playerButtonPrepa.style.opacity = "0"
        playerPrepa.playVideo();
        });
    }
  return (
        <Fragment>
            <div className='relative flex justify-center w-full mt-20 bg-center bg-cover lg:mt-32 h-vh75 bg-prepa'>
                <a href="https://sites.google.com/colegiomarcelina.edu.mx/somos-prepamarcelina/p%C3%A1gina-principal" target="_blank" rel="noopener noreferrer" className='hidden lg:block'>
                    <img src={somosPrepa} alt="somosPrepa" className='absolute w-52 h-60 right-16 top-32' />
                </a>
                <div className='absolute z-10 flex items-center justify-center w-20 h-20 rounded-full -bottom-10 lg:-bottom-14 lg:h-28 lg:w-28 bg-bluergba'>
                    <img src={arrowDown} alt="arrow down icon" className='w-4 h-4' />
                </div>
            </div>
            <div className='relative w-full px-4 py-10 bg-center bg-no-repeat bg-cover bg-blueFb lg:bg-fondoAzul lg:bg-100 lg:px-10 '>
                <div className='absolute w-8 h-8 rounded-full bg-redMar left-10 top-10'></div>
                <div className='absolute hidden w-4 h-4 rounded-full bg-redMar left-2/4 -bottom-10 lg:block'></div>
                <div className='flex justify-end w-full'>
                    <div className='inline-flex items-center justify-end'>
                        <a href='#galeriaPrep' className='text-base text-white lg:text-lg font-fira-semiboldNo'>
                            VER GALERÍA
                        </a>
                        <img src={arrowGal} alt="VerGaleria" className='w-20 h-4 lg:w-28'/>
                    </div>
                </div>
                <div className='flex flex-col w-full lg:flex-row'>
                    <div className='flex justify-center w-full py-8 lg:w-1/2 lg:block lg:py-0'>
                        <div className='relative border-4 border-solid h-72 w-72 lg:absolute lg:p-2 circulo-fondo-linea-kinder 2xl:h-450 2xl:w-450 lg:w-96 lg:h-96 lg:-bottom-28 2xl:-bottom-44 lg:left-48'>
                            <img src={prepaCirculo} alt="circuloNinos" className='w-full h-full'/>
                        </div>
                    </div>
                    <div className='flex flex-col items-start justify-center w-full p-3 lg:w-1/2'>

                        <div>
                            <h2 className='mt-5 text-3xl text-white font-fira-bold'>PREPARATORIA</h2>
                        </div>
                        <div className='flex flex-col w-10/12 mt-4 mb-12'>
                            <span className='text-base text-white font-fira-medita'><b className='text-lg'>#SomosPrepa</b> es desarrollar y potencializar las competencias de nuestros 
                                                                                        estudiantes, a través de una metodología activa en donde nuestros jóvenes son los 
                                                                                        protagonistas de su propio aprendizaje, guiados y acompañados de sus profesores 
                                                                                        para enfrentar los retos que el mundo tiene preparados para ellos.
                            </span>
                            <span className='mt-4 text-base text-white font-fira-medita'>Hacemos uso de TIC, aprendizaje basado en proyectos y en retos así como trabajo 
                                                                                            colaborativo y seguimiento a sus intereses que nos permite la formación de jóvenes 
                                                                                            líderes, capaces de enfrentar al mundo, con inteligencia emocional como base de sus 
                                                                                            acciones.
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='relative flex items-center justify-center w-full mt-0 h-vh75 lg:mt-52'>
                <iframe id='videoPrepa' className='relative w-full h-full' src="https://www.youtube.com/embed/SrEvaHzi-gI?enablejsapi=1&html5=1&mute=0&controls=1" title="YouTubeInicio" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div id='playbuttonPrepa' className='absolute z-10 p-2 border border-white border-solid rounded-full cursor-pointer w-36 h-36 animateYtBt'>
                        <div className='flex items-center justify-center w-full h-full rounded-full bg-blueFb'>
                            <img src={rightArrow} alt="rightIcon" className='w-8 h-8' />
                    </div>
                </div>
            </div> */}

            <div className='flex items-center justify-center w-full h-auto py-10 bg-cover lg:h-vh75 bg-filtroPedagogia lg:p-8'>
                <div className='flex flex-col items-center justify-center w-full bg-cartaMob lg:bg-cartaAcademica 2xl:w-1/2 h-750 2xl:h-550 lg:h-400 lg:w-1/2 bg-100 lg:items-start p-11'>
                    <span className='mt-40 text-2xl text-center font-fira-boldIta lg:text-4xl text-blueFb lg:text-left lg:mt-0'>Bienvenido a <br /> Prepa Marcelina</span>
                    <div className='relative flex items-center justify-center w-full -bottom-16 lg:-bottom-16'>
                    <a href="Bienvenido a Prepa Marcelina.pdf" target="_blank" rel="noopener noreferrer" className='flex items-center px-6 py-2 mt-5 rounded-full lg:mt-0 bg-redMar'>
                        <span className='text-white font-fira-semiboldNo'>Descargar carta</span>
                        <img src={arrowDown} alt="arrowDown" className='h-2.5 w-2.5 ml-3' />
                    </a>
                    </div>
                </div>
            </div>

            <div className='flex justify-center w-full py-20 lg:p-20'>

                <div className='flex flex-col w-10/12 lg:w-8/12'>

                    <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                        <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                            <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                                <img src={imagenUno} alt="img1" className='w-full h-full' />
                            </div>
                        </div>
                        <div className='flex flex-col w-full lg:w-2/3'>
                            <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Cultura global</span>
                            <ul className='pl-4 text-center list-disc font-fira-medita lg:text-justify'>
                                <li>
                                    <span>Vive el inglés en Londres con nosotros.</span> 
                                </li>
                                <li>
                                    <span>Aprende francés.</span>
                                </li>
                                <li>
                                    <span>
                                        Estudia un semestre en Italia.
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                        <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                            <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                                <img src={imagenDos} alt="img1" className='w-full h-full' />
                            </div>
                        </div>
                        <div className='flex flex-col w-full lg:w-2/3'>
                            <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Competencias digitales</span>
                            <span className='text-center font-fira-medita lg:text-justify'>
                                Disfruta de robótica y lenguaje de programación
                            </span>
                        </div>
                    </div>

                    <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                        <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                            <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                                <img src={imagenTres} alt="img1" className='w-full h-full' />
                            </div>
                        </div>
                        <div className='flex flex-col w-full lg:w-2/3'>
                            <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Cuidado de sí</span>
                            <ul className='pl-4 text-center list-disc font-fira-medita lg:text-justify'>
                                <li>
                                    <span>Se inteligente emocionalmente</span> 
                                </li>
                                <li>
                                    <span>Trabaja con un consejero en tu orientación.</span>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                        <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                            <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                                <img src={imagenCuatro} alt="img1" className='w-full h-full' />
                            </div>
                        </div>
                        <div className='flex flex-col w-full lg:w-2/3'>
                            <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Aprendiendo juntos</span>
                            <span className='text-center font-fira-medita lg:text-left'>
                                Sé un chico del siglo XXI que:
                            </span>
                            <ul className='pl-4 text-center list-disc font-fira-medita lg:text-justify'>
                                <li>
                                    <span>Trabaja en equipo.</span> 
                                </li>
                                <li>
                                    <span>Resuelve problemas.</span>
                                </li>
                                <li>
                                    <span>Cuida de si mismo.</span>
                                </li>
                                <li>
                                    <span>Utiliza la tecnología.</span>
                                </li>
                                <li>
                                    <span>Es solidario.</span>
                                </li>
                                <li>
                                    <span>Protege el entorno.</span>
                                </li>
                                <li>
                                    <span>Sueña y se divierte.</span>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                        <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                            <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                                <img src={imagenCinco} alt="img1" className='w-full h-full' />
                            </div>
                        </div>

                        <div className='flex flex-col w-full lg:w-2/3'>
                            <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Pastoral educativa</span>
                            <span className='text-center font-fira-medita lg:text-justify'>
                                Da testimonio del caminar a la luz del evangelio para vivir tu proyecto personal de 
                                vida y comprometerte con LA REALIDAD DEL MUNDO ACTUAL.
                            </span>
                        </div>
                    </div>

                </div>

            </div>

            {/* <div className='relative w-full bg-center bg-cover py-52 bg-prepaFondo'>
                <img src={filtroImagen} alt="filtro" className='absolute top-0 z-10 w-full h-full'/>
                <a href="https://sites.google.com/colegiomarcelina.edu.mx/somos-prepamarcelina/p%C3%A1gina-principal" target="_blank" rel="noopener noreferrer" className='relative z-20 flex items-center justify-center w-56 px-6 py-2 m-auto mt-5 rounded-full lg:mt-0 bg-redMar'>
                        <span className='text-center text-white font-fira-semiboldNo'>Explora nuestro site</span>
                </a>
            </div> */}

            {/* <div id='galeriaPrep' className='flex flex-col items-center justify-center w-full px-0 bg-center bg-no-repeat bg-cover lg:bg-100 bg-identidad lg:px-40 lg:py-20 py-11'>
                <span className='text-3xl font-fira-bold text-redMar'>GALERÍA</span>
                <Splide 
                    className='relative w-full mt-14 lg:h-vh85'
                    renderControls={ () => (
                        <div className="inset-0 flex justify-between w-10/12 m-auto splide__arrows lg:absolute">
                            <button className='splide__arrow--prev'><img src={arrowRight} alt="controlIcons" className='w-16 h-16' /></button>
                            <button className='splide__arrow--next '><img src={arrowLeft} alt="controlIcons" className='w-16 h-16' /></button>
                        </div>
                    )}
                    options={{ 
                        type : 'loop',
                        rewind : 'true',
                        rewindSpeed: 500,
                        padding:'10rem',
                        perPage: 1,
                        drag:true,
                        autoplay:true,
                        interval:6000,
                        waitForTransition:true,
                        keyboard:true,
                        pagination:false,
                        breakpoints: {
                            900: {
                                padding:'1rem',
                            },
                        }
                     }}
                >
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld1} alt="circulo-sld2" className='w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld2} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld3} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld4} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld5} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld6} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld7} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld8} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld9} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld10} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld11} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld12} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld13} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld14} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld15} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld16} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld17} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld18} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld19} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    <SplideSlide className="flex items-center justify-center w-full p-2 lg:p-14 h-vh60 lg:h-vh85">
                            <img src={circuloSld20} alt="circulo-sld2" className='object-cover w-full h-full'/>
                    </SplideSlide>
                    
                </Splide>

            </div> */}

        </Fragment>
  )
};

export default preparatoria;
