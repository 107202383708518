import React from 'react'
import { useEffect, useState } from "react"
import "../assets/css/App.css"
import "../assets/sass/App.scss"
import BienvenidoInicio from './inicio/Bienvenido'
import Chat from './inicio/Chat'
import Contacto from './inicio/Contacto'
import ConvenioInicio from './inicio/Convenio'
import Footer from './inicio/Footer'
import Instalaciones from './inicio/Instalaciones'
import PrincipalInicio from './inicio/Principal'
import loginIcon from "/src/assets/img/login.svg" 
import principalIcon from "/src/assets/img/LogoColegio.svg"
import globalIcon from "/src/assets/img/LogoGlobal.png"
import corazonIcon from "/src/assets/img/LogoCorazon.svg"
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import PortadaVinculacion from './vinculacion/Portada'
import FuturoVinculacion from './vinculacion/Futuro'
import PasosVinculacion from './vinculacion/Pasos'
import FaqVinculacion from './vinculacion/Faq'
import PortadaConocenos from './conocenos/Portada'
import HistoriaConocenos from './conocenos/Historia'
import MundoConocenos from './conocenos/Mundo'
import PrincipiosConocenos from './conocenos/Principios'
import ValoresConocenos from './conocenos/Valores'
import IdentidadConocenos from './conocenos/Identidad'
import PortadaProyecto from './proyectoEducativo/Portada'
import AlumnosProyecto from './proyectoEducativo/Alumnos'
import FormacionProyecto from './proyectoEducativo/Formacion'
import LineasFormacionProyecto from './proyectoEducativo/LineasFormacion'
import SelloProyecto from './proyectoEducativo/Sello'
import SliderProyecto from './proyectoEducativo/Slider'
import PedagogiaProyecto from './proyectoEducativo/Pedagogia'
import ModelosProyecto from './proyectoEducativo/Modelos'
import Preescolar from './academico/preescolar/Preescolar'
import Primaria from './academico/primaria/Primaria'
import Secundaria from './academico/secundaria/Secundaria'
import Preparatoria from './academico/preparatoria/Preparatoria'
import PortadaSlider from './pastoral/PortadaSlider'
import PastoralVideo from './pastoral/PastoralVideo'
import PatoralGerminado from './pastoral/PatoralGerminado'
import PastoralCorazon from './pastoral/PastoralCorazon'
import PortadaInformes from './informes/PortadaInformes'
import InteresadoVideo from './informes/InteresadoVideo'
import Oferta from './informes/Oferta'
import InformesContacto from './informes/InformesContacto'
import PortadaIdioma from './idioma/PortadaIdioma'
import IdiomaVideo from './idioma/IdiomaVideo'
import Slider from './idioma/Slider'
import IdiomaInstalacion from './idioma/IdiomaInstalacion'
import Gracias from './gracias/gracias'
import Login from './login/Login'
import CentroCultural from './centroCultural/CentroCultural'
import Pasarela from './pasarela/Pasarela'


function App() {
    const [heightMenu,    setHeightMenu]    = useState(false)
    const [movAcademica,  setMovAcademica]  = useState(false)
    const [menuStat,      setMenuStat]      = useState({
        'constancia':'false',
        'academica':'false',
        'proyecto':'false'
    });

    const handleClick = () => {
        setHeightMenu(!heightMenu)
        if (!heightMenu) {
            document.querySelector("body").classList.add("overflow-y-hidden")
        } else {
            document.querySelector("body").classList.remove("overflow-y-hidden")
            document.getElementById('handleAcMov').style.height = "0px"
            window.scrollTo(0, 0);
        }
    }

    const handleMovAcademica = () => {
        setMovAcademica(!movAcademica)
        if (!movAcademica) {
            document.getElementById('handleAcMov').style.height = "0px"
        } else {
            document.getElementById('handleAcMov').style.height = "300px"
            window.scrollTo(0, 0);
        }
    }

    const changeColor = (coll , color) => {
        for(var i=0, len=coll.length; i<len; i++)
        {
            coll[i].style["color"] = color;
        }
    }

    const menuOperation = (menu) => {
        setMenuStat(() =>{
            let actualizacion = menuStat
            switch (menu) {
                case "1":
                    console.log(menuStat);
                    if(menuStat.constancia == "false"){
                        actualizacion.constancia = 'true'
                        actualizacion.academica = 'false'
                        actualizacion.proyecto = 'false'
                        document.getElementById('subAcademico').style.height = "0px"
                        document.getElementById('subProyecto').style.height = "0px"
                        document.getElementById('subConocenos').style.height = "150px"
                        // opt-menu
                        // changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                        // document.getElementById('optConocenos').style.color = "#B43331"
                        return actualizacion
                    }else{
                        actualizacion.constancia = 'false'
                        actualizacion.academica = 'false'
                        actualizacion.proyecto = 'false'
                        document.getElementById('subAcademico').style.height = "0px"
                        document.getElementById('subConocenos').style.height = "0px"
                        document.getElementById('subProyecto').style.height = "0px"
                        // changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                        // document.getElementById('optConocenos').style.color = "#B43331"
                        // window.scrollTo(0, 0);
                        return actualizacion
                    }
                        break;
                case "2":
                    console.log(menuStat);
                    if(menuStat.academica == "false"){
                        actualizacion.academica = 'true'
                        actualizacion.constancia = 'false'
                        actualizacion.proyecto = 'false'
                        // changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                        // document.getElementById('subacademico').style.color = "#B43331"
                        document.getElementById('subAcademico').style.height = "400px"
                        document.getElementById('subConocenos').style.height = "0px"
                        document.getElementById('subProyecto').style.height = "0px"
                        return actualizacion
                    }else{
                        actualizacion.academica = 'false'
                        actualizacion.constancia = 'false'
                        actualizacion.proyecto = 'false'
                        // changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                        // document.getElementById('subacademico').style.color = "#B43331"
                        document.getElementById('subAcademico').style.height = "0px"
                        document.getElementById('subConocenos').style.height = "0px"
                        document.getElementById('subProyecto').style.height = "0px"
                        // window.scrollTo(0, 0);
                        return actualizacion
                    }
                    break;
                
                    case "3":
                        console.log(menuStat);
                        if(menuStat.proyecto == "false"){
                            actualizacion.proyecto = 'true'
                            actualizacion.constancia = 'false'
                            actualizacion.academica = 'false'
                            document.getElementById('subAcademico').style.height = "0px"
                            document.getElementById('subConocenos').style.height = "0px"
                            document.getElementById('subProyecto').style.height = "300px"
                            // opt-menu
                            // changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                            // document.getElementById('optEducativo').style.color = "#B43331"
                            return actualizacion
                        }else{
                            actualizacion.proyecto = 'false'
                            actualizacion.constancia = 'false'
                            actualizacion.academica = 'false'
                            document.getElementById('subAcademico').style.height = "0px"
                            document.getElementById('subConocenos').style.height = "0px"
                            document.getElementById('subProyecto').style.height = "0px"
                            // changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                            // document.getElementById('optEducativo').style.color = "#B43331"
                            // window.scrollTo(0, 0);
                            return actualizacion
                        }
                        break;

                    case "4":
                        actualizacion.proyecto = 'false'
                        actualizacion.constancia = 'false'
                        actualizacion.academica = 'false'
                        changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                        document.getElementById('optVinculacion').style.color = "#B43331"
                        window.scrollTo(0, 0);
                        document.getElementById('subAcademico').style.height = "0px"
                        document.getElementById('subProyecto').style.height = "0px"
                        document.getElementById('subConocenos').style.height = "0px"
                        return actualizacion
                        break;

                    case "5":
                        actualizacion.proyecto = 'false'
                        actualizacion.constancia = 'false'
                        actualizacion.academica = 'false'
                        changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                        document.getElementById('optContacto').style.color = "#B43331"
                        window.scrollTo(0, 0);
                        document.getElementById('subAcademico').style.height = "0px"
                        document.getElementById('subProyecto').style.height = "0px"
                        document.getElementById('subConocenos').style.height = "0px"
                        return actualizacion
                        break;

                    case "6":
                        actualizacion.proyecto = 'false'
                        actualizacion.constancia = 'false'
                        actualizacion.academica = 'false'
                        changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                        document.getElementById('subacademico').style.color = "#B43331"
                        document.getElementById('subAcademico').style.height = "0px"
                        window.scrollTo(0, 0);
                        document.getElementById('subProyecto').style.height = "0px"
                        document.getElementById('subConocenos').style.height = "0px"
                        return actualizacion
                            break;

                    case "7":
                        console.log(menuStat);
                        if(menuStat.constancia == "false"){
                            actualizacion = menuStat
                            actualizacion.constancia = 'true'
                            document.getElementById('subConocenos').style.height = "150px"
                            // opt-menu
                            changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                            document.getElementById('optConocenos').style.color = "#B43331"
                                return actualizacion
                        }else{
                            actualizacion = menuStat
                            actualizacion.constancia = 'false'
                            document.getElementById('subAcademico').style.height = "0px"
                            document.getElementById('subConocenos').style.height = "0px"
                            document.getElementById('subProyecto').style.height = "0px"
                            changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                            document.getElementById('optConocenos').style.color = "#B43331"
                            window.scrollTo(0, 1700);
                            changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                            document.getElementById('optConocenos').style.color = "#B43331"
                            return actualizacion
                        }
                            break;

                    case "8":
                            console.log('8');
                            document.getElementById('subAcademico').style.height = "0px"
                            document.getElementById('subConocenos').style.height = "0px"
                            document.getElementById('subProyecto').style.height = "0px"
                            changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                            document.getElementById('optEducativo').style.color = "#B43331"
                            window.scrollTo(0, 0);
                            return actualizacion
                            break;

                    case "9":
                        console.log('9');
                            document.getElementById('subAcademico').style.height = "0px"
                            document.getElementById('subConocenos').style.height = "0px"
                            document.getElementById('subProyecto').style.height = "0px"
                            changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                            document.getElementById('optEducativo').style.color = "#B43331"
                            window.scrollTo(0, 1600);
                            return actualizacion
                            break;

                    case "10":
                        console.log('10');
                        document.getElementById('subAcademico').style.height = "0px"
                        document.getElementById('subConocenos').style.height = "0px"
                        document.getElementById('subProyecto').style.height = "0px"
                        changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                        document.getElementById('optEducativo').style.color = "#B43331"
                        window.scrollTo(0, 3100);
                        return actualizacion
                        break;

                    case "11":
                        console.log('11');
                        document.getElementById('subAcademico').style.height = "0px"
                        document.getElementById('subConocenos').style.height = "0px"
                        document.getElementById('subProyecto').style.height = "0px"
                        changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                        document.getElementById('optEducativo').style.color = "#B43331"
                        window.scrollTo(0, 5200);
                        return actualizacion
                        break;
                        
                    case "12":
                        document.getElementById('subAcademico').style.height = "0px"
                        document.getElementById('subProyecto').style.height = "0px"
                        document.getElementById('subConocenos').style.height = "0px"
                        actualizacion.proyecto = 'false'
                        actualizacion.constancia = 'false'
                        actualizacion.academica = 'false'
                        return actualizacion
                        break;

                    case "13":
                        document.getElementById('subAcademico').style.height = "0px"
                        document.getElementById('subProyecto').style.height = "0px"
                        document.getElementById('subConocenos').style.height = "0px"
                        changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                        document.getElementById('optConocenos').style.color = "#B43331"
                        window.scrollTo(0, 0);
                        actualizacion.proyecto = 'false'
                        actualizacion.constancia = 'false'
                        actualizacion.academica = 'false'
                        return actualizacion
                        break;
                    
                    case "14":
                            document.getElementById('subAcademico').style.height = "0px"
                            document.getElementById('subProyecto').style.height = "0px"
                            document.getElementById('subConocenos').style.height = "0px"
                            changeColor(document.getElementsByClassName('opt-menu'),"#2B5CA6")
                            document.getElementById('optCentro').style.color = "#B43331"
                            window.scrollTo(0, 0);
                            actualizacion.proyecto = 'false'
                            actualizacion.constancia = 'false'
                            actualizacion.academica = 'false'
                            return actualizacion
                            break;


        

                    default:
                        break;

                
            }      
        })     
    }

    return (
        <div className="overflow-x-hidden app">
            {/* <Head/> */}
            <Router>
                <div className='fixed top-0 z-50 flex items-center justify-center w-full h-20 bg-white shadow-md lg:hidden'>
                    <Link to="/" className='flex justify-center w-5/6 max-h-full'>
                        <img src={principalIcon} alt="colegio marcelina logo" className='w-5/6 max-h-full' />
                    </Link>
                    <div className='flex flex-col items-center justify-around w-1/6 cursor-pointer h-1/4' onClick={handleClick}>
                        <span className={(heightMenu ? 'rotate-45 translate-y-1.5 translate-x-1/4  ' : 'rotate-0 ') + 'bg-red-500 h-1 w-2/4 transform transition-all'}></span>
                        <span className={(heightMenu ? 'hidden ' : 'flex ') + 'bg-red-500 h-1 w-2/4'}></span>
                        <span className={(heightMenu ? 'rotate-45inv -translate-y-full translate-x-1/4 ' : 'rotate-0 ') + 'bg-red-500 h-1 w-2/4 transform transition-all'}></span>
                    </div>

                    <div className={(heightMenu ? 'h-custom-movil bg-white ' : 'h-0 ') + 'fixed w-full z-50 transition-all top-20 left-0 right-0 flex flex-col justify-center bg-white overflow-hidden px-5'}>

                        <div className='flex items-center justify-center w-full'>
                            <img src={globalIcon} alt="Global compact" className='w-28' />
                        </div>

                        <nav className="flex flex-col items-center justify-center">
                            <Link onClick={handleClick} to="/conocenos" className="flex items-center justify-center w-full h-16 py-2 cursor-pointer font-fira-medita text-blueMar opt-menu">CONÓCENOS</Link>
                            <Link onClick={handleClick} to="/proyectoEducativo" className="flex items-center justify-center w-full h-16 py-2 cursor-pointer font-fira-medita text-blueMar opt-menu">PROYECTO EDUCATIVO</Link>
                            <div onClick={handleMovAcademica} className="flex items-center justify-center w-full h-16 py-2 cursor-pointer font-fira-medita text-blueMar opt-menu">OFERTA ACADÉMICA</div>
                            <div id='handleAcMov' className='flex flex-wrap justify-center w-full overflow-hidden transition-all duration-700'>
                                <Link to="/preescolar" onClick={handleClick} className='w-full py-2 text-center text-redYt font-fira-medita'>Preescolar</Link>
                                <Link to="/primaria" onClick={handleClick} className='w-full py-2 text-center text-redYt font-fira-medita'>Primaria</Link>
                                <Link to="/secundaria" onClick={handleClick} className='w-full py-2 text-center text-redYt font-fira-medita'>Secundaria</Link>
                                <Link to="/preparatoria" onClick={handleClick} className='w-full py-2 text-center text-redYt font-fira-medita'>Preparatoria</Link>
                                <Link to="/idioma" onClick={handleClick} className='w-full py-2 text-center text-redYt font-fira-medita'>Idiomas</Link>
                            </div>
                            <Link onClick={handleClick} to="/vinculacion" className="flex items-center justify-center w-full h-16 py-2 cursor-pointer font-fira-medita text-blueMar opt-menu">Admisiones</Link>
                            <Link onClick={handleClick} to="/pastoral" className='inline-flex p-2 mx-2 rounded-full cursor-pointer bg-redMar'>
                                <img src={corazonIcon} alt="corazon logo" className='w-5' />
                                <span className='ml-3 text-white font-fira-medita'>PASTORAL</span>
                            </Link>
                            <Link onClick={handleClick} id='optCentroMov' to='/centro' className='flex items-center justify-center w-full py-2 cursor-pointer h-14 font-fira-medita text-blueMar opt-menu'>Centro Cultural</Link>
                            <a onClick={handleClick} id='optContactoMov' href='#contacto' className='flex items-center justify-center w-full py-2 cursor-pointer h-14 font-fira-medita text-blueMar opt-menu'>CONTACTO</a>

                            <div className='w-full'>
                                <div className='flex justify-around w-full'>
                                    <Link to='/login' className='inline-flex items-center justify-between py-1 pl-1 pr-3 rounded-full cursor-pointer lg:mr-3 bg-blueMar'>
                                        <img src={loginIcon} alt="loginlogo" className='w-8 h-8' />
                                        <span className='ml-3 text-sm text-white font-mont-bold'>LOG IN</span>
                                    </Link>
                                    {/* <Link to='/informes' className='inline-flex items-center justify-center px-3 py-1 rounded-full cursor-pointer bg-redMar'>
                                        <span className='text-sm text-white font-mont-bold'>INFORMES</span>
                                    </Link> */}
                                </div>
                            </div>

                        </nav>

                    </div>

                </div>


                <div className='fixed top-0 z-50 hidden w-full px-4 pt-4 pb-3 bg-white shadow-md lg:flex'>
                    <Link className='w-1/6' to="/gracias">
                        <img src={globalIcon} alt="Global compact" className='w-28' />
                    </Link>
                    <div className='flex flex-col items-center justify-center w-4/6'>
                        <Link to="/" className='flex justify-center w-full mb-3'>
                            <img src={principalIcon} alt="colegio marcelina logo" className='w-96' />
                        </Link>
                        <div className='flex items-center justify-around w-full'>
                            <div className='relative'>
                                <div id='optConocenos'  onMouseEnter={() => menuOperation('1')} className='transition-all cursor-pointer font-fira-medita text-blueMar opt-menu'>CONÓCENOS</div>
                                    <div id='subConocenos' onMouseLeave={() => menuOperation('12')} className='absolute left-0 z-50 flex flex-col h-0 pt-5 overflow-hidden transition-all top-4'>
                                        <Link onClick={() => menuOperation('13')} to="/conocenos" className='px-4 py-2 mt-1 mb-2 bg-white shadow-md cursor-pointer w-44 font-fira-medita text-blueMar focus:bg-blueMar focus:text-white hover:bg-blueMar hover:text-white'>
                                            <span>Historia</span>
                                        </Link>
                                        <Link onClick={() => menuOperation('7')} to="/conocenos" className='px-4 py-2 mt-1 mb-2 bg-white shadow-md cursor-pointer w-44 font-fira-medita text-blueMar focus:bg-blueMar focus:text-white hover:bg-blueMar hover:text-white'>
                                            <a href='#principios'>Principios</a>
                                        </Link>
                                    </div>
                            </div>
                            <div className='relative'>
                                <div onMouseEnter={() => menuOperation('3')} id='optEducativo' className='mx-2 cursor-pointer font-fira-medita text-blueMar opt-menu'>PROYECTO EDUCATIVO
                                    <div id='subProyecto' onMouseLeave={() => menuOperation('12')} className='absolute left-0 z-50 flex flex-col h-0 pt-6 overflow-hidden transition-all top-4'>
                                        <Link onClick={() => menuOperation('8')} to="/proyectoEducativo" className='px-4 py-2 mt-1 mb-2 bg-white shadow-md cursor-pointer w-44 font-fira-medita text-blueMar focus:bg-blueMar focus:text-white hover:bg-blueMar hover:text-white'>
                                            <span>Modelo Educativo</span>
                                        </Link>
                                        <Link onClick={() => menuOperation('9')} to="/proyectoEducativo" className='px-4 py-2 mt-1 mb-2 bg-white shadow-md cursor-pointer w-44 font-fira-medita text-blueMar focus:bg-blueMar focus:text-white hover:bg-blueMar hover:text-white'>
                                            <span>Líneas de formación</span>
                                        </Link>
                                        <Link onClick={() => menuOperation('10')} to="/proyectoEducativo" className='px-4 py-2 mt-1 mb-2 bg-white shadow-md cursor-pointer w-44 font-fira-medita text-blueMar focus:bg-blueMar focus:text-white hover:bg-blueMar hover:text-white'>
                                            <span>Pedagogía</span>
                                        </Link>
                                        <Link onClick={() => menuOperation('11')} to="/proyectoEducativo" className='px-4 py-2 mt-1 mb-2 bg-white shadow-md cursor-pointer w-44 font-fira-medita text-blueMar focus:bg-blueMar focus:text-white hover:bg-blueMar hover:text-white'>
                                            <span>Nuestros Alumnos</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className='relative'>
                            <div  onMouseEnter={() => menuOperation('2')} id='subacademico' to="/preescolar" className='mx-2 cursor-pointer font-fira-medita text-blueMar opt-menu'>OFERTA ACADÉMICA</div>
                                <div id='subAcademico' onMouseLeave={() => menuOperation('12')}  className='absolute left-0 z-50 flex flex-col h-0 pt-6 overflow-hidden transition-all top-4'>
                                    <Link onClick={() => menuOperation('6')} to="/preescolar" className='px-4 py-2 mt-1 mb-2 bg-white shadow-md cursor-pointer w-44 font-fira-medita text-blueMar focus:bg-blueMar focus:text-white hover:bg-blueMar hover:text-white'>
                                        <span>Preescolar</span>
                                    </Link>
                                    <Link onClick={() => menuOperation('6')} to="/primaria" className='px-4 py-2 mt-1 mb-2 bg-white shadow-md cursor-pointer w-44 font-fira-medita text-blueMar focus:bg-blueMar focus:text-white hover:bg-blueMar hover:text-white'>
                                        <span>Primaria</span>
                                    </Link>
                                    <Link onClick={() => menuOperation('6')} to="/secundaria" className='px-4 py-2 mt-1 mb-2 bg-white shadow-md cursor-pointer w-44 font-fira-medita text-blueMar focus:bg-blueMar focus:text-white hover:bg-blueMar hover:text-white'>
                                        <span>Secundaria</span>
                                    </Link>
                                    <Link onClick={() => menuOperation('6')} to="/preparatoria" className='px-4 py-2 mt-1 mb-2 bg-white shadow-md cursor-pointer w-44 font-fira-medita text-blueMar focus:bg-blueMar focus:text-white hover:bg-blueMar hover:text-white'>
                                        <span>Preparatoria</span>
                                    </Link>
                                    <Link onClick={() => menuOperation('6')} to="/pastoral" className='px-4 py-2 mt-1 mb-2 bg-white shadow-md cursor-pointer w-44 font-fira-medita text-blueMar focus:bg-blueMar focus:text-white hover:bg-blueMar hover:text-white'>
                                        <span>Pastoral</span>
                                    </Link>
                                    <Link onClick={() => menuOperation('6')} to="/idioma" className='px-4 py-2 mt-1 mb-2 bg-white shadow-md cursor-pointer w-44 font-fira-medita text-blueMar focus:bg-blueMar focus:text-white hover:bg-blueMar hover:text-white'>
                                        <span>Idiomas</span>
                                    </Link>
                                </div>
                            </div>

                            <Link id='optVinculacion' to="/vinculacion" className='mx-2 cursor-pointer font-fira-medita text-blueMar opt-menu' onClick={() => menuOperation('4')}>ADMISIONES</Link>

                            <Link id='optCentro' to="/centro" className='mx-2 cursor-pointer font-fira-medita text-blueMar opt-menu' onClick={() => menuOperation('14')}>CENTRO CULTURAL</Link>
                            {/* <Link to="/pastoral" className='inline-flex p-2 mx-2 rounded-full cursor-pointer bg-redMar' onClick={() => menuOperation('6')} >
                                <img src={corazonIcon} alt="corazon logo" className='w-5' />
                                <span className='ml-3 text-white font-fira-medita'>PASTORAL</span>
                            </Link> */}
                            <a id='optContacto' href='#contacto' className='cursor-pointer font-fira-medita text-blueMar opt-menu' onClick={() => menuOperation('5')}>CONTACTO</a>
                        </div>
                        
                    </div>
                    <div className='w-1/6'>
                        <div className='flex justify-around w-full'>
                            <Link to='/login' className='inline-flex items-center justify-between py-1 pl-1 pr-3 mr-3 rounded-full cursor-pointer bg-blueMar'>
                                <img src={loginIcon} alt="loginlogo" className='w-8 h-8' />
                                <span className='ml-3 text-sm text-white font-mont-bold'>LOG IN</span>
                            </Link>
                            {/* <Link onClick={()=>{window.scrollTo(0, 0);}} to='/informes' className='inline-flex items-center justify-center px-3 py-1 rounded-full cursor-pointer bg-redMar'>
                                <span className='text-sm text-white font-mont-bold'>INFORMES</span>
                            </Link> */}
                        </div>
                    </div>
                </div>
                <Switch>
                    <Route exact path="/">
                        <PrincipalInicio/>
                        <BienvenidoInicio/>
                        <ConvenioInicio/>
                        <Instalaciones/>
                        <Contacto />
                        <Chat />
                        <Footer/> 
                    </Route>
                    <Route exact path="/conocenos">
                        <PortadaConocenos />
                        <HistoriaConocenos/>
                        <MundoConocenos />
                        <PrincipiosConocenos />
                        <ValoresConocenos />
                        <IdentidadConocenos />
                        <Instalaciones/>
                        <Contacto />
                        <Footer/> 
                    </Route>
                    <Route exact path="/proyectoEducativo">
                        <PortadaProyecto/>
                        <ModelosProyecto/>
                        <FormacionProyecto/>
                        <LineasFormacionProyecto/>
                        <PedagogiaProyecto/>
                        <SelloProyecto/>
                        <SliderProyecto/>
                        <AlumnosProyecto/>
                        <Instalaciones/>
                        <Contacto />
                        <Chat />
                        <Footer/> 
                    </Route>
                    <Route exact path="/preescolar">
                        <Preescolar />
                        <Instalaciones/>
                        <Contacto />
                        <Chat />
                        <Footer/>
                    </Route>
                    <Route exact path="/primaria">
                        <Primaria />
                        <Instalaciones/>
                        <Contacto />
                        <Chat />
                        <Footer/>
                    </Route>
                    <Route exact path="/secundaria">
                        <Secundaria />
                        <Instalaciones/>
                        <Contacto />
                        <Chat />
                        <Footer/>
                    </Route>
                    <Route exact path="/preparatoria">
                        <Preparatoria />
                        <Instalaciones/>
                        <Contacto />
                        <Chat />
                        <Footer/>
                    </Route>
                    <Route exact path="/pastoral">
                        {/* <PortadaSlider /> */}
                        <div className='w-full pt-20 lg:pt-32'></div>
                        <PastoralCorazon />
                        <PatoralGerminado />
                        {/* <PastoralVideo /> */}
                        <Instalaciones/>
                        <Contacto />
                        <Chat />
                        <Footer/>
                    </Route>
                    <Route exact path="/vinculacion">
                        <PortadaVinculacion />
                        <FuturoVinculacion />
                        <PasosVinculacion />
                        <FaqVinculacion />
                        <Instalaciones/>
                        <Contacto />
                        <Chat />
                        <Footer/> 
                    </Route>
                    <Route exact path="/informes">
                        <PortadaInformes />
                        <InteresadoVideo/>
                        <Oferta/>
                        <InformesContacto />
                        <Instalaciones/>
                        <Chat />
                        <Footer/> 
                    </Route>
                    <Route exact path="/idioma">
                        <PortadaIdioma/>
                        <IdiomaVideo/>
                        <Slider/>
                        <IdiomaInstalacion/>
                        <Chat />
                        <Contacto />
                        <Footer/> 
                    </Route>
                    <Route exact path="/gracias">
                        <Gracias />
                        <Footer/> 
                    </Route>
                    <Route exact path="/login">
                        <Login />
                        <Footer/> 
                    </Route>

                    <Route exact path="/pasarela">
                        <Pasarela />
                        <Footer/> 
                    </Route>

                    <Route exact path="/centro">
                        <CentroCultural />
                        <Contacto />
                        <Footer/>
                    </Route>

                </Switch>                
            </Router>
        </div>
    );
}

export default App
    