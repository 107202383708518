import React, { useEffect, useState } from 'react'
import { Fragment } from 'react/cjs/react.production.min'
// import { useState } from "react"
// import popUp from '/src/assets/img/fiesta_siglo.png'
// import whats from '/src/assets/img/waLogo.svg'
// import close from '/src/assets/img/close.svg'

// import CarouselComponent from './Carousel';

// import ReactDOM from 'react-dom';
import CarouselGallery from './Carousel';
// import ejemplo1 from '../../assets/img/banners_home/1.jpg';
// import play_icon from "../../assets/img/play.svg";

// import video1 from "../../assets/video/video_rampa.mov";
// import thumbnail_1 from "../../assets/video/thumbnail_1.jpg";

// import image1 from '../../assets/img/banners_home/IMG_1.jpg';
// import image2 from '../../assets/img/banners_home/IMG_2.jpg';
// import image3 from '../../assets/img/banners_home/IMG_3.jpg';
// import image4 from '../../assets/img/banners_home/IMG_4.jpg';

// import image4 from '../../assets/img/banners_home/4.jpg';
// import ejemplo5 from '../../assets/img/banner5.jpg';
// import ejemplo6 from '../../assets/img/banner6.png';

const Principal = () => {
    // const [Modal, setModal] = useState(true);
    // const images = [
    //     // video,
    //     ejemplo2,
    //     ejemplo3,
    //     ejemplo4,
    //     // ejemplo5,
    //     // ejemplo6,
    
    // ];
    // const imageTexts = [
    //     // { title: 'Consolidando Tradiciones y Fortaleciendo Lazos en la Familia Marcelina'},
    //     { title: 'Consolidando Tradiciones y Fortaleciendo Lazos en la Familia Marcelina' },
    //     { title: 'Consolidando Tradiciones y Fortaleciendo Lazos en la Familia Marcelina' },
    //     { title: 'Consolidando Tradiciones y Fortaleciendo Lazos en la Familia Marcelina' },
    //     // { title: 'El día 03 de octubre tuvimos firma de convenio de colaboración con la Universidad Intercontinental #UIC>El Colegio Marcelina fortalece sus nexos con educación superior para ofrecer a nuestros egresados más alternativas de calidad y consolidarse como un centro de formación continua para docentes en el ejercicio de su profesión.', subtitle: 'Nuestra Misión: Crecimiento Humano y Espiritual' },
    // ];
    const [ text, setText ] = useState("")
    const [ images, setImages ] = useState([])
    const [ randomized, setRandomized ] = useState("")

    async function getPictures() {
        await fetch("/get_pictures.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        }).then(response => {
            return response.json()
        }).then(( data ) => {
            if (data.success) {
                setImages(data.files.map((file, index) => ({
                    id: 1,
                    type: "image",
                    file: `/carousel_folder/${file}`,
                    text: "Pre-Spelling bee de nuestros alumnos de la sección preescolar"
                })))
            } else {
                alert(data.msg)
            }
        }).catch(err => alert(err));
    }

    async function getText() {
        await fetch("/get_text.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        }).then(response => {
            return response.json()
        }).then(( data ) => {
            if (data.success) {
                setText(data.text)
            } else {
                alert(data.msg)
            }
        }).catch(err => alert(err));
    }

    async function getToken() {
        await fetch("/get_token.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
        }).then(response => {
            return response.json()
        }).then(( data ) => {
            if (data.success) {
                setRandomized(data.text)
            } else {
                alert(data.msg)
            }
        }).catch(err => alert(err));
    }

    useEffect(() => {
        getToken()
        getPictures()
        getText()
    }, [])

    // const images = [
    //     {
    //         id: 1,
    //         type: "image",
    //         file: image1,
    //         text: "Pre-Spelling bee de nuestros alumnos de la sección preescolar"
    //     },
    //     {
    //         id: 2,
    //         type: "image",
    //         file: image2,
    //         text: "Pre-Spelling bee de nuestros alumnos de la sección preescolar"
    //     },
    //     {
    //         id: 3,
    //         type: "image",
    //         file: image3,
    //         text: "Pre-Spelling bee de nuestros alumnos de la sección preescolar"
    //     },
    //     {
    //         id: 4,
    //         type: "image",
    //         file: image4,
    //         text: "Pre-Spelling bee de nuestros alumnos de la sección preescolar"
    //     },
    //     // {
    //     //     id: 3,
    //     //     type: "image",
    //     //     file: image4,
    //     //     text: "Consolidando Tradiciones y Fortaleciendo Lazos en la Familia Marcelina"
    //     // },
    //     // {
    //     //     id: 4,
    //     //     type: "video",
    //     //     file: video1,
    //     //     thumbnail: thumbnail_1,
    //     //     text: "¡Con nuestra nueva rampa hemos mejorado los espacios para brindar una mayor seguridad!"
    //     // },
    // ]

    return (
        <Fragment>
            {/* <div onClick={()=>setModal(!Modal)} className={(Modal == true ? 'flex' : 'hidden')+' fixed justify-center flex-col items-center z-50 w-full h-full bg-blackMarce'}> */}
{/* 
                <div className='relative object-contain w-11/12 rounded-lg lg:w-4/12 lg:object-none'>

                    <img src={popUp} alt="popUp" className='w-full h-full rounded-xl' />

                    <div className='absolute right-0 z-50 cursor-pointer -top-11 lg:-right-8 lg:block'>
                
                        <img src={close} alt="close" className='w-10 h-10'/>
                
                    </div>
                
                </div>

                <a className='px-8 py-4 mt-10 text-xl text-white transition duration-300 ease-out bg-blue-400 border-2 border-blue-400 hover:bg-white hover:text-blue-400 rounded-2xl' href="https://m.facebook.com/story.php?story_fbid=546211367507446&id=100063557623427" target="_blank" rel="noopener noreferrer" >

                    Más información
                    
                </a> */}

                {/* <a href="https://wa.link/th30bn" target="_blank" rel="noopener noreferrer" className='flex items-center justify-center px-5 py-2 mt-10 text-white border-2 border-white bg-greenMarce rounded-3xl'>
                    <img src={whats} alt="whats" className='w-6 h-6 mr-3' />
                    <span className='font-fira-medium'>
                        Informes
                    </span> 
                </a> */}
            {/* </div> */}

             {/* //se quita para pooner el carrousel  */}
            {/* <div className='relative w-full mt-20 bg-cover lg:mt-32 h-vh75 bg-principal'>  */}
            {/* //se quita para pooner el carrousel  */}

            {/* <div className='relative w-full mt-20 bg-cover lg:mt-32 h-vh75 '> original */}
            <div className='relative w-full mt-20 bg-cover lg:mt-32 h-vh100 '>

             {/*  el carrousel inicia */}
            {/* <div className='relative w-full bg-cover xl:mt-72 lg:mt-72 mt-52 h-vh50 lg:h-vh75 xl:h-vh75 2xl:h-vh75'>
            <div className="App">
                <CarouselComponent />
            </div> */}
             {/*  el carrousel termina */}

                <div className="flex justify-center w-full App">
                    {images.length > 0 && (
                        <CarouselGallery
                            text={text}
                            images={images}
                            randomized={randomized}
                        />
                    )}
                </div>

             {/* <div className='absolute bottom-0 flex items-center justify-center w-full h-auto p-4 lg:h-16 bg-grayrgba lg:p-0'>
                        <span className='text-center text-white font-fira-medita lg:text-left'>En su trayectoria por nuestro colegio, acompañamos a nuestros alumnos estimulando su talento y cuidando su maduración sana y feliz.</span>
                    </div>*/}
            </div> 

                {/* con carrusel inicia */}
                {/* <div class="pt-0 lg:pt-80 xl:pt-80 2xl:pt-80 3xl:pt-44"> */}
                {/* con carrusel termina */}
                 {/* sin carrusel con banner*/}
                <div class=" ">
                {/* sin carrusel con banner */}
                {/* <div className='bottom-0 flex items-center justify-center w-full h-auto p-4 lg:h-16 bg-grayrgba lg:p-0'>
                        <span className='text-center text-white font-fira-medita lg:text-left'>En su trayectoria por nuestro colegio, acompañamos a nuestros alumnos estimulando su talento y cuidando su maduración sana y feliz.</span>
                    </div> */}
                    {/* <div className="absolute flex items-center justify-center w-full h-16 bg-redMar">
                        <span className='text-lg text-white font-fira-extraBold'>FORMANDO PERSONAS PARA TODA LA VIDA</span>
                    </div> */}
            </div>
        </Fragment>
    )
}

export default Principal