import React from 'react'
import selloMarce from '/src/assets/img/logoMarceSello.svg'
import selloPastoral from '/src/assets/img/logoPastoralSello.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';

const Sello = () => {
    AOS.init();
    return (
        <div className='w-full flex justify-center items-center flex-col pt-14 lg:pt-48 pb-64 bg-gray-100 lg:bg-filtroPedagogia bg-cover'>
            <div className='w-full text-center'>
                <p className='font-fira-bold text-redMar text-3xl mb-7 lg:mb-16'>
                    SELLO MARCELINA
                </p>
            </div>
            <div className='w-11/12 lg:w-8/12 flex justify-center items-center'>
                <div className='relative flex flex-col justify-center items-center'>
                    <div className='flex-col lg:flex-row flex w-full mb-3'>

                    <div className='relative w-7/12 py-1 px-2 bg-gray-400 rounded-full justify-between items-center m-auto mb-7 lg:hidden inline-flex'>
                        <img src={selloMarce} alt="sello marce" className='w-20 h-20' />
                        <img src={selloPastoral} alt="sello pastoral" className='w-20 h-20' />
                    </div>
                        <div className='bg-white p-6 w-full lg:w-1/2 mr-3 rounded-tr-4xl rounded-bl-4xl' data-aos="flip-left" data-aos-duration="1600">
                            <span className='font-fira-medita text-blueFb text-lg'>
                                Dimensión espiritual
                            </span>
                            <br />
                            <span className='font-fira-medita text-base'>
                                Buscamos un crecimiento interno mediante una fe viva 
                                y por convencimiento personal de los valores cristianos 
                                para dar fuerza y sentido a su vida.
                            </span>
                        </div>
                        <div className='bg-white p-6 w-full lg:w-1/2 ml-3 rounded-tl-4xl rounded-br-4xl mt-6 lg:mt-0' data-aos="flip-right" data-aos-duration="1600">
                            <span className='font-fira-medita text-blueFb text-lg'>
                                Sistema Marcelina
                            </span>
                            <br />
                            <span className='font-fira-medita text-base'>
                                Modelo educativo no tradicional propio, único y mejor. 
                                Integra todas las áreas formativas con alto rigor 
                                académico. Centrado en el alumno y en su gusto por 
                                aprender e intervenir en su propio aprendizaje.
                            </span>
                        </div>
                    </div>
                    <div className='absolute z-10 py-1 px-2 bg-gray-400 rounded-full justify-between items-center lg:inline-flex hidden'>
                        <img src={selloMarce} alt="sello marce" className='w-16 h-16' />
                        <img src={selloPastoral} alt="sello pastoral" className='w-16 h-16 ml-3' />
                    </div>
                    <div>
                        <div className='flex-col lg:flex-row flex w-full mt-3'>
                            <div className='bg-white p-6 w-full lg:w-1/2 mr-3 rounded-br-4xl rounded-tl-4xl' data-aos="flip-left" data-aos-duration="1500">
                                <span className='font-fira-medita text-blueFb text-lg'>
                                    Sentido de comunidad
                                </span>
                                <br />
                                <span className='font-fira-medita text-base'>
                                    Hacemos familia con un sentido de comunidad para 
                                    todos en donde se fomentan amistades para toda la 
                                    vida en una comunidad segura, sana, alegre y 
                                    acogedora.
                                </span>
                            </div>
                            <div className='bg-white p-6 w-full lg:w-1/2 ml-3 rounded-bl-4xl rounded-tr-4xl mt-6 lg:mt-0' data-aos="flip-right" data-aos-duration="1500">
                                <span className='font-fira-medita text-blueFb text-lg'>
                                    Reflexión sobre las propias acciones
                                </span>
                                <br />
                                <span className='font-fira-medita text-base'>
                                    Formamos para la toma de buenas decisiones ante la 
                                    propia conducta entendiendo el origen, sus 
                                    consecuencias y las implicaciones en los demás en 
                                    libertad y con un acompañamiento cercano
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Sello
