import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import arrowRight from '/src/assets/img/leftBlueArrow.svg'
import arrowLeft from '/src/assets/img/rightBlueArrow.svg'
import circuloSld1 from '/src/assets/img/idioma/slider1.png'
import circuloSld2 from '/src/assets/img/idioma/slider2.png'
import circuloSld3 from '/src/assets/img/idioma/slider3.png'
import circuloSld4 from '/src/assets/img/idioma/slider4.png'

const Slider = () => {
    return (
        <div className='w-full bg-fondoAzul lg:bg-100 bg-cover bg-center bg-no-repeat px-5 lg:px-14 flex flex-col text-center relative py-10'>
            <span className='text-white text-xl font-fira-semibold'>
                RECONOCIEMIENTOS INTERNACIONALES
            </span>
            <div className='bg-redMar rounded-full w-5 lg:w-14 h-5 lg:h-14 absolute left-5 top-36 lg:-bottom-0 lg:top-auto'></div>
            <Splide 
                    className='w-full'
                    renderControls={ () => (
                        <div className="splide__arrows w-10/12 flex items-center justify-between relative lg:absolute inset-0 m-auto mt-10 lg:mt-0">
                            <button className='splide__arrow--next bg-white rounded-full w-12 h-12 flex items-center justify-center'><img src={arrowRight} alt="controlIcons" className='w-5 h-5' /></button>
                            <button className='splide__arrow--prev bg-white rounded-full w-12 h-12 flex items-center justify-center'><img src={arrowLeft} alt="controlIcons" className='w-5 h-5' /></button>
                        </div>
                    )}
                    options={{ 
                        type : 'fade',
                        rewind : 'true',
                        rewindSpeed: 500,
                        perPage: 1,
                        drag:true,
                        autoplay:true,
                        interval:6000,
                        waitForTransition:true,
                        keyboard:true,
                        pagination:false,

                    }}
                >
                    <SplideSlide className="flex justify-center items-center w-full h-96">
                            <img src={circuloSld1} alt="circulo-sld2" className='lg:w-1/5 w-80 object-cover'/>
                    </SplideSlide>
                    <SplideSlide className="flex justify-center items-center w-full h-96">
                            <img src={circuloSld2} alt="circulo-sld2" className='lg:w-1/5 w-80 object-cover'/>
                    </SplideSlide>
                    <SplideSlide className="flex justify-center items-center w-full h-96">
                            <img src={circuloSld3} alt="circulo-sld2" className='lg:w-1/4 w-80 object-cover'/>
                    </SplideSlide>
                    <SplideSlide className="flex justify-center items-center w-full h-96">
                            <img src={circuloSld4} alt="circulo-sld2" className='w-80 object-cover bg-white'/>
                    </SplideSlide>
                </Splide>
                <div className='bg-redMar rounded-full lg:w-10 lg:h-10 w-3 h-3 absolute right-3 top-24 lg:right-96 lg:-top-9'></div>
        </div>
    )
}

export default Slider
