import React from 'react'
import fb from '/src/assets/img/fbLogo.svg'
import ins from '/src/assets/img/instagramLogo.svg'
import yt from '/src/assets/img/ytLogo.svg'


const Contacto = () => {
    return (
        <div id='contacto' className='bg-fondoContacto bg-no-repeat lg:bg-100 bg-cover bg-center w-full h-auto lg:pt-24 pt-14 pb-10 lg:px-20 flex items-center flex-col'>
            <h4 className='font-fira-bold text-redMar text-3xl mb-8'>CONTACTO</h4>
            <div className='w-full flex justify-center'>
                <div className='w-full lg:w-2/3 font-fira-bol text-center lg:px-0 px-4'>
                    <span className='font-fira-regular text-blueMar mb-1'>Estaremos encantados de conocerte y guiarte para que formes parte de esta Gran Familia.</span><br />
                    <p className='font-fira-boldIta text-xl lg:text-2xl text-blueMar'>¡Escríbenos y nos pondremos en contacto contigo!</p>
                </div>
            </div>
            <div className='w-full flex flex-col lg:flex-row h-auto lg:h-500 mt-10 '>
                <div className='lg:w-3/12 w-full lg:py-0 py-4'>
                    <iframe className='w-full h-full' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3734.2706702353657!2d-100.38762688512243!3d20.61782130705558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d35b19ea15af17%3A0x85ae8ff421ff0673!2sColegio%20Marcelina!5e0!3m2!1ses!2smx!4v1642014033606!5m2!1ses!2smx"  loading="lazy"></iframe>
                </div>

                <div className='w-full lg:w-4/12 flex flex-col lg:px-10 px-4'>

                    <a  className='font-fira-regular text-base my-1' href="tel:442 212 90 98">(442) 212 90 98</a>
                    <a  className='font-fira-regular text-base my-1' href="mailto:admisiones@colegiomarcelina.edu.mx">admisiones@colegiomarcelina.edu.mx</a>
                    <span className='font-fira-regular text-base my-1' >L-V 8:00 a 17:00 hrs</span>
                    <span className='font-fira-regular text-base my-1' >Níspero No. 101, Col. Arboledas, C.P. 76140 Querétaro, Qro.</span>
                    <div className='hidden lg:flex w-8 h-0.5 bg-blueMar mt-20 mb-14'></div>
                    <div className='flex lg:flex-col items-center justify-center lg:items-start flex-row'>
                        <a href="https://www.facebook.com/MarcelinaColegio" target="_blank" rel="noopener noreferrer" className='mt-3 text-white font-fira-medium bg-blueFb rounded-full py-2 px-2 w-2/6 flex items-center justify-around'>Facebook <img className='w-5 h-5 ml-1' src={fb} alt="Fasebook logo" /></a>
                        <a href="https://www.instagram.com/colegiomarcelina/" target="_blank" rel="noopener noreferrer" className='mt-3 mx-2 lg:mx-0 text-white font-fira-medium bg-instagram rounded-full py-2 px-2 w-2/6 flex items-center justify-around'>Instagram <img className='w-5 h-5 ml-1' src={ins} alt="Youtube logo" /></a>
                        <a href="https://www.youtube.com/channel/UCD1_qJw_Jh7uT9Tg1LvBFMA" target="_blank" rel="noopener noreferrer" className='mt-3 text-white font-fira-medium bg-redYt rounded-full py-2 px-2 w-2/6 flex items-center justify-around'>Youtube <img className='w-7 h-7 ml-2' src={yt} alt="Instagram logo" /></a>
                    </div>

                    

                </div>

                <div className='w-full lg:w-5/12 h-auto lg:py-0 py-10 lg:px-0 px-4'>

                    <form action="contacto.php" method="post">
                        <div className='my-3 lg:my-4 w-full'>
                            <label className='font-fira-medium text-base text-gray-900 mb-3' htmlFor="nombre">Nombre</label>
                            <input className='w-full bg-transparent border-b border-solid border-grayForm focus:no-underline focus:outline-none' type="text" name='nombre' id='nombre' />
                        </div>
                        <div className='my-3 lg:my-4 w-full'>
                            <label className='font-fira-medium text-base text-gray-900 mb-3' htmlFor="area">Grado y área de interés</label>
                            <input className='w-full bg-transparent border-b border-solid border-grayForm focus:no-underline focus:outline-none' type="text" name='area' id='area' />
                        </div>
                        <div className='my-3 lg:my-4 w-full'>
                            <label className='font-fira-medium text-base text-gray-900 mb-3' htmlFor="telefono">Teléfono</label>
                            <input className='w-full bg-transparent border-b border-solid border-grayForm focus:no-underline focus:outline-none' type="text" name='telefono' id='telefono' />
                        </div>
                        <div className='my-3 lg:my-4 w-full'>
                            <label className='font-fira-medium text-base text-gray-900 mb-3' htmlFor="">Correo</label>
                            <input className='w-full bg-transparent border-b border-solid border-grayForm focus:no-underline focus:outline-none' type="text" name='email' id='email' />
                        </div>
                        <div className='my-3 lg:my-4 w-full'>
                            <label className='font-fira-medium text-base text-gray-900 mb-3' htmlFor="">Mensaje</label>
                            <textarea className='w-full bg-transparent border-b border-solid border-grayForm focus:no-underline focus:outline-none resize-none' type="text" name='mensaje' id='mensaje' />
                        </div>
                        <div className='w-full flex'>
                            <div className='w-2/3 flex justify-end flex-col mt-4'>
                                <span className='font-fira-medium text-sm'>Aviso de privacidad</span>
                                <div className='flex items-center'>
                                    <input type="checkbox" name="condiciones" id="condiciones" />
                                    <span className='font-fira-medium text-sm ml-1'>He leído y acepto el aviso de privacidad</span>
                                </div>
                            </div>
                            <div className='w-1/3 flex justify-end items-end'>
                                <button className='bg-blueMar text-white px-5 py-1 font-fira-medium' type="submit">
                                    Enviar
                                </button>
                            </div>
                        </div>
                    </form>

                </div>

            </div>
        </div>
    )
}

export default Contacto
