import React from 'react';

const PortadaIdioma = () => {
  return(
        <div className='bg-portadaIdioma  p-14 w-full h-vh75 bg-no-repeat bg-center bg-cover mt-20 lg:mt-32 relative'>
        </div>
  )
};

export default PortadaIdioma;
