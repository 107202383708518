import React from 'react';
import { Fragment } from 'react/cjs/react.production.min'
import arrowGal from '/src/assets/img/arrowGal.svg'
import primariaCirculo from '/src/assets/img/primariaCircula.png'
import arrowDown from '/src/assets/img/downArrow.svg'
import imagenUno from '/src/assets/img/primariaCultura.jpg'
import imagenDos from '/src/assets/img/primaraAprendizaje.png'
import imagenTres from '/src/assets/img/primariaConociendome.png'
import imagenCuatro from '/src/assets/img/primariaExperiencia.png'
import imagenCinco from '/src/assets/img/primariaConectado.png'
import imagenSeis from '/src/assets/img/primariaConectado.png'
import filtroImagen from '/src/assets/img/primariaFiltro.png'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import arrowRight from '/src/assets/img/left.svg'
import arrowLeft from '/src/assets/img/right.svg'
import rightArrow from '/src/assets/img/rightWhiteArrow.svg'
import circuloSld1 from '/src/assets/img/Primaria/Primaria_01.jpg'
import circuloSld2 from '/src/assets/img/Primaria/Primaria_02.jpg'
import circuloSld3 from '/src/assets/img/Primaria/Primaria_03.jpg'
import circuloSld4 from '/src/assets/img/Primaria/Primaria_04.jpg'
import circuloSld5 from '/src/assets/img/Primaria/Primaria_05.jpg'
import circuloSld6 from '/src/assets/img/Primaria/Primaria_06.jpg'
import circuloSld7 from '/src/assets/img/Primaria/Primaria_07.jpg'
import circuloSld8 from '/src/assets/img/Primaria/Primaria_08.jpg'
import circuloSld9 from '/src/assets/img/Primaria/Primaria_09.jpg'
import circuloSld10 from '/src/assets/img/Primaria/Primaria_10.jpg'
import circuloSld11 from '/src/assets/img/Primaria/Primaria_11.jpg'
import circuloSld12 from '/src/assets/img/Primaria/Primaria_12.jpg'
import circuloSld13 from '/src/assets/img/Primaria/Primaria_13.jpg'
import circuloSld14 from '/src/assets/img/Primaria/Primaria_14.jpg'
import circuloSld15 from '/src/assets/img/Primaria/Primaria_15.jpg'
import circuloSld16 from '/src/assets/img/Primaria/Primaria_16.jpg'
import circuloSld17 from '/src/assets/img/Primaria/Primaria_17.jpg'
import circuloSld18 from '/src/assets/img/Primaria/Primaria_18.jpg'
import circuloSld19 from '/src/assets/img/Primaria/Primaria_19.jpg'
import circuloSld20 from '/src/assets/img/Primaria/Primaria_20.jpg'
import circuloSld21 from '/src/assets/img/Primaria/Primaria_21.jpg'
import circuloSld22 from '/src/assets/img/Primaria/Primaria_22.jpg'
import circuloSld23 from '/src/assets/img/Primaria/Primaria_23.jpg'

const Primaria = () => {
    let playerSecu
    const onYTplayerSecu = () => {
        playerSecu = new YT.Player("videoSecu", {
            events: {
              onReady: onPlayerReadySecu
            }
          });
    }

    setTimeout(() => {
        onYTplayerSecu()
    }, 1000);
    
    

    function onPlayerReadySecu(event) {
        var playerButtonSecu = document.getElementById("playbuttonSecu");
        playerButtonSecu.addEventListener("click", function () {
        document.getElementById('videoSecu').classList.remove('pointer-events-none')
        playerButtonSecu.style.marginTop = "60%"
        playerButtonSecu.style.opacity = "0"
        playerSecu.playVideo();
        });
    }
  return(
    <Fragment>
        <div className='relative flex justify-center w-full mt-20 bg-center bg-cover lg:mt-32 h-vh75 bg-primaria'>
            <div className='absolute z-10 flex items-center justify-center w-20 h-20 rounded-full -bottom-10 lg:-bottom-14 lg:h-28 lg:w-28 bg-bluergba'>
                <img src={arrowDown} alt="arrow down icon" className='w-4 h-4' />
            </div>
        </div>
        <div className='relative w-full px-4 py-10 bg-center bg-no-repeat bg-cover bg-blueFb lg:bg-fondoAzul lg:bg-100 lg:px-10 '>
            <div className='absolute w-8 h-8 rounded-full bg-redMar left-10 top-10'></div>
            <div className='absolute hidden w-4 h-4 rounded-full bg-redMar left-2/4 -bottom-10 lg:block'></div>
            <div className='flex justify-end w-full'>
                <div className='inline-flex items-center justify-end'>
                    <a href='#primariaGal' className='text-base text-white lg:text-lg font-fira-semiboldNo'>
                        VER GALERÍA
                    </a>
                    <img src={arrowGal} alt="VerGaleria" className='w-20 h-4 lg:w-28'/>
                </div>
            </div>
            <div className='flex flex-col w-full lg:flex-row'>
                <div className='flex justify-center w-full py-8 lg:w-1/2 lg:block lg:py-0'>
                    <div className='relative border-4 border-solid h-72 w-72 lg:absolute lg:p-2 circulo-fondo-linea-kinder 2xl:h-450 2xl:w-450 lg:w-96 lg:h-96 lg:-bottom-28 2xl:-bottom-44 lg:left-48'>
                        <img src={primariaCirculo} alt="circuloNinos" className='w-full h-full'/>
                    </div>
                </div>
                <div className='flex flex-col items-start justify-center w-full p-3 lg:w-1/2'>

                    <div>
                        <h2 className='mt-5 text-3xl text-white font-fira-bold'>PRIMARIA</h2>
                    </div>
                    <div className='flex flex-col w-10/12 mt-4 mb-12'>
                        <span className='text-base text-white font-fira-medita'>La primaria es una etapa maravillosa llena de muchos cambios en donde se fortalecen 
                                                                                  los hábitos, el autoestima, el orden y el valor de sus ideas.
                        </span>
                        <span className='my-4 text-base text-white font-fira-medita'>Mantenemos un trato muy cercano con nuestros niños que nos permite estar al tanto 
                                                                                    de lo que sienten y piensan para así poder orientarlos en las distintas situaciones que 
                                                                                    se les presentan.
                        </span>
                        <span className='text-base text-white font-fira-medita'>En la primaria se adquieren hábitos de trabajo, hábitos de estudio, se viven los valores 
                                                                                    y se desarrollan tanto las relaciones sociales como las habilidades del siglo XXI.
                        </span>
                    </div>
                </div>
            </div>
        </div>

            {/* <div className='relative flex items-center justify-center w-full mt-0 h-vh75 lg:mt-52 '>
                <iframe id='videoPri' className='relative w-full h-full pointer-events-none' src="https://www.youtube.com/embed/TU9lI5JJRvs?si=B5Gr4KGgEWC8hEIF?enablejsapi=1&html5=1&mute=0&controls=1" title="YouTubeInicio" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div id='playbuttonPri' className='absolute z-10 p-2 border border-white border-solid rounded-full cursor-pointer w-36 h-36 animateYtBt'>
                        <div className='flex items-center justify-center w-full h-full rounded-full bg-blueFb'>
                            <img src={rightArrow} alt="rightIcon" className='w-8 h-8' />
                    </div>
                </div>
            </div> */}
           <div className='relative flex items-center justify-center w-full mt-0 h-vh75 lg:mt-52'>
                <iframe id='videoSecu' className='relative w-full h-full pointer-events-none' src="https://www.youtube.com/embed/TU9lI5JJRvs?enablejsapi=1&html5=1&mute=0&controls=1" title="YouTubeInicio" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <div id='playbuttonSecu' className='absolute z-10 p-2 border border-white border-solid rounded-full cursor-pointer w-36 h-36 animateYtBt'>
                        <div className='flex items-center justify-center w-full h-full rounded-full bg-blueFb'>
                            <img src={rightArrow} alt="rightIcon" className='w-8 h-8' />
                    </div>
                </div>
        </div>

        <div className='flex items-center justify-center w-full h-auto py-10 bg-cover lg:h-vh75 bg-filtroPedagogia lg:p-8'>
            <div className='flex flex-col items-center justify-center w-full bg-cartaMob lg:bg-cartaAcademica 2xl:w-1/2 h-750 2xl:h-550 lg:h-400 lg:w-1/2 bg-100 lg:items-start p-11'>
                <span className='mt-40 text-2xl text-center font-fira-boldIta lg:text-4xl text-blueFb lg:text-left lg:mt-0'>Bienvenido a <br /> Primaria Marcelina</span>
                <div className='relative flex items-center justify-center w-full -bottom-16 lg:-bottom-16'>
                <a href="Bienvenido a Primaria Marcelina.pdf" target="_blank" rel="noopener noreferrer" className='flex items-center px-6 py-2 mt-5 rounded-full lg:mt-0 bg-redMar'>
                    <span className='text-white font-fira-semiboldNo'>Descargar carta</span>
                    <img src={arrowDown} alt="arrowDown" className='h-2.5 w-2.5 ml-3' />
                </a>
                </div>
            </div>
        </div>

        <div className='flex justify-center w-full py-20 lg:p-20'>

            <div className='flex flex-col w-10/12 lg:w-8/12'>

                <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                    <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                        <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                            <img src={imagenUno} alt="img1" className='object-cover object-center w-full h-full rounded-full' />
                        </div>
                    </div>
                    <div className='flex flex-col w-full lg:w-2/3'>
                        <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Cultura del mundo</span>
                        <span className='text-center font-fira-medita lg:text-justify'>
                            Propiciamos el acercamiento a otras culturas para reconocer y valorar a cada 
                            persona más allá de las fronteras. Promovemos las competencias comunicativas 
                            para que sean capaces de expresarse en otros idiomas: inglés e italiano.
                        </span>
                    </div>
                </div>

                <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                    <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                        <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                            <img src={imagenDos} alt="img1" className='w-full h-full' />
                        </div>
                    </div>
                    <div className='flex flex-col w-full lg:w-2/3'>
                        <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Aprendizaje digital</span>
                        <span className='text-center font-fira-medita lg:text-justify'>
                            Nuestros pequeños comienzan a adentrarse en el mundo de la comunicación 
                            digital a través del uso de plataformas educativas y dispositivos electrónicos que 
                            les permiten mostrar sus aprendizajes y desarrollar su creatividad. 
                        </span>
                    </div>
                </div>

                <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                    <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                        <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                            <img src={imagenTres} alt="img1" className='w-full h-full' />
                        </div>
                    </div>
                    <div className='flex flex-col w-full lg:w-2/3'>
                        <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Conociéndome a mí mismo</span>
                        <span className='text-center font-fira-medita lg:text-justify'>
                            Nuestros niños van descubriendo las riquezas que hay en si mismos, en los demás 
                            y en el mundo que les rodea. Los acompañamos en este descubrimiento, poniendo 
                            a su alcance herramientas que les permitirán aprender a gestionar sus emociones, 
                            cuidar de si mismos y de toda la creación.
                        </span>
                    </div>
                </div>


                <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                    <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                        <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                            <img src={imagenCuatro} alt="img1" className='w-full h-full' />
                        </div>
                    </div>
                    <div className='flex flex-col w-full lg:w-2/3'>
                        <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Experiencia de aprendizaje</span>
                        <span className='text-center font-fira-medita lg:text-justify'>
                            Nuestros pequeños descubren todo su potencial a través de actividades y 
                            experiencias que les permiten sentirse partícipes de su propio aprendizaje y 
                            desarrollar habilidades esenciales como el trabajo colaborativo, el pensamiento 
                            crítico, la creatividad y la resolución de conflictos de manera pacífica.
                        </span>
                    </div>
                </div>


                <div className='flex flex-col items-center justify-between w-full lg:flex-row py-7 lg:min-w-650'>
                <div className='mb-8 w-72 lg:w-1/3 lg:mr-5'>
                        <div className='circulo-fondo-linea-academica lg:h-52 lg:w-52 2xl:w-80 2xl:h-80'>
                            <img src={imagenSeis} alt="img1" className='w-full h-full' />
                        </div>
                    </div>
                    <div className='flex flex-col w-full lg:w-2/3'>
                        <span className='mb-4 text-3xl text-center font-fira-bold text-redMar lg:text-left'>Conectado a Jesús y con el mundo</span>
                        <span className='text-center font-fira-medita lg:text-justify'>
                            Queremos que nuestros niños se reconozcan como hijos de Dios y hermanos de 
                            Jesús a partir del bautismo, para comprender la importancia de saberse relacionar 
                            con las personas y valorar la armonía de la integridad humana.
                        </span>
                    </div>
                </div>

            </div>

            </div>


    </Fragment>
  )};

export default Primaria;
